import { getValueAndUnitFromLocator, newInspectionForOrder } from './InspectionLogic';
import {
  emptyPackagingSection,
  getOCRQuestionSpec,
  LotInspection,
  InspectionContext,
  LegacyInspectionReference,
  InspectionRenderingInfo,
  PropertyToBeSet,
  QuestionInput,
  UserInputLocator,
  Inspection,
  LegacyInspection,
  InspectionReference,
  ProductionSiteInspectionReference,
  ProductionSiteInspection,
  ProdSiteInspectionPreview,
  PreviewQuestionSpec,
} from './InspectionModel';
import {
  FieldSchemaObjectTypeEnum,
  InspectableObjectType,
  InspectionSpecSection,
  InspectionSpecSectionType,
  InspectionType,
  LotPropertiesFromInspection,
  LotSchemaObjectTypeEnum,
  MeasurementUnit,
  PictureSectionType,
  SectionType,
  TransitSchemaObjectTypeEnum,
} from './generated/openapi/core';
import { isEqual, cloneDeep } from 'lodash';
import {
  AG_BOXES_AT_INSPECTION_QUESTION_ID,
  AG_BOXES_RECEIVED,
  AG_BOXES_SHIPPED_MISMATCH,
  AG_BOXES_SHIPPED_QUESTION_ID,
  AG_VOLUME_AT_INSPECTION,
  dateToString,
  IArticle,
  Lot,
  LotPosition,
  Order,
  OrderAGStatus,
  ProductionSiteLocation,
  Report,
  User,
  UserProfile,
} from './Model';
import {
  INSPECTION_BACKUP_INDEXEDDB_PREFIX,
  INSPECTION_TIMER_INDEXEDDB_PREFIX,
  ORDER_BOXES_EXPECTED,
  ORDER_QC_STATUS,
  SHARED_FIELD_REPORT_BASE_PATH,
  SUPPLY_CHAIN_URL_SUFFIX,
} from './GlobalConstants';
import { Article } from './ServiceArticle';
import {
  formatDate,
  isSplitLotPosition,
  capitalizeFirstLetter,
  standardizeDate,
} from './HelperUtils';
import { del, get, set } from 'idb-keyval';
import { InspectionSpec } from './ModelSpecification';

// In this file we'll have (for now at least) all helpers that are used throughout the app related to the inspection object but not necessarily related to the inspection flow
export interface InspectionClassInterface {
  isCompleted: () => boolean;
}

/********************************************** */
// ObjectType agnostic:
/********************************************** */
export class InspectionClass implements InspectionClassInterface {
  private inspection: Inspection;

  constructor(inspection: Inspection) {
    this.inspection = inspection;
  }

  public isCompleted() {
    if (!this.inspection?.status) return false;
    return (
      this.inspection.status !== 'OPEN' && this.inspection.status !== 'IN PROGRESS'
    );
  }
}

//----------------------------------------------------------------------------
export function removeEmptyInspectionReferenceFields<T extends InspectionReference>(
  reference: T,
  removeGrower: boolean = false
): T {
  const newReference = {} as T;
  Object.entries(reference).forEach(([key, value]) => {
    if (!!value) {
      newReference[key] = value;
    }
  });
  if (removeGrower) {
    delete (newReference as LegacyInspectionReference).growerId;
  }
  return newReference;
}

//----------------------------------------------------------------------------
export function isNewerInspection(
  newI: LegacyInspection,
  oldI: LegacyInspection
): boolean {
  if (!oldI || !oldI.lastModifiedDate?.hasOwnProperty('_second')) {
    return true;
  }
  return oldI.lastModifiedDate.toMillis() < newI.lastModifiedDate.toMillis();
}

//----------------------------------------------------------------------------
export const hasInspectionReference = (
  a: Inspection,
  reference: InspectionReference
): boolean => {
  const ref1 = removeEmptyInspectionReferenceFields(
    a?.reference ?? ({} as InspectionReference)
  );
  const ref2 = removeEmptyInspectionReferenceFields(
    reference ?? ({} as InspectionReference)
  );
  return isEqual(ref1, ref2);
};

//----------------------------------------------------------------------------
export const getObjectTypeFromReference = (
  reference: InspectionReference
): InspectableObjectType | null => {
  const prodSiteRef = reference as ProductionSiteInspectionReference;
  const legacyRef = reference as LegacyInspectionReference;

  if ((legacyRef.lotId ?? '').length > 0) {
    return LotSchemaObjectTypeEnum.Lot;
  } else if ((legacyRef.transportId ?? '').length > 0) {
    return TransitSchemaObjectTypeEnum.Transit;
  } else if ((prodSiteRef.locationId ?? '').length > 0) {
    return FieldSchemaObjectTypeEnum.Field;
  }
  return null;
};

//----------------------------------------------------------------------------
export const getQuestionDisplayedName = (
  questionId: string,
  schema: InspectionSpec | InspectionRenderingInfo
) => {
  const questionSpecs = schema?.questionSpecs[questionId];
  const name = questionSpecs?.displayedName ?? questionId;
  return name;
};

//----------------------------------------------------------------------------
// This function converts an InspectionReference into a string representation
// IMPORTANT: if the InspectionReference model changes, remember to adapt both stringifyInspectionReference and parseInspectionReference accordingly
export function stringifyInspectionReference(
  reference: InspectionReference | undefined
): string {
  if (!reference) {
    return ';;;;';
  }

  const prodSiteRef = reference as ProductionSiteInspectionReference;
  const legacyRef = reference as LegacyInspectionReference;

  if (!!prodSiteRef.locationId) {
    return [
      prodSiteRef.type,
      prodSiteRef.locationId,
      prodSiteRef.productionType,
      prodSiteRef.date,
    ].join(';');
  } else {
    return [
      legacyRef.type,
      legacyRef.orderId,
      legacyRef.lotId,
      legacyRef.transportId,
      legacyRef.date,
    ].join(';');
  }
}

export function parseInspectionReference(
  stringifiedReference: string
): InspectionReference | undefined {
  // Note: this is very hacky, but I can't think of a better way for now.
  const splitString: string[] = stringifiedReference.split(';');

  if (splitString.length === 5) {
    return parseLegacyInspectionReference(stringifiedReference);
  } else if (splitString.length === 4) {
    return parseProdSiteInspectionReference(stringifiedReference);
  }

  console.warn(`Could not parse stringified reference '${stringifiedReference}'`);
  return undefined;
}

//----------------------------------------------------------------------------
// Note: used only for testing purposes
export const getContextDelta = (
  context1: InspectionContext,
  context2: InspectionContext
): Partial<InspectionContext> => {
  const difference: Partial<InspectionContext> = cloneDeep(context2);
  Object.entries(context1).forEach(([contextKey, values]) => {
    Object.entries(values).forEach(([refId, value]) => {
      if (isEqual(context2?.[contextKey]?.[refId], value)) {
        delete difference[contextKey][refId];
      }
    });
    if (Object.keys(difference[contextKey]).length === 0) {
      delete difference[contextKey];
    }
  });
  return difference;
};

//----------------------------------------------------------------------------
export function renderUnit(unit: MeasurementUnit): string {
  if (!unit) return unit;
  const unitMap: { [unit in MeasurementUnit]: string } = {
    boxes: 'B.',
    product_percentage: '%',
    boxes_percentage: '%',
    punnets: 'P.',
    celsius: 'ºC',
    fahrenheit: 'ºF',
    degree_brix: 'ºBx',
    pieces: 'pcs',
    kilos: 'Kg',
    grams: 'g',
    pounds: 'Lb',
    punnets_percentage: '%',
    weight_percentage: '%',
    durofel_unit: ' Dur.',
    concentration_percentage: '%',
    shore: 'Sh.',
    millimeter: 'mm',
    color: 'color',
    kilos_per_box: 'kg/box',
    question: 'Q.',
    score: '',
    days: 'days',
    liters: 'l',
    plants_percentage: '%',
  };
  return unitMap[unit] ?? unit;
}

//----------------------------------------------------------------------------
export function getUserInputValuesToRender(
  defect: QuestionInput,
  forEmail: boolean = false
): string {
  let input = defect.measurableInputs?.[0]?.measurementInput;

  if (!input) return '';

  const pf = (v: any) => parseFloat(v).toFixed(2);

  const numberListComputedSuffixes = [':avg', ':std', ':min', ':max'];
  const isNumberList =
    input.valueType === 'float_list' || input.valueType === 'int_list';
  const isNumberListWithComputation =
    isNumberList &&
    numberListComputedSuffixes.some(
      (s) => !!defect.measurableInputs?.[1]?.measurableId.includes(s)
    );

  const values = defect.measurableInputs.map((m, idx) => {
    const { measurementInput: input, measurableId } = m ?? {};

    // we don't show the actual number list if it has computed values (avg, std, etc), in order to save space
    if (!input || (isNumberListWithComputation && idx === 0)) return null;

    let { value, unit } = input;

    if (
      Array.isArray(value) &&
      isNumberList &&
      !isNumberListWithComputation &&
      idx === 0
    ) {
      value = value.map((v) => pf(v)).join('/');
    } else if (isNumberListWithComputation && idx > 0) {
      for (const suffix of numberListComputedSuffixes) {
        if (measurableId.includes(suffix)) {
          value = `${capitalizeFirstLetter(suffix.replace(':', ''))} ${pf(value)}`;
          break;
        }
      }
    } else if (value === true) value = forEmail ? '' : '';
    else if (value === false) value = 'No';
    else if (input.valueType === 'category' || input.valueType === 'text') {
      /* keep it as is */
      return value;
    } else if (input.valueType === 'int') {
      value = parseInt(value);
    } else {
      value = pf(value).replace('.00', '');
    }
    return value + (unit ? renderUnit(unit) : '');
  });

  return values.filter((v) => v != null).join(' · ');
}

export const detectInspectionUpdateConflicts = async (
  currInspection: Inspection,
  latestInspectionSnapshotFromDb: Inspection,
  profile: UserProfile,
  users: User[],
  setWarningMessage: (message: string) => void
) => {
  const { lastModifiedUserId, lastModifiedDate } = latestInspectionSnapshotFromDb;

  const currInspectionLastModifiedTimestamp: number = await getInspectionTimer(
    currInspection
  );

  // If the inspection starting time is not found, it means the inspection hasn't been started on the current device
  if (!currInspectionLastModifiedTimestamp) return;

  const latestInspectionLastModifiedTimestamp: number = standardizeDate(
    lastModifiedDate ?? 0
  ).getTime();

  // if the inspection with the same reference has a higher lastModifiedDate in the latest lot snapshot, this means it has been modified elsewhere since we started working on it on this window/tab/device
  if (
    latestInspectionLastModifiedTimestamp > currInspectionLastModifiedTimestamp &&
    !new InspectionClass(currInspection).isCompleted()
  ) {
    let message: string;
    // display an appropriate warning message
    if (lastModifiedUserId === profile.id) {
      message = `Inspection has been modified by yourself on a different device/browser. The changes on this device will overwrite those other changes.`;
    } else {
      const userName: string =
        users.find((user) => user.id === lastModifiedUserId)?.name ?? 'someone else';
      message = `Inspection has been modified by ${userName}. Your changes will override theirs.`;
    }
    setWarningMessage(message);
  }
};

/********************************************** */
// Lot or Transit (a.k.a. "Legacy") specific
/********************************************** */
export function replaceArticleInLotInspections(lot: Lot, article: IArticle): Lot {
  if (!lot) {
    return lot;
  }

  const newLot = cloneDeep(lot);

  if (!!newLot.latestInspection) {
    newLot.latestInspection.lotProperties.article = article;
  }
  if (!!newLot.inspections) {
    newLot.inspections = newLot.inspections.map((a) => ({
      ...a,
      lotProperties: { ...a.lotProperties, article },
    }));
  }

  return newLot;
}

//----------------------------------------------------------------------------
// This converts a string created by the function stringifyInspectionReference (right above) back into an InspectionReference
// IMPORTANT: if the InspectionReference model changes, remember to adapt both stringifyInspectionReference and parseInspectionReference accordingly
export function parseLegacyInspectionReference(id: string): LegacyInspectionReference {
  const vals: string[] = (id ?? ';;;;').split(';');
  return {
    type: vals[0] as InspectionType,
    orderId: vals[1],
    lotId: vals[2],
    transportId: vals[3],
    date: vals[4],
  } as LegacyInspectionReference;
}

//----------------------------------------------------------------------------
export function orderInspectionsList(order: Order): LegacyInspection[] {
  if (!order) {
    return [];
  }

  const inspections: LegacyInspection[] = order.positions.map((pos) => {
    let result: LotInspection | undefined;

    if (!!order.lotInspectionMap) {
      result = order.lotInspectionMap[pos.lotId];
    }

    if (result) {
      return result;
    }

    return newInspectionForOrder(
      {
        orderId: order.id,
        lotId: pos.lotId,
        type: order.type === 'SELL' ? 'outgoing' : 'incoming',
        date: dateToString(new Date()),
      },
      order
    );
  });

  let result: LegacyInspection | undefined;
  if (!!order.transportInspectionMap) {
    result = order.transportInspectionMap['TRANSPORT'];
  }

  if (result) {
    inspections.push(result);
  } else {
    inspections.push(
      newInspectionForOrder(
        {
          orderId: order.id,
          transportId: 'TRANSPORT',
          type: 'transport',
          date: dateToString(new Date()),
        },
        order
      )
    );
  }

  return inspections;
}

//----------------------------------------------------------------------------
export function getInspectionBoxes(inspection: LotInspection): {
  boxesExpected?: number;
  boxesAtInspection?: number;
  boxesShipped?: number;
  boxesMismatch?: boolean;
  volumeAtInspection?: number;
} {
  if (!inspection?.userInputs) {
    return {};
  }

  const { userInputs } = inspection;

  const boxesExpected = inspection?.renderingInfo?.externalProperties?.boxes_expected;

  const boxesShipped = (
    userInputs?.[AG_BOXES_RECEIVED] ?? userInputs?.[AG_BOXES_SHIPPED_QUESTION_ID]
  )?.measurableInputs?.find(
    (m) =>
      m.measurableId === `${AG_BOXES_RECEIVED}:boxes` ||
      m.measurableId === `${AG_BOXES_SHIPPED_QUESTION_ID}:boxes`
  )?.measurementInput?.value;

  const boxesMismatch = userInputs?.[AG_BOXES_SHIPPED_MISMATCH]?.measurableInputs?.find(
    (m) => m.measurableId === AG_BOXES_SHIPPED_MISMATCH
  )?.measurementInput?.value;

  const boxesAtInspection = userInputs?.[
    AG_BOXES_AT_INSPECTION_QUESTION_ID
  ]?.measurableInputs?.find(
    (m) => m.measurableId === `${AG_BOXES_AT_INSPECTION_QUESTION_ID}:boxes`
  )?.measurementInput?.value;

  const volumeAtInspection = userInputs?.[
    AG_VOLUME_AT_INSPECTION
  ]?.measurableInputs?.find(
    (m) => m.measurableId === `${AG_VOLUME_AT_INSPECTION}:kilos`
  )?.measurementInput?.value;

  return {
    boxesExpected,
    boxesShipped,
    boxesMismatch,
    boxesAtInspection,
    volumeAtInspection,
  };
}

//----------------------------------------------------------------------------
export function removeInspectionlessPositions(order: Order | Report) {
  let newOrder = cloneDeep(order);
  newOrder.positions = newOrder.positions.filter(
    (p) =>
      !!order.lotInspectionMap?.[p.lotId] &&
      new InspectionClass(order.lotInspectionMap?.[p.lotId]).isCompleted()
  );

  // remove the assessments that are not closed as well
  Object.entries(order.lotInspectionMap).forEach(([lotId, insp]) => {
    if (!new InspectionClass(insp).isCompleted())
      delete newOrder.lotInspectionMap[lotId];
  });

  return newOrder as Order;
}

//----------------------------------------------------------------------------
export const buildLotOrTransitInspectionPath = (
  orgId: string,
  reference: LegacyInspectionReference,
  isSupplyChainLot?: boolean,
  boxesExpected?: number,
  qcStatus?: OrderAGStatus
) => {
  return (
    '/secure/' +
    orgId +
    '/inspection/' +
    stringifyInspectionReference(reference) +
    (isSupplyChainLot ? `/${SUPPLY_CHAIN_URL_SUFFIX}` : '') +
    (boxesExpected != null ? `?${ORDER_BOXES_EXPECTED}=${boxesExpected}` : '') +
    (qcStatus != null
      ? `${boxesExpected != null ? '&' : '?'}${ORDER_QC_STATUS}=${qcStatus}`
      : '')
  );
};

export function shouldVerifyNumBoxes(order: Order, position: LotPosition) {
  if (!position || !order) {
    return false;
  }
  return (
    ['BUY', 'SELL', 'INTERNAL_TRANSFER', 'SELL_RETURN'].includes(order.type) &&
    !isSplitLotPosition(position) &&
    !new Article(position.article).isRaw()
  );
}

//-------------------------------------------------------------------
export function compileLotPropertiesToSet(
  schema: InspectionSpec,
  inspection: LotInspection
) {
  let props: PropertyToBeSet[] = [];

  if ((inspection.barcodes ?? []).length > 0) {
    props.push({
      setProperty: 'barcodes',
      value: inspection.barcodes,
    });
  }

  Object.entries(schema.questionSpecs).forEach(([questionId, spec]) => {
    (spec.measurables ?? []).forEach((m) => {
      const { setProperty, measurableId } = m;
      const locator: UserInputLocator = {
        questionId,
        measurableId,
      };
      const { value } = getValueAndUnitFromLocator(locator, inspection);

      if (setProperty != null && value != null) {
        props.push({
          setProperty: setProperty as LotPropertiesFromInspection, // TODO DEV-1853: update setProperty type in core model
          value,
        });
      }
    });
  });

  return props;
}

//-------------------------------------------------------------------
export function updateLotPropertiesFromInspection(
  lot: Lot,
  inspection: LotInspection,
  propertiesToSet: PropertyToBeSet[]
) {
  propertiesToSet.forEach((prop) => {
    const { value, setProperty } = prop;

    switch (setProperty) {
      case 'boxes_shipped':
        const buyTransferIndex: number = lot.transfers.findIndex(
          (t) => t.transferType === 'BUY'
        );
        if (buyTransferIndex >= 0) {
          lot.transfers[buyTransferIndex].numBoxes = +value;
          lot.transfers[buyTransferIndex].volumeInKg = new Article(
            lot.article
          ).computeVolumeInKg(+value);
        }
        break;
      case 'current_num_boxes':
        // we can't directly modify the transient, as it's computed from the transfers
        // for now, we'll just adjust the first transfer in such way that the final value equals the value input by the user
        const difference = lot.transient.numBoxes - +value;
        const newTransferNumBoxes = lot.transfers[0].numBoxes - difference;
        lot.transfers[0].numBoxes = newTransferNumBoxes;
        lot.transfers[0].volumeInKg = new Article(lot.article).computeVolumeInKg(
          newTransferNumBoxes
        );
        break;
      case 'barcodes':
        lot.transient.barcodes = value;
        break;
      default:
        break;
    }
  });

  if ((inspection.lotProperties?.ggnList ?? []).length > 0) {
    if (lot.origin == null) {
      lot.origin = {};
    }
    // TODO: growerGGN is a single value in the lot model but an array in the inspection
    // lot properties model we might want to revise this.
    lot.origin.growerGGN = inspection.lotProperties?.ggnList[0];
    lot.origin.growerGGNs = inspection.lotProperties?.ggnList;
  }
}

/********************************************** */
// Production Site specific
/********************************************** */
export function parseProdSiteInspectionReference(
  id: string
): ProductionSiteInspectionReference {
  const vals: string[] = (id ?? ';;;').split(';');
  return {
    type: vals[0] as InspectionType,
    locationId: vals[1],
    productionType: vals[2],
    date: vals[3],
  } as ProductionSiteInspectionReference;
}

export function compileProductionSiteInspectionPreview(
  inspection: ProductionSiteInspection
): ProdSiteInspectionPreview {
  const { questionSpecs } = inspection.renderingInfo;
  const { pictures, scores, lastModifiedDate, status } = inspection;

  const renderedQuestions: PreviewQuestionSpec[] = Object.entries(
    inspection.userInputs ?? {}
  )
    .filter(([id, _]) => questionSpecs[id]?.cardProperties?.hideInCard !== true)
    .map(([id, o]) => ({
      displayedName: questionSpecs[id].cardProperties?.cardName
        ? questionSpecs[id].cardProperties?.cardName
        : questionSpecs[id].displayedName,
      value: getUserInputValuesToRender(o),
      agScore: Math.random() < 0.5 ? 3 : 2,
    }));

  const preview: ProdSiteInspectionPreview = {
    renderedQuestions,
    pictures,
    scores,
    lastModifiedDate,
    status,
  };

  return preview;
}

// these two functions below take into account both of the possible reference date formats
// for field inspections, namely the old one (DD/MM/YYYY) and the new one (unix timestamps)
export const formatFieldInspectionDate = (
  reference: ProductionSiteInspectionReference
): string => {
  return (
    isNaN(+reference.date)
      ? formatDate(reference.date)
      : formatDate(+reference.date, {
          timeStyle: 'short',
          dateStyle: 'short',
        })
  ) as string;
};

export const datifyFieldInspectionReferenceDate = (
  ref: ProductionSiteInspectionReference
): Date => {
  return new Date(isNaN(+ref.date) ? ref.date : +ref.date);
};

/************* */
// OCR
/************* */
export function injectOCRQuestionsToSpec(
  oldSpec: InspectionSpec,
  questionIds: string[]
) {
  const spec = cloneDeep(oldSpec);

  questionIds.forEach((questionId) => {
    // first, try to find the packaging section. If it doesn't exist, create it
    let packagingSectionIndex = spec.layout.findIndex(
      (t) => t.sectionType === SectionType.PackagingAndLabeling
    );
    if (packagingSectionIndex < 0) {
      // for now, positioning the empty packaging section at the beginning of the layout. Revise this behaviour if needed
      spec.layout = [emptyPackagingSection, ...spec.layout];
      packagingSectionIndex = 0;
    }

    // look for the question in the layout. If it doesn't exist, push it
    let questionExists = false;
    for (const l of spec.layout[packagingSectionIndex].layout) {
      if (
        (typeof l === 'string' && l === questionId) ||
        (typeof l !== 'string' && (l?.questionIds ?? []).includes(questionId))
      ) {
        questionExists = true;
        break;
      }
    }

    if (!questionExists) {
      spec.layout[packagingSectionIndex].layout.push(questionId);
      spec.questionSpecs[questionId] = getOCRQuestionSpec(questionId);
    }
  });

  return spec;
}

export const buildProdSiteInspectionPath = (
  orgId: string,
  reference: ProductionSiteInspectionReference
) => {
  return (
    '/secure/' + orgId + '/field-inspection/' + stringifyInspectionReference(reference)
  );
};

export const buildProdSiteInspectionViewPath = (
  orgId: string,
  reference: ProductionSiteInspectionReference
) => {
  return (
    '/secure/' + orgId + '/inspection-view/' + stringifyInspectionReference(reference)
  );
};

export const buildSharedFieldInspectionViewPath = (
  sharedReportId: string,
  inspectionId: string
) => {
  return `/${SHARED_FIELD_REPORT_BASE_PATH}/${sharedReportId}/${inspectionId}`;
};

export const buildSharedFieldReportUrl = (sharedReportId: string) =>
  `${window.location.origin}/${SHARED_FIELD_REPORT_BASE_PATH}/${sharedReportId}`;

export const buildProdSiteInspectionReference = (
  { locationId, productionType }: ProductionSiteLocation,
  type?: InspectionType,
  date?: Date
): ProductionSiteInspectionReference => {
  const reference: ProductionSiteInspectionReference = {
    locationId,
    productionType,
    type: type ?? InspectionType.PreHarvest,
    date: (date ?? new Date()).getTime().toString(),
  };
  return reference;
};

/************* */
// LAYOUT
/************* */

export function getLayoutFilteredByTaggable(
  schema: InspectionSpec
): InspectionSpecSection[] {
  const isQuestionTaggable = (qId: string) =>
    schema.questionSpecs[qId]?.inspectionProperties?.taggable === true;

  return schema.layout.filter((node) => {
    // Picture sections are all taggable except for packaging pictures
    if (node.type === InspectionSpecSectionType.Picture) {
      return node.sectionType !== PictureSectionType.PackagingPictures;
    }

    // Do not check for taggable flag at the section level anymore (TODO: remove taggable from the section model)
    // if (node.taggable === true || node.taggable == null) {
    //   return true;
    // }

    // Display the section if at least one of the questions present in it is taggable
    for (const s of node.layout) {
      if (typeof s === 'string') {
        if (isQuestionTaggable(s)) {
          return true;
        }
      } else {
        for (const ss of s.questionIds) {
          if (isQuestionTaggable(ss)) {
            return true;
          }
        }
      }
    }

    return false;
  });
}

/************* */
// POSTGRES
/************* */
const inspectionReferenceDateToIdDate = (inspectionReferenceDate: string) => {
  if (inspectionReferenceDate == null) {
    return inspectionReferenceDate;
  }

  const makeDoubleDigit = (s: string) => (s.length === 1 ? `0${s}` : s);

  const [year, month, day] = inspectionReferenceDate.split('.');
  return `${year}${makeDoubleDigit(month)}${makeDoubleDigit(day)}`;
};

//-------------------------------------------------------------------
export const buildInspectionId = (
  orgId: string,
  inspection: Inspection,
  useReferenceDate: boolean = true // if false, it uses the inspection's lastModifiedDate
): string | null => {
  if (!inspection || !orgId) {
    return null;
  }

  const getRefIdDate = (refDate: string) =>
    useReferenceDate && refDate != null
      ? inspectionReferenceDateToIdDate(refDate)
      : (formatDate(inspection.lastModifiedDate, undefined, true) as Date)
          .toISOString()
          .slice(0, 10)
          .replace(/-/g, '');

  // SAMPLE:
  // specialfruit.be;lot;LOT0812222;incoming;20220908

  // For now, we handle lot and transit (legacy) inspections in the same logic block
  if (
    inspection.objectType === LotSchemaObjectTypeEnum.Lot ||
    inspection.objectType === TransitSchemaObjectTypeEnum.Transit
  ) {
    const { transportId, lotId, type, date: refDate, orderId } = inspection.reference;

    const objectType: InspectableObjectType = !!transportId
      ? TransitSchemaObjectTypeEnum.Transit
      : LotSchemaObjectTypeEnum.Lot;

    const entityId =
      objectType === TransitSchemaObjectTypeEnum.Transit
        ? orderId // for transport inspections, we use the order id as they can only live there for now
        : lotId;

    const params: string[] = [orgId, objectType, entityId, type, getRefIdDate(refDate)];
    return params.join(';');
  } else if (inspection.objectType === FieldSchemaObjectTypeEnum.Field) {
    const { locationId, type, date: refDate } = inspection.reference;
    const params: string[] = [
      orgId,
      FieldSchemaObjectTypeEnum.Field,
      locationId,
      type,
      refDate,
    ];
    return params.join(';');
  }
};

/************* */
// INDEXEDDB BACKUP
/************* */
export const buildInspectionTimerKey = (inspection: Inspection) =>
  `${INSPECTION_TIMER_INDEXEDDB_PREFIX}${stringifyInspectionReference(
    inspection.reference
  )}`;

//-------------------------------------------------------------------
export const buildInspectionBackupKey = (inspection: Inspection) =>
  `${INSPECTION_BACKUP_INDEXEDDB_PREFIX}${stringifyInspectionReference(
    inspection.reference
  )}`;

//-------------------------------------------------------------------
export const saveInspectionAndTimerIntoIndexedDB = async (
  inspection: Inspection,
  errorCallback?: (hasError: boolean) => void
) => {
  let hasError = false;

  // save an inspection backup into the indexedDB each time it changes
  try {
    await set(buildInspectionBackupKey(inspection), inspection);
  } catch (error) {
    console.warn(`Could not save inspection backup into indexedDB`, error);
    hasError = true;
  }

  // after the first modification to the inspection, the timer starts counting
  try {
    const timerKey = buildInspectionTimerKey(inspection);
    const assessmentTimer = await get(timerKey);
    if (assessmentTimer == null) {
      await set(timerKey, Date.now());
    }
  } catch (error) {
    console.warn(`Could not save inspection timer into indexedDB`, error);
    hasError = true;
  }

  return errorCallback?.(hasError);
};

//-------------------------------------------------------------------
export const removeInspectionAndTimerFromIndexedDB = async (
  inspection: Inspection,
  errorCallback?: (hasError: boolean) => void
) => {
  let hasError = false;

  try {
    await del(buildInspectionBackupKey(inspection));
  } catch (error) {
    console.warn(`Could not remove inspection backup from indexedDB`, error);
    hasError = true;
  }

  try {
    await del(buildInspectionTimerKey(inspection));
  } catch (error) {
    console.warn(`Could not remove inspection timer from indexedDB`, error);
    hasError = true;
  }

  return errorCallback?.(hasError);
};

//-------------------------------------------------------------------
export const getInspectionTimer = async (
  inspection: Inspection
): Promise<number | undefined> => {
  let timer: number;
  try {
    timer = await get(buildInspectionTimerKey(inspection));
  } catch (error) {
    console.warn('Timer could not be read from indexedDB', error);
  }
  return timer;
};

//-------------------------------------------------------------------
export async function getInspectionBackup<T extends Inspection>(
  reference: InspectionReference
): Promise<T | undefined> {
  let backupInspection: T;
  try {
    backupInspection = await get(buildInspectionBackupKey({ reference } as Inspection));
  } catch (error) {
    console.warn('Could not retrieve inspection backup from indexedDB', error);
  }
  return backupInspection;
}

//-------------------------------------------------------------------
