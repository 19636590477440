import {
  IonBackButton,
  IonBadge,
  IonButton,
  IonButtons,
  IonChip,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonList,
  IonLoading,
  IonModal,
  IonPage,
  IonPopover,
  IonSpinner,
  IonText,
  IonTitle,
  IonToolbar,
  withIonLifeCycle,
} from '@ionic/react';
import { cloneDeep } from 'lodash';
import {
  chatbubbleEllipsesOutline,
  checkboxOutline,
  chevronBackOutline,
  closeOutline,
  ellipsisHorizontal,
  imageOutline,
  stopOutline,
  timeOutline,
} from 'ionicons/icons';
import React, { Fragment, useContext } from 'react';
import { CardImages } from './CardImages';
import { firestore, storage } from './ConfigFirebase';
import { getOrder, getSharedReport, inspectionHistoryColRef } from './DataStorage';
import { capitalizeFirstLetter, formatDate, resolveLocationName } from './HelperUtils';
import ModalSelectPicturesImport from './ModalSelectPicturesImport';
import {
  AGScore,
  Conversation,
  Order,
  Picture,
  PopoverModel,
  ProductionSiteLocation,
} from './Model';
import PageGallery from './PageGallery';
import './PageInspectionView.scss';
import PageNewConversation from './PageNewConversation';
import PagePicture from './PagePicture';
import {
  AppProduct,
  QCTierAction,
  isFreeQCTier,
  orgHasPermission,
  userHasPermission,
} from './PermissionsService';
import i18n from './ServiceI18n';
import ViewArticleDescription from './ViewArticleDescription';
//@ts-ignore
import { ViewContactNameById } from './ViewContactName';
// import { group } from 'console';
import {
  addInspectionMessage,
  getLegacyInspectionSnapshot,
  getProductionSiteInspectionSnapshot,
  getReportInspectionSnapshot,
  updateLotOrTransitInspectionInRelatedEntities,
  updateProductionSiteInspectionInRelatedEntities,
  uploadInspectionImages,
} from './DataInspection';
import {
  INSPECTION_IMAGES_STORAGE_BASE_PATH,
  NOT_AVAILABLE_STRING,
  SHARED_FIELD_REPORT_BASE_PATH,
  SUPPLY_CHAIN_URL_SUFFIX,
} from './GlobalConstants';
import withContext, { ContextProps } from './HOC/withContext';
import {
  LotInspection,
  LegacyInspectionReference,
  InspectionScore,
  LotProperties,
  MeasurableInput,
  MeasurementInput,
  UserInputLocator,
  LegacyInspection,
  InspectionReference,
  Inspection,
  ProductionSiteInspectionReference,
  ProductionSiteInspection,
} from './InspectionModel';
import { SectionNames } from './ModelSpecification';
import {
  buildLotOrTransitInspectionPath,
  buildProdSiteInspectionPath,
  formatFieldInspectionDate,
  getObjectTypeFromReference,
  parseLegacyInspectionReference,
  renderUnit,
  stringifyInspectionReference,
} from './ServiceInspection';
import { RenderGroupValue } from './ViewInspectionScore';
import ViewPicture from './ViewPicture';
import {
  FieldSchemaObjectTypeEnum,
  InspectionSpecSection,
  LotSchemaObjectTypeEnum,
} from './generated/openapi/core';
import { fetchEntityErrorMessage, presentStandardToast } from './HelperIonic';
import { toastController, alertController } from '@ionic/core';
import { ctxLocations } from './App';
import { useLocationUtils } from './hooks/useLocationUtils';
import { CFileUpload } from './components/CFileUpload';
import { getSharedFieldReportSnapshot } from './data/sharedFieldReport/sharedFieldReport.service';

interface Props {
  history: any;
  inspectionReference: InspectionReference;
  date?: string;
  sharedReportId?: string;
  reportId?: string;
  sharedReport?: boolean;
  sharedFieldReport?: boolean;
  fieldInspectionId?: string;
  supplyChainLot?: boolean;
}

interface State {
  inspection?: Inspection;
  savedInspection?: Inspection;
  inspectionReference: InspectionReference;
  inspectionHistory: Array<{ id: number; data: Inspection }>;

  order?: Order;

  inited: boolean;
  ionLoading?: boolean;
  layout: boolean;

  showNewConversation: boolean;
  showPageGallery: boolean;
  showPagePicture: boolean;
  showModalSelectPicturesImport: boolean;
  showCategoryId: string;
  showDefectId: string;
  showPicture: Picture;
  showApplyAssessment: boolean;

  menuPopover: PopoverModel;
}

class PageInspectionView extends React.Component<Props & ContextProps, State> {
  private unsubscribers: any[] = [];
  private mounted;
  private fileInput = React.createRef<HTMLInputElement>();

  constructor(props) {
    super(props);

    this.state = {
      inited: false,
      layout: false,
      inspectionHistory: [],
      showApplyAssessment: false,
      showNewConversation: false,
      showPageGallery: false,
      showPagePicture: false,
      showModalSelectPicturesImport: false,
      showCategoryId: undefined,
      showDefectId: undefined,
      showPicture: undefined,
      menuPopover: { visible: false },
      inspectionReference: this.props.inspectionReference,
    };
  }

  async ionViewWillEnter() {
    const {
      inspectionReference,
      sharedReport,
      reportId,
      profile,
      sharedFieldReport,
      sharedReportId,
      fieldInspectionId,
    } = this.props;

    if (inspectionReference !== this.state.inspectionReference) {
      if (this.mounted) {
        this.setState({
          inited: true,
          inspection: undefined,
          inspectionReference: undefined,
        });
      }
    }

    const legacyReference = inspectionReference as LegacyInspectionReference;
    const prodSiteReference = inspectionReference as ProductionSiteInspectionReference;

    let order: Order;
    if (!!legacyReference?.orderId) {
      getOrder(
        firestore,
        this.props.profile.organisationId,
        legacyReference.orderId
      ).then((order) => this.setState({ order }));
    }

    if (sharedReport) {
      let report = await getSharedReport(firestore, sharedReportId);
      //@ts-ignore
      let inspId = parseLegacyInspectionReference(inspectionReference);

      if (!report) {
        await presentStandardToast(toastController, fetchEntityErrorMessage('report'));
        return;
      }

      let inspection: LegacyInspection;
      if (inspId.transportId) {
        inspection = report.transportInspectionMap?.[inspId.transportId];
      } else {
        inspection = report.lotInspectionMap?.[inspId.lotId];
      }

      this.setState({
        inited: true,
        inspection,
        inspectionReference,
      });
    } else if (sharedFieldReport && fieldInspectionId) {      
      this.unsubscribers.push(
        getSharedFieldReportSnapshot(
          firestore,
          sharedReportId,
          (report) => {            
            this.setState({
              inited: true,
              inspection:
                report.inspections.find((i) => i.id === fieldInspectionId) ?? null,
              inspectionReference,
            });
          },
          async (err) => {
            await presentStandardToast(
              toastController,
              `${fetchEntityErrorMessage('report')}:\n${err}`
            );
            return;
          }
        )
      );
    } else {
      if (reportId) {
        getReportInspectionSnapshot(
          firestore,
          profile.organisationId,
          reportId,
          inspectionReference,
          (inspection) => {
            this.setState({
              inited: true,
              inspection,
              inspectionReference,
            });
          }
        );
      } else if (
        getObjectTypeFromReference(inspectionReference) !==
        FieldSchemaObjectTypeEnum.Field
      ) {
        this.unsubscribers.push(
          getLegacyInspectionSnapshot(
            firestore,
            profile.organisationId,
            inspectionReference,
            this.props.date,
            (inspection) => {
              this.setState({
                inited: true,
                inspection,
                inspectionReference,
              });
            },
            this.props.organisation?.id,
            this.props.supplyChainLot
          )
        );
      } else {
        this.unsubscribers.push(
          getProductionSiteInspectionSnapshot(
            firestore,
            profile.organisationId,
            prodSiteReference,
            (inspection) => {
              this.setState({
                inited: true,
                inspection: inspection ?? null,
                inspectionReference,
              });
            }
          )
        );
      }
    }
  }

  ionViewWillLeave() {
    this.unsubscribers.map((unsubscrive) => unsubscrive());
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount() {
    this.mounted = true;
  }

  startNewConversation() {
    this.setState({ ...this.state, showNewConversation: true });
  }

  cancelNewConversation() {
    this.setState({ ...this.state, showNewConversation: false });
  }

  async saveNewConversation(conversation: Conversation) {
    conversation.title =
      'Report: ' +
      this.props.inspectionReference.type +
      ' ' +
      (this.props.inspectionReference as LegacyInspectionReference).lotId;
    this.setState({ showNewConversation: false, ionLoading: true });
    await addInspectionMessage(
      firestore,
      this.state.inspection,
      this.props.profile.id,
      conversation
    );
    await this.setState({ showNewConversation: false, ionLoading: false });
    this.goToConversation(conversation);
  }

  goToConversation(conversation: Conversation) {
    this.props.history.push('/secure/inbox/' + conversation.id + '/new-message');
  }

  toggleExplanation(e) {
    e.target.nextSibling.classList.toggle('hide');
  }

  async pictureSelected(categoryId?: any) {
    if (categoryId) {
      this.setState({ showCategoryId: categoryId, showPageGallery: true });
    } else {
      this.setState({
        showCategoryId: undefined,
        showDefectId: undefined,
        showPageGallery: true,
      });
    }
  }

  getDefaultBackUrl() {
    const { inspection } = this.state;

    if (!inspection) {
      return '/tabs/quality-control';
    }

    if (this.props.sharedReport) {
      return '/shared-report/' + this.props.sharedReportId;
    }

    if (this.props.sharedFieldReport) {
      return `/${SHARED_FIELD_REPORT_BASE_PATH}/` + this.props.sharedReportId;
    }

    const legacyInspection = inspection as LegacyInspection;
    const locationId: string | undefined = (inspection as ProductionSiteInspection)
      .reference.locationId;

    if (legacyInspection.reference.orderId) {
      return (
        '/secure/' +
        this.props.profile.organisationId +
        '/order/' +
        legacyInspection.reference.orderId
      );
    } else if (legacyInspection.reference.lotId) {
      return (
        '/secure/' +
        this.props.profile.organisationId +
        '/lot/' +
        legacyInspection.reference.lotId +
        (this.props.supplyChainLot ? `/${SUPPLY_CHAIN_URL_SUFFIX}` : '')
      );
    } else if (!!locationId) {
      return '/secure/' + this.props.profile.organisationId + '/location/' + locationId;
    } else {
      return '/tabs/quality-control';
    }
  }

  getPicturesFromGroup(groupId) {
    let { inited, inspection: assessment } = this.state;
    if (!inited) {
      return;
    }

    let pictures: Picture[] = assessment?.pictures;
    return pictures
      .filter((p) => (p.sectionId ?? p.imageTag) === groupId)
      .map((pic, i) => (
        <div key={i} onClick={(e) => this.pictureSelected(groupId)}>
          <ViewPicture id={pic.id} />
        </div>
      ));
  }

  getNumOfPics(defectId) {
    return this.state.inspection.pictures.filter((p) => p.inputIds?.includes(defectId))
      .length;
  }

  itemClick(questionId) {
    if (this.getNumOfPics(questionId) > 0) {
      this.setState({
        showPageGallery: true,
        showDefectId: questionId,
      });
    }
  }

  clickPicture(picture: Picture) {
    console.log(picture);
    this.setState({
      showPicture: picture,
      showPagePicture: true,
    });
  }

  changeLayout() {
    this.setState({ layout: !this.state.layout });
  }

  renderQuestion(questionId: string) {
    const { inspection } = this.state;

    const { displayedName } = inspection.renderingInfo?.questionSpecs[questionId];
    const userInput = inspection.userInputs[questionId];
    const score = inspection.scores[questionId];

    const numOfPics = this.getNumOfPics(questionId);

    const hasNoInput = !score && !userInput;

    const singleRow: boolean =
      !!score ||
      hasNoInput ||
      ((userInput?.measurableInputs ?? []).length === 1 &&
        userInput?.measurableInputs[0]?.measurementInput?.displayedName == null &&
        (userInput?.measurableInputs[0]?.auxMeasurementsInput ?? []).length <= 1);

    const className = `
      question ${questionId} 
      ${singleRow ? '' : 'multiple'}  
      score-${userInput?.agScore ?? score?.agScore ?? ''} ${
      numOfPics > 0 ? ' clickable' : ''
    }
    `;

    if (questionId === 'ag_boxes_shipped_mismatch' && userInput?.agScore === 4) {
      return;
    }

    return (
      <div
        className={className}
        key={questionId}
        data-tip={`inspection-result-${questionId}`}
        onClick={() => this.itemClick(questionId)}
      >
        <div className="question-name">
          <div>{displayedName}</div>
          {numOfPics > 0 && (
            <IonBadge
              color="dark"
              className="num-of-pictures"
              data-tip={`inspection-result-num-of-pictures-${questionId}`}
            >
              <IonIcon icon={imageOutline} /> {numOfPics}
            </IonBadge>
          )}
        </div>
        <div className="values">
          {!!userInput &&
            userInput.measurableInputs.map((input, idx) => {
              const locator: UserInputLocator = {
                questionId,
                measurableId: input.measurableId,
              };
              return (
                <div key={idx} className={'type-' + input.measurementInput?.valueType}>
                  {this.renderValues(idx, input, userInput.agScore, locator)}
                </div>
              );
            })}
          {!!score && <div>{this.renderScore(score)}</div>}
          {hasNoInput ? (
            <div>
              <span>{NOT_AVAILABLE_STRING}</span>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }

  renderScore(score: InspectionScore) {
    return <RenderGroupValue omitName={true} groupId={score.id} outputValue={score} />;
  }

  renderValues(
    index: number,
    input: MeasurableInput,
    agScore: AGScore,
    locator: UserInputLocator
  ) {
    let { displayedName, valueType } = input.measurementInput ?? {};
    const { auxMeasurementsInput, verifiableSource } = input;
    const { questionId } = locator;

    displayedName = displayedName ?? input.auxMeasurementsInput?.[0]?.displayedName;

    // console.log(displayedName, input)

    const dataTip = `inspection-result-${questionId}-input-${index}`;

    let className = '';
    if (agScore != null) {
      className += ' score-' + agScore;
    }

    let renderedValue: any = input.measurementInput
      ? this.getRenderedValueAndUnit(input.measurementInput, verifiableSource)
      : NOT_AVAILABLE_STRING;

    // this ensures we respect the line breaks
    if (typeof renderedValue === 'string' && renderedValue.includes('\n')) {
      renderedValue = renderedValue.split('\n').map((v, i, a) => (
        <Fragment key={i}>
          {v}
          {i < a.length - 1 ? <br /> : ''}
        </Fragment>
      ));
    }

    if ((auxMeasurementsInput ?? []).length) {
      const prefix =
        '(' +
        auxMeasurementsInput
          .map((i) => this.getRenderedValueAndUnit(i, undefined))
          .join(' · ') +
        ')';
      renderedValue = (
        <>
          <span className="aux-prefix">{prefix}</span>
          <span className="aux-value">{renderedValue}</span>
        </>
      );
    }

    if (!!displayedName) {
      return (
        <>
          <span className="answerLabel">{displayedName}</span>
          <span data-tip={dataTip}>{renderedValue}</span>
        </>
      );
    } else {
      return (
        <span data-tip={dataTip} className={valueType + className}>
          {renderedValue}
        </span>
      );
    }
  }

  private getRenderedValueAndUnit(
    measInput: MeasurementInput,
    verifiableSource?: number
  ) {
    const { unit, value, valueType } = measInput;
    let renderedUnit: string = !!unit ? renderUnit(unit) : '';
    let renderedValue: string = value;

    try {
      if (verifiableSource != null) {
        renderedValue = `${renderedValue} / ${verifiableSource}`;
      } else if (valueType === 'boolean') {
        return <IonIcon icon={value === true ? checkboxOutline : stopOutline} />;
      } else if (valueType === 'float') {
        renderedValue = parseFloat(value?.toFixed(2)) + '';
      } else if (valueType === 'int') {
        renderedValue = parseInt(value).toFixed(0);
      } else if (valueType === 'float_list' || valueType === 'int_list') {
        // TODO: investigate what's causing sporadic cases of non-array values for user inputs of type float/int_list
        renderedValue = `(${(Array.isArray(value) ? value : [value])
          .filter((v) => !isNaN(v))
          .map((v: number) => parseFloat(v.toFixed(2)))
          .join(', ')}) `;
      }
      return `${renderedValue}${renderedUnit}`;
    } catch (error) {
      console.error('ERROR', measInput, this.state.inspection);
      return '';
    }
  }

  renderReport() {
    const { inspection } = this.state;
    let myOrgAssessment =
      this.props.profile?.organisationId === this.props.organisation?.id &&
      !this.props.sharedReport;

    console.log('inspection', inspection);

    const inspectionUser = this.props.users?.find(
      (o) => o.id === inspection?.lastModifiedUserId
    );
    const commercialUser = this.props.users?.find(
      (o) => o.id === inspection?.reviewedBy
    );

    const emptyInspection = Object.keys(inspection.userInputs ?? {}).length === 0;

    const layout = inspection?.renderingInfo?.layout.filter((l) => !l.imageTag);
    const questionSpecs = inspection?.renderingInfo?.questionSpecs;

    const appearsInInternalReport = (questionId: string) => {
      if (questionSpecs?.[questionId]?.reportProperties?.hideInReport !== true) {
        return true;
      }
      return !myOrgAssessment;
    };

    const appearsInExternalReport = (questionId: string) => {
      if (
        questionSpecs?.[questionId]?.reportProperties?.hideInExternalReport !== true
      ) {
        return true;
      }
      return myOrgAssessment;
    };

    // add any additional logic here to decide whether to display a question
    const includeQuestion = (questionId: string, section: InspectionSpecSection) => {
      let included: boolean =
        section.reportProperties?.hideEmptyQuestions === false ||
        (inspection?.userInputs[questionId]?.measurableInputs ?? []).length > 0 ||
        !!inspection?.scores[questionId];

      // Note: this is not set at org settings level anymore, but at the section level
      // !!this.props.applicationContext?.organisationSettings?.includeEmptyInspectionQuestionsInReport

      return (
        included &&
        appearsInInternalReport(questionId) &&
        appearsInExternalReport(questionId)
      );
    };

    const schema = this.props.inspectionSpecs?.find(
      (s) => s.id === inspection.schemaId
    );

    const renderDate = () => {
      const dateComp = (content: string, title: string = 'Date') => {
        return (
          <div>
            <b>{title}: </b> {content}
          </div>
        );
      };

      switch (inspection?.objectType) {
        case 'field':
          return (
            <>
              {!!inspection.reference.date &&
                dateComp(formatFieldInspectionDate(inspection.reference))}
              {!!inspection.lastModifiedDate &&
                dateComp(
                  formatDate(inspection.lastModifiedDate, {
                    dateStyle: 'short',
                    timeStyle: 'short',
                  }) as string,
                  'Last modified'
                )}
            </>
          );
        default:
          return (
            !!inspection.lastModifiedDate &&
            dateComp(
              formatDate(inspection.lastModifiedDate, {
                dateStyle: 'short',
                timeStyle: 'short',
              }) as string
            )
          );
      }
    };

    return (
      <div className={'main-wrapper ' + (this.state.layout && ' no-wrap')}>
        <div className="top-pictures">
          {!!inspection?.pictures?.length && (
            <div className="view-all no-print" onClick={(e) => this.pictureSelected()}>
              <IonChip color="dark">view gallery</IonChip>
            </div>
          )}
          <CardImages
            pictures={inspection?.pictures}
            max={inspection?.pictures?.length}
            onClick={this.clickPicture.bind(this)}
            imageMode="img"
          />
        </div>

        <div className="top-info">
          <div className="change-layout no-print" onClick={(e) => this.changeLayout()}>
            <div>Change Layout</div>
          </div>
          <RenderEntityInfo inspection={inspection} />
          <div className="assessment-info">
            {renderDate()}

            {/* <div><b>Status: </b> { inspection.status }</div> */}

            {!!inspectionUser && (
              <div data-tip={'inspection-result-inspection-by'}>
                <b>Inspection by: </b> {inspectionUser.email}
              </div>
            )}

            {!!commercialUser && (
              <div>
                <b>Reviewed by: </b> {commercialUser.email}
              </div>
            )}

            {!!inspection?.locationId && (
              <div>
                <b>Location: </b>{' '}
                {resolveLocationName(inspection?.locationId, this.props.locations)}
              </div>
            )}

            {inspection.objectType !== FieldSchemaObjectTypeEnum.Field &&
              !!inspection.reference.growerId && (
                <div className="grower-name">
                  <b>Grower:</b>{' '}
                  <ViewContactNameById contactId={inspection.reference.growerId} />
                </div>
              )}

            {!!schema && (
              <div>
                <b>Schema:</b>{' '}
                {inspection.schemaId
                  ? schema?.name
                    ? schema.name
                    : inspection.schemaId.split(';').slice(-1)[0]
                  : NOT_AVAILABLE_STRING}
                &nbsp;
                {inspection.schemaVersion ? (
                  <small>{`(v${inspection.schemaVersion}${
                    !!schema?.orgId &&
                    schema.orgId !== this.props.profile?.organisationId
                      ? ` - ${schema.orgId}`
                      : ''
                  })`}</small>
                ) : (
                  ''
                )}
              </div>
            )}

            {/* <div><b>Schema: </b> {inspection.schemaId ? inspection.schemaId.split(';').slice(-1)[0] : 'n/a'}</div> */}

            {inspection.objectType === LotSchemaObjectTypeEnum.Lot &&
              inspection.barcodes?.length > 0 && (
                <div>
                  <b>Barcodes:</b>&nbsp;
                  {inspection.barcodes.map((b) => (
                    <span key={b.code}>
                      {b.code} ({b.type}) &middot;{' '}
                    </span>
                  ))}
                </div>
              )}
          </div>
        </div>

        {inspection.objectType === LotSchemaObjectTypeEnum.Lot && (
          <ViewLotProperties lotProperties={inspection.lotProperties} />
        )}

        {emptyInspection ? (
          <h2 className="not-found">Empty Inspection</h2>
        ) : (
          !layout && (
            <>
              <h1 className="h1-not-found">Inspection still open</h1>
              {/* <h5 className="not-found">Report Layout Not Found</h5> */}
            </>
          )
        )}

        <div className="report-grid">
          {!!inspection &&
            layout?.map((section) => {
              const groupScore = inspection.scores?.[section.name]?.score;
              // console.log('section', section)
              return (
                <div className="group" key={section.name}>
                  <div className="group-name">
                    <strong>{SectionNames[section.sectionType.toLowerCase()]}</strong>
                    {groupScore != null && (
                      <div className={'view-assessment-score FULL'}>
                        <div className={`score ${section.name} score-${groupScore}`}>
                          {groupScore}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="question-list-group">
                    {(section.layout ?? []).length > 0 &&
                      section.layout.map((l) => {
                        if (typeof l === 'string') {
                          return includeQuestion(l, section)
                            ? this.renderQuestion(l)
                            : null;
                        } else {
                          const questionIds = (l.questionIds ?? []).filter((q) =>
                            includeQuestion(q, section)
                          );
                          return questionIds.length > 0 ? (
                            <div key={l.name} className="question-group-wrapper">
                              <b>{l.name}</b>
                              <div className="question-list">
                                {questionIds.map((q) => this.renderQuestion(q))}
                              </div>
                            </div>
                          ) : null;
                        }
                      })}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  }

  openHistory() {
    const inspection: Inspection = this.state.inspection;
    {
      this.setState({
        inspectionHistory: undefined,
        savedInspection: this.state.inspection,
        menuPopover: { visible: false },
      });
      let inspectionHistory = [];

      let query: any = inspectionHistoryColRef(
        firestore,
        this.props.profile.organisationId
      );

      ['lotId', 'type', 'orderId'].forEach((k) => {
        const refVal = inspection.reference[k];
        if (!!refVal) {
          query = query.where(`reference.${k}`, '==', refVal);
        }
      });

      query
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            inspectionHistory.push({ id: doc.id, data: doc.data() });
          });

          inspectionHistory = inspectionHistory.sort(
            (a1, b1) =>
              b1.data.lastModifiedDate.toDate().getTime() -
              a1.data.lastModifiedDate.toDate().getTime()
          );
          // a.forEach(doc => console.log(doc.id, " => ", doc.data.lastModifiedDate.toDate(), doc.data.status, doc.data.pictures.length))
          this.setState({ inspectionHistory });
        })
        .catch((error) => {
          this.setState({ inspectionHistory: [], savedInspection: undefined });
          console.error('Error getting assessmentHistory documents: ', error);
        });
    }
  }

  updateWithHistory() {
    if (!window.confirm('are you sure you want to override this assessment?')) {
      return;
    }
    const { inspection } = this.state;

    this.setState({
      savedInspection: inspection,
      inspectionHistory: [],
      showApplyAssessment: false,
    });

    if (inspection.objectType !== FieldSchemaObjectTypeEnum.Field) {
      updateLotOrTransitInspectionInRelatedEntities(
        firestore,
        this.props.profile,
        inspection,
        [],
        this.props.organisation,
        true,
        this.props.supplyChainLot
      );
    } else {
      updateProductionSiteInspectionInRelatedEntities(
        firestore,
        this.props.profile,
        inspection,
        this.props.organisation
      );
    }
  }

  closeHistory() {
    this.setState({
      inspectionHistory: [],
      showApplyAssessment: false,
      savedInspection: undefined,
      inspection: this.state.savedInspection,
      menuPopover: { visible: false },
    });
  }

  getRouterLink() {
    const { inspection } = this.state;
    const { profile } = this.props;

    return inspection.objectType !== FieldSchemaObjectTypeEnum.Field
      ? buildLotOrTransitInspectionPath(
          profile.organisationId,
          inspection.reference,
          this.props.supplyChainLot || inspection.reference.type === 'upcoming',
          inspection.renderingInfo?.externalProperties?.boxes_expected ||
            this.state.order?.positions.find(
              (p) => p.lotId === inspection.reference.lotId
            )?.numBoxes,
          this.state.order?.qcStatus
        )
      : buildProdSiteInspectionPath(profile.organisationId, inspection.reference);
  }

  async uploadProdSiteInspectionPictures(files: FileList) {
    const { profile, organisation } = this.props;
    const { inspection } = cloneDeep(this.state);

    this.setState({ ionLoading: true });

    // // upload files
    let { errors, newAttachments } = await uploadInspectionImages(
      storage,
      files,
      profile,
      this.props.inspectionReference
    );
    console.log(errors, newAttachments);

    if (errors.length > 0) {
      return presentStandardToast(
        toastController,
        `Following files could not be uploaded:\r\n${errors
          .map((e) => `File: ${e.filename}, ${e.error}`)
          .join('\r\n')}`
      );
    }

    let pictures = newAttachments.map((a) => {
      return {
        id: a.replace(INSPECTION_IMAGES_STORAGE_BASE_PATH + '/', ''),
        inputIds: ['upload image'],
      };
    });

    inspection.pictures = (inspection.pictures ?? []).concat(pictures);

    await updateProductionSiteInspectionInRelatedEntities(
      firestore,
      profile,
      inspection as ProductionSiteInspection,
      organisation
    );

    this.setState({ ionLoading: false, menuPopover: { visible: false } });
  }

  render() {
    let { inspection, inspectionHistory } = this.state;
    const { profile, reportId } = this.props;
    let isTransport = !!(inspection as LegacyInspection)?.reference.transportId;
    let myOrgAssessment =
      this.props.profile?.organisationId === this.props.organisation?.id &&
      !this.props.sharedReport;

    const displayReportOptions =
      myOrgAssessment && this.state.order?.qcStatus !== 'SHARED';

    const displayChat =
      myOrgAssessment &&
      userHasPermission(profile, 'SHARE_INT', 'REPORT') &&
      orgHasPermission(
        this.props.organisation?.settings,
        AppProduct.QualityControl,
        QCTierAction.Chat
      );

    // Popover permissions:
    const allowReopen = userHasPermission(profile, 'WRITE', 'ASSESSMENT');
    // Don't allow uploading pictures from the context of a report
    const allowAddImages = userHasPermission(profile, 'UPLOAD', 'PICTURE') && !reportId;
    const allowRevertHistory = userHasPermission(profile, 'REVERT', 'ASSESSMENT');

    let assessmentTypeTitle = !!inspection?.reference?.type
      ? inspection.reference.type + ' '
      : '';

    if (this.props.supplyChainLot) {
      assessmentTypeTitle = 'Upcoming ';
    }

    const title = `${capitalizeFirstLetter(assessmentTypeTitle)}${i18n.t(
      'PageInspection.title'
    )}`;

    return (
      <IonPage
        className={'page-inspection-result' + (isTransport ? ' transport-report' : '')}
      >
        {/* Modal Page New Conversation */}
        {profile && (
          <IonModal
            isOpen={this.state.showNewConversation}
            onDidDismiss={(_) => this.cancelNewConversation()}
          >
            <PageNewConversation
              onCancel={(_) => this.cancelNewConversation()}
              onSave={(conversation) => this.saveNewConversation(conversation)}
            ></PageNewConversation>
          </IonModal>
        )}

        {/* Modal Page Gallery */}
        {!!inspection && (
          <IonModal
            isOpen={this.state.showPageGallery}
            onDidDismiss={(ev) =>
              this.setState({ showPageGallery: false, showPagePicture: false })
            }
          >
            <PageGallery
              editable={false}
              pictures={inspection.pictures}
              showDefectId={this.state.showDefectId}
              // schema={selectedSchema}
              inspection={inspection}
              onDismiss={(ev) =>
                this.setState({ showPageGallery: false, showPagePicture: false })
              }
              onPicturesUpdated={(_: any) => {}}
            />
          </IonModal>
        )}

        {/* Modal Page Picture */}
        {!!inspection && (
          <IonModal
            isOpen={this.state.showPagePicture}
            onDidDismiss={(ev) =>
              this.setState({ showPageGallery: false, showPagePicture: false })
            }
          >
            <PagePicture
              editable={false}
              pictures={inspection.pictures}
              inspection={inspection}
              picture={this.state.showPicture}
              // schema={selectedSchema}
              onDismiss={(ev) =>
                this.setState({ showPagePicture: false, showPageGallery: false })
              }
            />
          </IonModal>
        )}

        {/* Modal Select Picture Import */}
        {inspection?.objectType !== FieldSchemaObjectTypeEnum.Field &&
          profile &&
          this.state.showModalSelectPicturesImport && (
            <IonModal
              isOpen={this.state.showModalSelectPicturesImport}
              onDidDismiss={(ev) =>
                this.setState({ showModalSelectPicturesImport: false })
              }
            >
              <ModalSelectPicturesImport
                profile={profile}
                organisation={this.props.organisation}
                inspection={inspection}
                inspectionReference={this.props.inspectionReference}
                onCancel={(ev) =>
                  this.setState({ showModalSelectPicturesImport: false })
                }
                supplyChainLot={this.props.supplyChainLot}
              />
            </IonModal>
          )}

        {/* Popover for side menu */}
        {/* {myOrgAssessment && (assessment?.reference.type === "INCOMING" || assessment?.reference.type === "OUTGOING") ? */}
        {myOrgAssessment && !!inspection ? (
          <IonPopover
            mode="md"
            isOpen={this.state.menuPopover.visible}
            event={this.state.menuPopover.event}
            onDidDismiss={() => this.setState({ menuPopover: { visible: false } })}
          >
            <IonList>
              {!allowReopen && !allowAddImages && !allowRevertHistory ? (
                <IonItem>
                  <IonText color="medium">No actions available</IonText>
                </IonItem>
              ) : (
                <>
                  {allowReopen && (
                    <IonItem
                      button
                      routerLink={this.getRouterLink()}
                      onClick={(_) =>
                        this.setState({ menuPopover: { visible: false } })
                      }
                    >
                      View inspection
                    </IonItem>
                  )}

                  {allowAddImages && (
                    <>
                      <IonItem
                        button
                        onClick={(_) =>
                          inspection.objectType === FieldSchemaObjectTypeEnum.Field
                            ? this.fileInput.current.click()
                            : this.setState({
                                showModalSelectPicturesImport: true,
                                menuPopover: { visible: false },
                              })
                        }
                      >
                        Add images
                      </IonItem>
                      <CFileUpload
                        fileInputRef={this.fileInput}
                        onFilesSelect={this.uploadProdSiteInspectionPictures.bind(this)}
                      />
                    </>
                  )}

                  {/* close history button */}
                  {allowRevertHistory && (
                    <>
                      {this.state.inspectionHistory?.length > 0 && (
                        <IonItem
                          button
                          onClick={(_) =>
                            this.setState({
                              inspectionHistory: [],
                              showApplyAssessment: false,
                              savedInspection: undefined,
                              inspection: this.state.savedInspection,
                              menuPopover: { visible: false },
                            })
                          }
                        >
                          Close History
                        </IonItem>
                      )}

                      {/* open history button */}
                      {this.state.inspectionHistory?.length === 0 && (
                        <IonItem button onClick={(_) => this.openHistory()}>
                          History
                        </IonItem>
                      )}
                    </>
                  )}
                </>
              )}

              {/* <IonItem button onClick={_ => this.approve()}>{i18n.t('PageReport.approve')}</IonItem> */}
            </IonList>
          </IonPopover>
        ) : (
          ''
        )}

        {/* Header */}
        <IonHeader translucent={true} className="no-print">
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton
                text={i18n.t('General.back')}
                defaultHref={this.getDefaultBackUrl()}
                color="dark"
              >
                <IonIcon icon={chevronBackOutline} slot="icon-only" />
              </IonBackButton>
            </IonButtons>

            {!isFreeQCTier(this.props.organisation?.settings) && (
              <IonButtons slot="end">
                {displayReportOptions ? (
                  <IonButton
                    color="dark"
                    onClick={(evt) => {
                      evt.persist();
                      this.setState({ menuPopover: { visible: true, event: evt } });
                    }}
                  >
                    <IonIcon icon={ellipsisHorizontal}></IonIcon>
                  </IonButton>
                ) : (
                  ''
                )}
              </IonButtons>
            )}

            <IonTitle>{title}</IonTitle>

            {displayChat && (
              <IonButtons slot="end">
                <IonButton color="dark" onClick={(_) => this.startNewConversation()}>
                  <IonIcon icon={chatbubbleEllipsesOutline}></IonIcon>
                </IonButton>
              </IonButtons>
            )}
          </IonToolbar>
        </IonHeader>

        <IonContent>
          {/* ---------------------------------------------------------------- */}
          {/* Render reportHistory */}
          {/* ---------------------------------------------------------------- */}
          {inspectionHistory === undefined && (
            <div className="history-loading">
              <IonSpinner />
            </div>
          )}
          {inspectionHistory?.length > 0 && !!this.state.inspection && (
            <div className="history">
              <div className="history-title">
                <IonIcon icon={timeOutline} />
                History
                <IonButtons>
                  <IonButton onClick={(_) => this.closeHistory()}>
                    <IonIcon icon={closeOutline} slot="icon-only" />
                  </IonButton>
                </IonButtons>
              </div>
              {inspectionHistory.map((o) => (
                <div
                  key={o.data.lastModifiedDate}
                  className={
                    o.data.lastModifiedDate === this.state.inspection.lastModifiedDate
                      ? 'selected'
                      : ''
                  }
                  onClick={(evt) => {
                    this.setState({
                      inspection: inspectionHistory.find((a) => o.id === a.id).data,
                      showApplyAssessment: true,
                    });
                  }}
                >
                  <IonBadge color="medium">
                    {formatDate(o.data.lastModifiedDate, {
                      dateStyle: 'short',
                      timeStyle: 'short',
                    })}{' '}
                    <br />
                  </IonBadge>
                  <div>
                    {
                      this.props.users.find((u) => u.id === o.data.lastModifiedUserId)
                        ?.email
                    }
                  </div>
                </div>
              ))}
              {this.state.showApplyAssessment && (
                <IonButtons className="b-bottom">
                  <IonButton
                    color="primary"
                    fill="solid"
                    onClick={(_) => this.updateWithHistory()}
                  >
                    Apply selected history
                  </IonButton>
                </IonButtons>
              )}
            </div>
          )}

          {/* ---------------------------------------------------------------- */}
          {/* Render report */}
          {/* ---------------------------------------------------------------- */}

          {inspection === undefined ? (
            <div className="report-not-available">
              <div className="loading">
                <IonSpinner name="dots" />
              </div>
            </div>
          ) : inspection === null ? (
            <div className="report-not-available">
              <h1 className="h1-not-found">Not Found</h1>
            </div>
          ) : (
            this.renderReport()
          )}
          <IonLoading isOpen={this.state.ionLoading} />
        </IonContent>
      </IonPage>
    );
  }
}

export default React.memo(
  withContext(withIonLifeCycle(PageInspectionView), [
    'locations',
    'inspectionSpecs',
    'organisation',
    'users',
    'profile',
  ])
);

interface LPProps {
  lotProperties: LotProperties;
  setInspection?: (value: React.SetStateAction<LotInspection>) => void;
  isEditable?: boolean;
}

export const ViewLotProperties: React.FC<LPProps> = ({
  lotProperties,
  setInspection,
  isEditable,
}) => {
  const canDeleteValues = isEditable && !!setInspection;

  const showDeleteDialog = async (message: string, handler: () => void) => {
    const alert = await alertController.create({
      header: 'Warning',
      message,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Confirm',
          cssClass: 'alert-button danger-button',
          handler,
        },
      ],
    });

    await alert.present();
  };

  const handleDeleteOCRProperty = (value: string, type: 'ggn' | 'gln' | 'coc') => {
    canDeleteValues &&
      setInspection((inspection) => {
        const lotPropId = `${type}List`;
        const newList = inspection.lotProperties[lotPropId].filter(
          (v: string) => v !== value
        );
        const newInspection = {
          ...inspection,
          lotProperties: {
            ...inspection.lotProperties,
            [lotPropId]: newList,
          },
        };
        return newInspection;
      });
  };

  const handleDeletePallet = (palletId: string) => {
    canDeleteValues &&
      setInspection((inspection) => {
        const newInspection = {
          ...inspection,
          lotProperties: {
            ...inspection.lotProperties,
            palletIds: inspection.lotProperties.palletIds.filter((p) => p !== palletId),
          },
        };
        return newInspection;
      });
  };

  const className = `${canDeleteValues ? 'badge-clickable' : ''}`;

  const icon = canDeleteValues ? <IonIcon icon={closeOutline} /> : '';

  return (
    <>
      {(lotProperties?.palletIds ?? []).length > 0 && (
        <div className="pallets">
          <div>
            <b>Pallet IDs ({lotProperties?.palletIds?.length})</b>
          </div>
          <div>
            {lotProperties.palletIds.map((p) => (
              <IonBadge
                key={p}
                onClick={() => {
                  if (!canDeleteValues) return;
                  showDeleteDialog(
                    `Are you sure you want to remove pallet nr. ${p}?`,
                    () => handleDeletePallet(p)
                  );
                }}
                className={className}
                color="medium"
              >
                {p}
                {icon}
              </IonBadge>
            ))}
          </div>
        </div>
      )}

      {(lotProperties?.ggnList ?? []).length > 0 && (
        <div className="ggn">
          <div>
            <b>GGNs ({lotProperties?.ggnList?.length})</b>
          </div>
          <div>
            {lotProperties.ggnList.map((g) => (
              <IonBadge
                onClick={() => {
                  if (!canDeleteValues) return;
                  showDeleteDialog(`Are you sure you want to remove GGN ${g}?`, () =>
                    handleDeleteOCRProperty(g, 'ggn')
                  );
                }}
                className={className}
                color="medium"
                key={g}
              >
                {g}
                {icon}
              </IonBadge>
            ))}
          </div>
        </div>
      )}

      {(lotProperties?.glnList ?? []).length > 0 && (
        <div className="gln">
          <div>
            <b>GLNs ({lotProperties?.glnList?.length})</b>
          </div>
          <div>
            {lotProperties.glnList.map((g) => (
              <IonBadge
                onClick={() => {
                  if (!canDeleteValues) return;
                  showDeleteDialog(`Are you sure you want to remove GLN ${g}?`, () =>
                    handleDeleteOCRProperty(g, 'gln')
                  );
                }}
                className={className}
                color="medium"
                key={g}
              >
                {g}
                {icon}
              </IonBadge>
            ))}
          </div>
        </div>
      )}

      {(lotProperties?.cocList ?? []).length > 0 && (
        <div className="gln">
          <div>
            <b>CoCs ({lotProperties?.cocList?.length})</b>
          </div>
          <div>
            {lotProperties.cocList.map((c) => (
              <IonBadge
                onClick={() => {
                  if (!canDeleteValues) return;
                  showDeleteDialog(`Are you sure you want to remove CoC ${c}?`, () =>
                    handleDeleteOCRProperty(c, 'coc')
                  );
                }}
                className={className}
                color="medium"
                key={c}
              >
                {c}
                {icon}
              </IonBadge>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

interface REIProps {
  inspection?: Inspection;
}
const RenderEntityInfo = ({ inspection }: REIProps) => {
  const locations = useContext(ctxLocations);
  const { getProductionTypeLabel } = useLocationUtils();

  if (!inspection) return null;

  return inspection.objectType !== FieldSchemaObjectTypeEnum.Field ? (
    <div className="top-wrapper">
      <div className="lot-info">
        <div>{inspection.reference.lotId || inspection.reference.transportId}</div>
        <div>{inspection.reference.orderId}</div>
      </div>
      {inspection.objectType === LotSchemaObjectTypeEnum.Lot && (
        <div className="article-info">
          <ViewArticleDescription
            article={inspection.lotProperties?.article}
            pills={true}
          />
        </div>
      )}
    </div>
  ) : (
    (() => {
      const location = locations.find(
        (l) => l.locationId === inspection.reference.locationId
      ) as ProductionSiteLocation;

      if (!location) return null;

      const name = location.name ?? inspection.reference.locationId;

      return (
        <div className="top-wrapper">
          <div className="lot-info">
            <div>{name}</div>
            {location.locationId !== name && <div>id: {location.locationId}</div>}
          </div>
          <div>{getProductionTypeLabel(location.productionType)}</div>
        </div>
      );
    })()
  );
};
