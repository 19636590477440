import { Capacitor } from '@capacitor/core';
import { IonButton, IonIcon, IonListHeader, IonModal } from '@ionic/react';
import {
  cameraOutline,
  chevronDown,
  chevronForwardOutline,
  helpCircleOutline,
  imagesOutline,
} from 'ionicons/icons';
import React, { useState } from 'react';
import CInspectionItem from '../CInspectionItem';
import { CardImages } from '../CardImages';
import { filterSectionOrSubsectionLayout } from '../InspectionLogic';
import { SectionNames } from '../ModelSpecification';
import PagePicture from '../PagePicture';
import ViewPicture from '../ViewPicture';
import {
  InspectionSpecSection,
  InspectionSpecSubsection,
  LayoutInner1,
} from '../generated/openapi/core';
import { useInspectionContext } from '../context/InspectionContextProvider';

interface CSectionProps {
  onNativePicture: (section?: InspectionSpecSection) => void;
  section: InspectionSpecSection;
  cameraRef?: any;
  open?: boolean;
}

export const CSection = ({
  onNativePicture,
  section,
  cameraRef,
  open = true,
}: CSectionProps) => {
  const {
    isPreview,
    spec: schema,
    inspection,
    context,
    renderedQuestionIds,
  } = useInspectionContext();

  const filteredLayout = isPreview
    ? section.layout
    : filterSectionOrSubsectionLayout(
        schema?.questionSpecs,
        context,
        inspection,
        section
      );

  const isImageContainer = filteredLayout.length === 0 && section.imageTag !== null;
  const pictures =
    inspection?.pictures?.filter((p) => p.imageTag === section.imageTag) ?? [];

  const hideSection = (l: LayoutInner1[]) =>
    l?.length === 0 && !isImageContainer && !isPreview;

  const renderSubSections = (subsection: InspectionSpecSubsection) => {
    const filteredQuestionIds = isPreview
      ? subsection.questionIds
      : (filterSectionOrSubsectionLayout(
          schema?.questionSpecs,
          context,
          inspection,
          undefined,
          subsection
        ) as string[]);

    if (hideSection(filteredQuestionIds)) {
      return null;
    }

    return (
      <div key={subsection.name} className="sub-section-wrapper">
        <ToggleView
          onNativePicture={() => {}}
          section={subsection}
          showCamera={(_) => {}}
          className={'sub-toggle'}
          open={subsection.expandByDefault}
        />
        {filteredQuestionIds?.map((question) => {
          if (!!renderedQuestionIds?.current) {
            renderedQuestionIds.current = Array.from(
              new Set([...renderedQuestionIds.current, question])
            );
          }
          return (
            <CInspectionItem
              question={question}
              questionSpecs={schema.questionSpecs[question]}
              key={question}
              section={section}
            />
          );
        })}
      </div>
    );
  };

  if (hideSection(filteredLayout)) {
    return null;
  }

  return (
    <div key={section.name}>
      <ToggleView
        section={section}
        isSection={true}
        open={open && section.expandByDefault}
        isImageContainer={isImageContainer}
        showCamera={(section) => cameraRef?.current?.openCamera(section)}
        onNativePicture={onNativePicture}
      />
      {filteredLayout.map((question) => {
        if (typeof question === 'string') {
          if (!!renderedQuestionIds?.current) {
            renderedQuestionIds.current = Array.from(
              new Set([...renderedQuestionIds.current, question])
            );
          }
          return (
            <CInspectionItem
              question={question}
              questionSpecs={schema.questionSpecs[question]}
              key={question}
              section={section}
            />
          );
        } else {
          return renderSubSections(question);
        }
      })}
      {isImageContainer && (
        <div>
          <CardImages max={pictures.length} pictures={pictures} />
        </div>
      )}
    </div>
  );
};

// ----------------------------------------------------------------
// Section Toggle Component
// ----------------------------------------------------------------
interface PropsToggle {
  section: InspectionSpecSection | InspectionSpecSubsection;
  isSection?: boolean;
  open?: boolean;
  onNativePicture: (section?: InspectionSpecSection) => void;
  showCamera: Function;
  className?: string;
  isImageContainer?: boolean;
}

const TV: React.FC<PropsToggle> = ({
  section,
  isSection = false,
  open = true,
  onNativePicture,
  showCamera,
  className,
  isImageContainer = false,
}) => {
  const [toggle, setToggle] = useState(open);
  const [showSectionHint, setShowSectionHint] = useState(false);
  const [picture, setPicture] = useState<string>(undefined);

  const { inspection } = useInspectionContext();

  className = className ?? '';

  const pics = inspection?.pictures?.filter(
    (p) => p.imageTag === (section as InspectionSpecSection).imageTag
  );
  const numPictures = pics?.length;

  const renderToggle = () => {
    return (
      <IonIcon className="toggle" icon={toggle ? chevronDown : chevronForwardOutline} />
    );
  };

  const title =
    SectionNames[(section as InspectionSpecSection).sectionType] ?? section.name;
  const hint = (section as InspectionSpecSection).hint;

  // console.log('section', title, section)

  return (
    <>
      <IonListHeader
        mode="md"
        onClick={(_) => setToggle(!toggle)}
        className={`toggle-section ${className} ${toggle ? 'open' : 'closed'}`}
      >
        <div className="ion-label">
          {renderToggle()}
          <h1>{title}</h1>
        </div>
        <div className="section-align-right">
          {!!hint && (
            <>
              <IonIcon
                className="hint"
                icon={helpCircleOutline}
                data-tooltip-id={title}
                data-tooltip-content={hint}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowSectionHint(!showSectionHint);
                }}
              />
            </>
          )}
          {isImageContainer && numPictures > 0 ? (
            <div className="pic-container">
              <div className={`pic-indicator`}>
                <div className={`pic-count ${numPictures === 0 ? '' : 'many'}`}>
                  {numPictures}
                </div>
                <IonIcon icon={imagesOutline} />
              </div>
            </div>
          ) : null}
          {isImageContainer && (
            <IonButton
              fill="solid"
              mode="ios"
              onClick={async (e) => {
                e.stopPropagation();
                if (Capacitor.isNativePlatform() && isSection) {
                  onNativePicture(section as InspectionSpecSection);
                } else {
                  showCamera(section);
                }
              }}
            >
              <IonIcon icon={cameraOutline} /> &nbsp;ADD
            </IonButton>
          )}
        </div>
      </IonListHeader>
      {showSectionHint ? (
        <div className="section-hint">
          <div className="section-hint-title">
            {/* {title} */}
            Instructions for {title}:
          </div>
          <div className="section-hint-description">{hint}</div>
          <div className="section-hint-gallery">
            {/* @ts-ignore */}
            {section.hintImages?.map((image) => (
              <ViewPicture
                key={image}
                id={image}
                onClick={() => setPicture(image)}
                location=""
                resolution="thumbnail"
              />
            ))}
          </div>
          <IonModal isOpen={!!picture} onDidDismiss={() => setPicture(undefined)}>
            <PagePicture
              editable={false}
              location=""
              picture={{ id: picture }}
              // @ts-ignore
              pictures={section.hintImages?.map((p: AnnotatedImage) => {
                return { id: p.storageId };
              })}
              onDismiss={() => setPicture(undefined)}
            />
          </IonModal>
        </div>
      ) : null}
    </>
  );
};

const ToggleView = React.memo(TV, (prev, next) => prev.open === next.open);
