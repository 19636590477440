import {
  IonContent,
  IonHeader,
  IonModal,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { isEqual } from 'lodash';
import React, { useContext, useState } from 'react';
import { toastController } from '@ionic/core';
import {
  ProductionSiteInspection,
  ProductionSiteInspectionReference,
} from '../../InspectionModel';
import ModalShare from '../../ModalShare';
import { ProductionSiteLocation } from '../../Model';
import { LocationDetailsHeader } from '../../components/location/LocationDetailsHeader/LocationDetailsHeader';
import { LocationInspections } from '../../components/location/LocationInspections/LocationInspections';
import { LocationProperties } from '../../components/location/LocationProperties/LocationProperties';
import { LocationActions } from './LocationActions/LocationActions';
import { addSharedFieldReport } from '../../data/sharedFieldReport/sharedFieldReport.service';
import { firestore } from '../../ConfigFirebase';
import { ctxOrg, ctxProfile } from '../../App';
import { presentStandardToast } from '../../HelperIonic';

interface LDProps {
  field: ProductionSiteLocation;
  associatedPartnerName?: string;
  inspections: ProductionSiteInspection[];
  isLoadingInspections: boolean;
}
export const LocationDetailsContainer = ({
  field,
  inspections,
  associatedPartnerName,
  isLoadingInspections,
}: LDProps) => {
  const org = useContext(ctxOrg);
  const profile = useContext(ctxProfile);

  const { name, geoPoint } = field;

  const [isInSharingFlow, setIsInSharingFlow] = useState<boolean>(false);
  const [selectedInspections, setSelectedInspections] = useState<
    ProductionSiteInspection[]
  >([]);
  const [showShareModal, setShowShareModal] = useState<boolean>(false);

  const toggleInspection = (reference: ProductionSiteInspectionReference) => {
    const hasSameRef = (i: ProductionSiteInspection) => isEqual(i.reference, reference);
    if (selectedInspections.some(hasSameRef)) {
      setSelectedInspections((curr) => curr.filter((i) => !hasSameRef(i)));
    } else {
      setSelectedInspections((curr) => [...curr, inspections.find(hasSameRef)]);
    }
  };

  const onShareCancel = () => {
    setIsInSharingFlow(false);
    setSelectedInspections([]);
  };

  const onShareAccept = () => {
    setShowShareModal(true);
  };

  const shareFieldReport = async (res: { emails: string[]; message: string }) => {
    try {
      await addSharedFieldReport(
        firestore,
        field,
        selectedInspections,
        org.id,
        profile,
        res.emails,
        res.message,
        associatedPartnerName
      );
      await presentStandardToast(
        toastController,
        `Report shared successfully`,
        3000,
        'success'
      );
    } catch (error) {
      await presentStandardToast(
        toastController,
        `Something went wrong sharing the report:\n${error}`
      );
    } finally {
      onShareCancel();
      setShowShareModal(false);
    }
  };

  return (
    <IonPage className="page-lot">
      <LocationDetailsHeader name={name} geopoint={geoPoint} />
      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">
              <div>{name}</div>
            </IonTitle>
          </IonToolbar>
        </IonHeader>

        <LocationProperties
          field={field}
          associatedPartnerName={associatedPartnerName}          
        />

        <LocationInspections
          field={field}
          inspections={inspections}
          isLoadingInspections={isLoadingInspections}
          isInSharingFlow={isInSharingFlow}
          selectedInspections={selectedInspections}
          toggleInspection={toggleInspection}
        />

        <LocationActions
          field={field}
          isInSharingFlow={isInSharingFlow}
          onShareStart={() => setIsInSharingFlow(true)}
          onShareCancel={onShareCancel}
          onShareAccept={onShareAccept}
          selectedInspections={selectedInspections}
          hasInspections={inspections.length > 0}
        />

        {showShareModal && (
          <IonModal
            cssClass={'modal-share'}
            isOpen={showShareModal}
            onDidDismiss={() => setShowShareModal(false)}
          >
            <ModalShare
              onCancel={() => setShowShareModal(false)}
              title={'Share field report'}
              onSuccess={shareFieldReport}
              contactId={field.associatedPartnerId}
            />
          </IonModal>
        )}
      </IonContent>
    </IonPage>
  );
};
