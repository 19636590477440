import { IonBadge, IonButton, IonIcon, IonModal } from '@ionic/react';
import {
  addCircleOutline,
  caretBackOutline,
  closeOutline,
  shareOutline,
} from 'ionicons/icons';
import React, { useContext, useState } from 'react';
import { ctxOrg, ctxProfile } from '../../../App';
import {
  ProductionSiteInspection,
  ProductionSiteInspectionReference,
} from '../../../InspectionModel';
import { ProductionSiteLocation } from '../../../Model';
import { userHasPermission } from '../../../PermissionsService';
import { buildProdSiteInspectionPath } from '../../../ServiceInspection';
import { InspectionType } from '../../../generated/openapi/core';
import { useGetFieldReportShares } from '../../../data/sharedFieldReport/sharedFieldReport.hooks';
import { firestore } from '../../../ConfigFirebase';
import { CSharedHistory } from '../../../components/CSharedHistory';
import { useLocationUtils } from '../../../hooks/useLocationUtils';

interface LAProps {
  field: ProductionSiteLocation;
  isInSharingFlow: boolean;
  onShareStart: () => void;
  onShareCancel: () => void;
  onShareAccept: () => void;
  selectedInspections: ProductionSiteInspection[];
  hasInspections: boolean;
}
export const LocationActions = ({
  field,
  onShareStart,
  onShareCancel,
  onShareAccept,
  isInSharingFlow,
  selectedInspections,
  hasInspections,
}: LAProps) => {
  const org = useContext(ctxOrg);
  const profile = useContext(ctxProfile);
  const { buildSharedFieldReportUrl: buildSharedLocationReportUrl } =
    useLocationUtils();

  const { productionType, locationId, name } = field;

  const { sharedTo } = useGetFieldReportShares(firestore, locationId);

  const [showShares, setShowShares] = useState<boolean>(false);

  const canCreateInspection = userHasPermission(profile, 'WRITE', 'ASSESSMENT');
  const canShareReport =
    userHasPermission(profile, 'SHARE_EXT', 'ASSESSMENT') && hasInspections;

  const getNewInspectionLink = () => {
    const reference: ProductionSiteInspectionReference = {
      locationId,
      productionType,
      type: InspectionType.PreHarvest,
      date: new Date().getTime().toString(),
    };
    return buildProdSiteInspectionPath(org.id, reference);
  };

  const shareButtonText =
    selectedInspections.length > 0
      ? `share ${selectedInspections.length} inspection${
          selectedInspections.length > 1 ? 's' : ''
        }`
      : 'Select inspections';

  return (
    <div slot="fixed" className="button-container">
      {isInSharingFlow ? (
        <>
          <IonButton
            disabled={selectedInspections.length === 0}
            expand="block"
            color="tertiary"
            onClick={onShareAccept}
          >
            <IonIcon
              icon={selectedInspections.length > 0 ? shareOutline : caretBackOutline}
            ></IonIcon>
            &nbsp;{shareButtonText}
          </IonButton>
          <IonButton expand="block" color="tertiary" onClick={onShareCancel}>
            <IonIcon icon={closeOutline}></IonIcon>
            &nbsp; cancel
          </IonButton>
        </>
      ) : (
        <>
          {sharedTo.length > 0 && (
            <IonButton
              expand="block"
              color="primary"
              onClick={() => setShowShares(true)}
            >
              View shares &nbsp;<IonBadge color="danger">{sharedTo.length}</IonBadge>
            </IonButton>
          )}
          {canShareReport && (
            <IonButton expand="block" color="tertiary" onClick={onShareStart}>
              <IonIcon icon={shareOutline}></IonIcon>
              &nbsp; share report
            </IonButton>
          )}
          {canCreateInspection && (
            <IonButton
              expand="block"
              color="tertiary"
              routerLink={getNewInspectionLink()}
            >
              <IonIcon icon={addCircleOutline}></IonIcon>
              &nbsp; new inspection
            </IonButton>
          )}
          {showShares && (
            <IonModal isOpen={showShares} onDidDismiss={() => setShowShares(false)}>
              <CSharedHistory
                onCancel={() => setShowShares(false)}
                name={name}
                buildLink={buildSharedLocationReportUrl}
                sharedTo={sharedTo}
              />
            </IonModal>
          )}
        </>
      )}
    </div>
  );
};
