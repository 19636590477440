import { getAuth } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import * as _ from 'lodash';
import { cloneDeep, isEqual, uniq, omit } from 'lodash';
import { updateOrderReportReferences } from './DataReport';
import { FirebaseSpecificationRepository } from './DataSpecification';
import {
  ARCHIVED_COL_NAME,
  COMMON_COL_NAME,
  FIREBASE_FUNCTIONS_REGION,
  LOT_DATE_SORTING_FIELD,
  ORDER_DATE_SORTING_FIELD,
  SUPPLY_CHAIN_COL_NAME,
} from './GlobalConstants';
import {
  changeLotId,
  computeTransientQuantities,
  createLotActionId,
  filterFromPositions,
  filterToPositions,
  getProductionTransferOfLotCreatedFromBatchTransformation,
  getTodayMinusXDays,
  getWasteId,
  isValidSubLot,
  mapLotToLotAction,
  mapLotTransferSummary,
  mapProductionOrSplitLotPayloadPositions,
  readFileAsync,
  sortLotTransfers,
  standardizeDate,
} from './HelperUtils';
import {
  AGQuestion,
  Claim,
  ClaimCreatePayload,
  ClaimableOrderType,
  Contact,
  ContactUpdatePayload,
  Conversation,
  ConversationMessage,
  DeletionInfo,
  IArticle,
  InternalTransferPayload,
  Invite,
  InviteStatus,
  LastRead,
  Location,
  Lot,
  LotCreatePayload,
  LotOriginProps,
  LotPosition,
  LotPreviousOrgProps,
  LotTransfer,
  LotTransientProps,
  LotUpdateObject,
  OfflineSyncingStatus,
  Order,
  OrderCreatePayload,
  OrderType,
  Organisation,
  OrganisationSettings,
  PreloadDataEntities,
  Product,
  ProductMapping,
  ProductView,
  ProductionPayload,
  Report,
  SplitLotPayload,
  Todo,
  User,
  UserInvite,
  UserProfile,
  UserProperties,
  UserRoleType,
  VarietyMapping,
  Waste,
  copyOrderForSharing,
  createNewEmptyOrder,
  updateAggregateModificationData,
  uuid4,
} from './Model';
import { FilterType } from './ModelAGTabs';
import { generateLotSearch, generateOrderSearch } from './SearchService';
import { Article } from './ServiceArticle';
import {
  hasInspectionReference,
  replaceArticleInLotInspections,
} from './ServiceInspection';
import { AnnotatedImage } from './generated/openapi/core';
import { LotInspection } from './InspectionModel';

/***********************/
// HELPERS
/***********************/

function inTransaction(
  store: firebase.firestore.Firestore,
  trxFunction: (transaction: firebase.firestore.Transaction) => Promise<any>
): Promise<any> {
  if (isOnline()) {
    return store.runTransaction(async (transaction: firebase.firestore.Transaction) => {
      return trxFunction(transaction);
    });
  } else {
    return trxFunction(undefined);
  }
}

export type PagedResult<T> = {
  cursor: firebase.firestore.DocumentSnapshot;
  data: T[];
};

let isOnline = () => {
  return window.navigator.onLine;
};

export function overrideIsOnline(newOnlineFunction: any) {
  isOnline = newOnlineFunction;
}

export function conditionalPagingCriteria(
  cursor: firebase.firestore.DocumentSnapshot,
  collectionRef: any
) {
  if (cursor) {
    return collectionRef.startAfter(cursor);
  }
  return collectionRef;
}

// Returning null in the callback means an error occurred
export const handleOnDocSnapshot = <T>(
  onResult: (data: T | null) => void
): [
  (
    snapshot: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
  ) => void,
  (error: firebase.firestore.FirestoreError) => void
] => [
  (doc: firebase.firestore.DocumentSnapshot) => {
    try {
      onResult(doc.exists ? (doc.data() as T) : undefined);
    } catch (error) {
      console.log(error);
      onResult(null);
    }
  },
  (error: firebase.firestore.FirestoreError) => {
    console.error('Error fetching snapshot:', error);
    onResult(null);
  },
];

// Returning null in the callback means an error occurred
export const handleOnCollectionSnapshot = <T>(
  onResult: (data: T[] | null) => void,
  filter?: (d: T) => boolean,
  sort?: (a: T, b: T) => number
): [
  (snapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>) => void,
  (error: firebase.firestore.FirestoreError) => void
] => [
  (docs: firebase.firestore.QuerySnapshot) => {
    try {
      let docsData: T[] = docs.empty ? [] : docs.docs.map((doc) => doc.data() as T);

      if (!!filter) {
        docsData = docsData.filter(filter);
      }

      if (!!sort) {
        docsData = docsData.sort(sort);
      }

      onResult(docsData);
    } catch (error) {
      console.log(error);
      onResult(null);
    }
  },
  (error: firebase.firestore.FirestoreError) => {
    console.error('Error fetching snapshot:', error);
    onResult(null);
  },
];

/***********************/
// CALLABLE FUNCTIONS
/***********************/

/***********************/
/***********************/
// FIRESTORE STUFF
/***********************/
/***********************/

/* ******** */
// 1. REFERENCES
/* ******** */

type FS = firebase.firestore.Firestore;

// ****************************************************************
// 1.1 Organisation agnostic
// ****************************************************************

// ----------------------------------------------------------------
// updateService
// ----------------------------------------------------------------
export const updateService = (firestore: FS) =>
  firestore.collection('updateService').doc('updateService');

// ----------------------------------------------------------------
// Specifications
// ----------------------------------------------------------------

export const specificationsColRef = (fs: FS) => fs.collection('specifications');

export const specQuestionsColRef = (fs: FS) =>
  specificationsColRef(fs).doc('questions').collection('questions');

// ----------------------------------------------------------------
// Users
// ----------------------------------------------------------------
export const userColRef = (fs: FS) => fs.collection('user');

export const userDocRef = (fs: FS, userId: string) => userColRef(fs).doc(userId);

export const profileDocRef = (fs: FS, userId: string) =>
  userDocRef(fs, userId).collection('profile').doc(userId);

// Products
export const productColRef = (fs: FS) => fs.collection('product');

export const productDocRef = (fs: FS, productId: string) =>
  productColRef(fs).doc(productId);

// Shared reports
export const sharedReportColRef = (fs: FS) => fs.collection('sharedReports');

export const sharedReportDocRef = (fs: FS, sharedReportId: string) =>
  sharedReportColRef(fs).doc(sharedReportId);

export const sharedFieldReportColRef = (fs: FS) => fs.collection('sharedFieldReports');

export const sharedFieldReportDocRef = (fs: FS, sharedReportId: string) =>
  sharedFieldReportColRef(fs).doc(sharedReportId);

// Other
export const conversationColRef = (fs: FS) => fs.collection('conversation');

export const conversationDocRef = (fs: FS, convId: string) =>
  conversationColRef(fs).doc(convId);

export const inviteColRef = (fs: FS) => fs.collection('invite');

export const inviteDocRef = (fs: FS, inviteId: string) =>
  inviteColRef(fs).doc(inviteId);

export const userInviteColRef = (fs: FS) => fs.collection('userInvite');

// ----------------------------------------------------------------
// Other
// ----------------------------------------------------------------
export const postgresRetryQueueColRef = (fs: FS) => fs.collection('postgresRetryQueue');

export const eventLogColRef = (fs: FS) => fs.collection('eventLog');

// ----------------------------------------------------------------
// 1.2 Organisation dependant
// ----------------------------------------------------------------

export const orgColRef = (fs: FS) => fs.collection('organisation');

export const orgDocRef = (fs: FS, orgId: string) => orgColRef(fs).doc(orgId);

// Main entities collections
export const lotColRef = (
  fs: FS,
  orgId: string,
  collection: string = COMMON_COL_NAME,
  lotOrgId: string = orgId
) => orgDocRef(fs, orgId).collection(collection).doc(lotOrgId).collection('lot');

export const reportColRef = (fs: FS, orgId: string) =>
  orgDocRef(fs, orgId).collection('report');

export const inspectionColRef = (fs: FS, orgId: string) =>
  orgDocRef(fs, orgId).collection('inspection');

export const inspectionDocRef = (fs: FS, orgId: string, docId: string) =>
  inspectionColRef(fs, orgId).doc(docId);

export const assessmentHistoryColRef = (fs: FS, orgId: string) =>
  orgDocRef(fs, orgId).collection('assessmentHistory');

export const inspectionHistoryColRef = (fs: FS, orgId: string) =>
  orgDocRef(fs, orgId).collection('inspectionHistory');

export const orderColRef = (
  fs: FS,
  orgId: string,
  orderOrgId: string = orgId,
  collection: string = COMMON_COL_NAME
) => orgDocRef(fs, orgId).collection(collection).doc(orderOrgId).collection('order');

// Specifications
export const specColRef = (fs: FS, orgId: string) =>
  orgDocRef(fs, orgId).collection('specifications');

export const templateColRef = (fs: FS, orgId: string) =>
  specColRef(fs, orgId).doc('schemas').collection('templates');

export const schemaColRef = (fs: FS, orgId: string) =>
  specColRef(fs, orgId).doc('schemas').collection('schemas');

// Other
export const claimColRef = (fs: FS, orgId: string) =>
  orgDocRef(fs, orgId).collection('claim');

export const handshakeColRef = (fs: FS, orgId: string) =>
  orgDocRef(fs, orgId).collection('handshake');

export const handshakeDocRef = (fs: FS, org1Id: string, org2Id: string) =>
  handshakeColRef(fs, org1Id).doc(org2Id);

export const lotActionColRef = (fs: FS, orgId: string) =>
  orgDocRef(fs, orgId).collection('lotAction');

export const wasteColRef = (fs: FS, orgId: string) =>
  orgDocRef(fs, orgId).collection('waste');

// Meta
export const metaDocRef = (fs: FS, orgId: string) =>
  orgDocRef(fs, orgId).collection('meta').doc('meta');

export const articleColRef = (fs: FS, orgId: string) =>
  metaDocRef(fs, orgId).collection('article');

export const contactColRef = (fs: FS, orgId: string) =>
  metaDocRef(fs, orgId).collection('contact');

export const locationColRef = (fs: FS, orgId: string) =>
  metaDocRef(fs, orgId).collection('location');

export const productViewColRef = (fs: FS, orgId: string) =>
  metaDocRef(fs, orgId).collection('product');

export const productMappingColRef = (fs: FS, orgId: string) =>
  metaDocRef(fs, orgId).collection('productMapping');

export const varietyMappingColRef = (fs: FS, orgId: string) =>
  metaDocRef(fs, orgId).collection('varietyMapping');

/*********************************/

/***********************/
// 2. MISCELLANEOUS
/***********************/

export async function preloadOfflineData(
  store: firebase.firestore.Firestore,
  organisationId: string,
  limit = {
    incomingOrder: 60,
    sellOrder: 20,
    lot: 50,
    supplyChainLot: 20,
    todos: 50,
  },
  setSyncingStatus: (s: OfflineSyncingStatus) => void
): Promise<any> {
  let progress: number = 0;
  const totalWithoutAdditionalLots = Object.values(limit).reduce(
    (acc: number, val) => acc + val,
    0
  );

  let updateProgress = (val: number) => {
    const factor: number = 50 / totalWithoutAdditionalLots;
    progress += val * factor;
  };

  const logDocsSize = (
    docs: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>,
    name: string
  ) => {
    console.log(
      `${name}: ${JSON.stringify(docs.docs.map((d) => d.data())).length / 1000}kb`
    );
  };

  setSyncingStatus({ entity: 'buy orders', progress });

  const incomingOrderDocs = await orderColRef(store, organisationId)
    .orderBy(ORDER_DATE_SORTING_FIELD, 'desc')
    .where(ORDER_DATE_SORTING_FIELD, '<=', new Date(getTodayMinusXDays(-1)))
    .where('type', '==', 'BUY')
    .limit(limit.incomingOrder)
    .get();

  logDocsSize(incomingOrderDocs, 'buy orders');

  updateProgress(limit.incomingOrder);
  setSyncingStatus({ entity: 'sell orders', progress });

  const sellOrderDocs = await orderColRef(store, organisationId)
    .orderBy(ORDER_DATE_SORTING_FIELD, 'desc')
    .where(ORDER_DATE_SORTING_FIELD, '<=', new Date(getTodayMinusXDays(-1)))
    .where('type', '==', 'SELL')
    .limit(limit.sellOrder)
    .get();

  logDocsSize(sellOrderDocs, 'sell orders');

  // Note: this second fetch is necessary for the offline data preload to work on integration,
  // as the fulfilmentDate of orders hasn't been migrated there yet.
  // TODO: populate integration with the latest data
  let integrationOrderDocs: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>;
  if (process.env.REACT_APP_ENV === 'integration') {
    integrationOrderDocs = await orderColRef(store, organisationId)
      .orderBy(ORDER_DATE_SORTING_FIELD, 'desc')
      .where(ORDER_DATE_SORTING_FIELD, '<=', getTodayMinusXDays(-1))
      .where('type', '==', 'BUY')
      .limit(limit.incomingOrder)
      .get();
  }

  const orderDocs = [
    ...incomingOrderDocs.docs,
    ...sellOrderDocs.docs,
    ...(integrationOrderDocs === undefined ? [] : integrationOrderDocs.docs),
  ];

  const orderLotids = _.uniq(
    orderDocs.flatMap((o) => {
      const order: Order = o.data() as Order;
      return order.positions.map((pos) => pos.lotId);
    })
  );

  //------------------------------------------------------

  updateProgress(limit.sellOrder);
  setSyncingStatus({ entity: 'to-dos', progress });

  const todoDocs = await store
    .collection('organisation')
    .doc(organisationId)
    .collection('todo')
    .where('uid', '==', getAuth(firebase.app()).currentUser.uid)
    .where('isCompleted', '!=', true)
    .limit(limit.todos)
    .get();

  logDocsSize(todoDocs, 'todos');

  //------------------------------------------------------
  updateProgress(limit.todos);
  setSyncingStatus({ entity: 'supply chain lots', progress });

  const supplyChainLotDocs = await lotColRef(
    store,
    organisationId,
    SUPPLY_CHAIN_COL_NAME
  )
    .orderBy(LOT_DATE_SORTING_FIELD, 'desc')
    .where(LOT_DATE_SORTING_FIELD, '<=', new Date())
    .limit(limit.supplyChainLot)
    .get();

  logDocsSize(supplyChainLotDocs, 'supply chain lots');
  //------------------------------------------------------

  updateProgress(limit.supplyChainLot);
  setSyncingStatus({ entity: 'lots', progress });

  const todoLotIds = _.uniq(
    todoDocs.docs.flatMap((o) => {
      const todo: Todo = o.data() as Todo;
      return todo.tasks.map((task) => task.lotId);
    })
  );

  // Fetch latest lots
  const lotDocs = await lotColRef(store, organisationId)
    .orderBy(LOT_DATE_SORTING_FIELD, 'desc')
    .where(LOT_DATE_SORTING_FIELD, '<=', new Date(getTodayMinusXDays(-1)))
    .limit(limit.lot)
    .get();

  logDocsSize(lotDocs, 'lots');

  updateProgress(limit.lot);
  setSyncingStatus({ entity: 'lots', progress });

  // Fetch lots associated to the cached orders and TODOs that have not been yet fetched
  const additionalLotIds = _.uniq([...orderLotids, ...todoLotIds]).filter(
    (id) => !lotDocs.docs.map((d) => d.id).includes(id)
  );

  //------------------------------------------------------

  const additionalLotsCount = additionalLotIds.length;

  updateProgress = (val: number) => {
    const factor: number = 50 / additionalLotsCount;
    progress += val * factor;
  };

  // 2023-09-01: we'll experiment fetching the lots sequentially instead of doing a Promise.all, as that made my phone crash a couple of times when there were too many lots
  while (additionalLotIds.length) {
    const res = await lotColRef(store, organisationId)
      .where('id', 'in', additionalLotIds.splice(-10))
      .get();
    updateProgress(10);
    setSyncingStatus({ entity: `${additionalLotIds.length} lots`, progress });
  }

  //------------------------------------------------------
  return setSyncingStatus({ entity: 'data', progress: 100 });
}

export async function preloadKnownOfflineData(
  store: firebase.firestore.Firestore,
  organisationId: string,
  entities: PreloadDataEntities,
  setSyncingStatus: (s: OfflineSyncingStatus) => void
): Promise<any> {
  let progress: number = 0;
  const orderFullProgress = 20;
  const lotFullProgress = 100 - orderFullProgress;

  let { orderIds, lotIds } = cloneDeep(entities);
  const initialOrderLength = orderIds.length;

  // Fetch orders
  let updateProgress = (val: number) => {
    const factor: number = orderFullProgress / initialOrderLength;
    progress += val * factor;
  };

  if (orderIds.length > 0) {
    while (orderIds.length) {
      setSyncingStatus({ entity: `${orderIds.length} orders`, progress });
      const idsBatch = orderIds.splice(-10);
      const orderDocs = await orderColRef(store, organisationId)
        .where('id', 'in', idsBatch)
        .get();

      updateProgress(idsBatch.length);

      // add orders' lots to the list of lots to fetch
      lotIds.push(
        ...orderDocs.docs.flatMap((d) =>
          (d.data() as Order).positions.map((p) => p.lotId)
        )
      );
    }
  } else {
    progress += orderFullProgress;
  }

  // Dedup and fetch lots
  lotIds = Array.from(new Set(lotIds));

  if (lotIds.length > 0) {
    const initialLotLength = lotIds.length;

    updateProgress = (val: number) => {
      const factor: number = lotFullProgress / initialLotLength;
      progress += val * factor;
    };

    while (lotIds.length) {
      setSyncingStatus({ entity: `${lotIds.length} lots`, progress });

      const idsBatch = lotIds.splice(-10);
      await lotColRef(store, organisationId).where('id', 'in', idsBatch).get();

      updateProgress(idsBatch.length);
    }
  } else {
    progress += lotFullProgress;
  }

  return setSyncingStatus({ entity: 'data', progress: 100 });
}

export async function getOrganisation(
  store: firebase.firestore.Firestore,
  organisationId: string
): Promise<Organisation> {
  return orgDocRef(store, organisationId)
    .get()
    .then((db) => db.data() as Organisation);
}

export function getOrganisationSnapshot(
  firestoreRef: firebase.firestore.Firestore,
  organisationId: string,
  onResult: (organisation: Organisation | null) => any
): () => void {
  if (!organisationId) {
    return;
  }

  return orgDocRef(firestoreRef, organisationId).onSnapshot(
    ...handleOnDocSnapshot(onResult)
  );
}

/*****************************/
// 3. SPECIFICATIONS / AG PRODUCTS
/*****************************/

export async function getOrganisationSettings(
  firestore: firebase.firestore.Firestore,
  organisationId: string
): Promise<OrganisationSettings> {
  return (await orgDocRef(firestore, organisationId).get()).data()
    ?.settings as OrganisationSettings;
}

export async function getOrganisationSpecs(
  firestore: firebase.firestore.Firestore,
  organisationId: string
) {
  if (!organisationId) {
    return {};
  }
  const repo = new FirebaseSpecificationRepository(firestore, organisationId);
  const inspectionSpecs = await repo.getInspectionSpecs();
  const scorings = await repo.getScorings();
  const organisationSettings = await getOrganisationSettings(firestore, organisationId);
  return { inspectionSpecs, organisationSettings, scorings };
}

// export async function getOrganisationSpecs(firestore: firebase.firestore.Firestore, organisationId: string) {
//   /* ******* */
//   // Returns fully merged self-contained schemas
//   /* ******* */

//   if (!organisationId) {
//     return {};
//   }

//   // 1. fetch templates
//   const templates: MergedSchema[] = [];
//   const templateDocs = await templateColRef(firestore, organisationId).get();

//   templateDocs.forEach(doc => templates.push(doc.data() as MergedSchema));

//   // console.log("templates", templates)

//   // 2. fetch schemas (customizable parts)
//   const schemas: MergedSchema[] = [];
//   const schemaDocs = await schemaColRef(firestore, organisationId).get();

//   schemaDocs.forEach(doc => schemas.push(doc.data() as MergedSchema));

//   // console.log("unmerged schemas", schemas)

//   // 3. Merge templates with the customizable parts
//   const mergedSchemas: MergedSchema[] = [];

//   // 3.1 First loop through templates. If the template's children doesn't have any abstract == true, we consider it as a "standalone" schema and return it as it is
//   // TODO: revise, this "abstract" check  has to change
//   for (const template of templates) {
//     const hasAbstract = template.layout.children.filter(child => child.abstract === true).length > 0;
//     if (!hasAbstract) {
//       mergedSchemas.push(template);
//     }
//   }

//   // 3.2 Add the resolved schemas
//   for (const schema of schemas) {
//     if (schema.fromSchemaId) {
//       // resolve the schema from the template
//       const currTemplate = templates.find(t => t.id === schema.fromSchemaId);

//       // we add information on whether it's a test schema
//       try {
//         currTemplate.testFromSchemaId = schema.testFromSchemaId;
//         // TODO: Update the inputs here to use the new data model and include sections
//         mergedSchemas.push(resolveSchema(currTemplate, schema));
//       } catch (error) {
//         colorLog('magenta', `Error: schema ${schema.id} could not be resolved with template ${schema.fromSchemaId}`);
//       }
//     }
//   }

//   // 4. Fetch organization specs
//   const organisationSettings: OrganisationSettings = ((await orgDocRef(firestore, organisationId).get()).data())?.settings as OrganisationSettings;

//   // console.log("mergedSchemas", mergedSchemas);

//   return {mergedSchemas, organisationSettings};
// }

export async function getAGQuestions(firestore: firebase.firestore.Firestore) {
  const inputListDocs = await firestore
    .collection('agrinorm')
    .doc('questions')
    .collection('questions')
    .get();

  let inputList: AGQuestion[] = [];
  inputListDocs.forEach((doc) => inputList.push(doc.data() as AGQuestion));
  return inputList;
}

export async function importProduct(
  store: firebase.firestore.Firestore,
  payload: Product
) {
  await productDocRef(store, payload.id).set(payload);
}

export async function getAGProductsTransactional(
  store: firebase.firestore.Firestore,
  transaction: firebase.firestore.Transaction,
  productIds: string[]
) {
  const products: Product[] = [];

  for (const productId of productIds) {
    if (productId == null) {
      continue;
    }
    const product = await getAGProductTransactional(store, transaction, productId);
    if (!!product && !products.map((p) => p.id).includes(productId)) {
      products.push(product);
    }
  }
  return products;
}

export async function getAGProductTransactional(
  store: firebase.firestore.Firestore,
  transaction: firebase.firestore.Transaction,
  productId: string
): Promise<Product> {
  const collRef = productDocRef(store, productId);

  let result;

  // TODO: ugly code, improve
  if (transaction) {
    result = await transaction.get(collRef);

    if (result.exists) {
      return result.data() as Product;
    }
    return undefined;
  } else {
    result = await collRef.get();

    if (result.exists) {
      return result.data() as Product;
    }
    return undefined;
  }
}

export async function resolveAgProductId(
  store: firebase.firestore.Firestore,
  oldArticle: IArticle,
  profile: UserProfile,
  isAgProduct: boolean
) {
  // if agProductId present, do nothing!
  if (!!oldArticle.agProductId) {
    return oldArticle;
  }

  let article = { ...oldArticle };

  if (isAgProduct) {
    // first check that the product exists in AG
    const productDoc = await productDocRef(store, article.productId).get();
    if (!productDoc.exists) {
      throw new Error(
        `Product id ${article.productId} does not exist in AG Product list`
      );
    }
    article.agProductId = article.productId;
  } else {
    // resolve with the mapping
    const pmDoc = await productMappingColRef(store, profile.organisationId).get();

    // We don't allow articles without agProductId, so if no mapping found, we throw an error
    if (pmDoc.empty) {
      throw new Error(
        `Product mapping of organisation ${profile.organisationId} not found`
      );
    }
    const pm: ProductMapping = pmDoc.docs
      .map((d) => d.data() as ProductMapping)
      .find((m) => m.companyId === article.productId);
    if (!pm) {
      throw new Error(
        `No mapping found for product id ${article.productId} (org: ${profile.organisationId})`
      );
    }
    article.agProductId = pm.id;
  }
  return article;
}

export async function resolveAgVariety(
  store: firebase.firestore.Firestore,
  oldArticle: IArticle,
  profile: UserProfile,
  isAgVariety: boolean
) {
  // if no variety found or agVariety already present, do nothing!
  if (!oldArticle.variety || !!oldArticle.agVariety) {
    return oldArticle;
  }

  // get organisations settings to see whether the variety mapping must be enforced
  const organisationSettings: OrganisationSettings = (
    await orgDocRef(store, profile.organisationId).get()
  ).data().settings;
  const enforceVarietyMapping =
    organisationSettings?.import?.enforceVarietyMapping ?? false;

  let article = { ...oldArticle };

  if (isAgVariety) {
    // first check that the product exists in AG
    const productDoc = await productDocRef(store, article.agProductId).get();
    if (!productDoc.exists) {
      throw new Error(
        `Product id ${article.productId} does not exist in AG Product list. Variety cannot be checked`
      );
    }
    const product: Product = productDoc.data() as Product;
    if (!product.varieties?.includes(article.variety)) {
      throw new Error(
        `Variety ${article.variety} does not exist in AG product ${article.agProductId}`
      );
    }
    article.agVariety = article.variety;
  } else {
    // resolve with the mapping
    const vmDoc = await varietyMappingColRef(store, profile.organisationId).get();

    // In the case of varieties, we do allow missing mapping unless the enforceVarietyMapping flag is true
    if (!vmDoc.empty) {
      const vm: VarietyMapping = vmDoc.docs
        .map((d) => d.data() as VarietyMapping)
        .find((m) => m.companyId === article.variety);
      if (vm) {
        article.agVariety = vm.id;
      } else {
        const msg = (title: string) =>
          `${title}: variety ${article.variety} of product id ${article.agProductId} (company id: ${article.productId}) could not be mapped`;
        if (enforceVarietyMapping) {
          throw new Error(msg('ERROR'));
        } else {
          console.log(msg('Warning'));
        }
      }
    } else {
      const msg = (title: string) =>
        `${title}: variety mapping of organisation ${profile.organisationId} not found`;
      if (enforceVarietyMapping) {
        throw new Error(msg('ERROR'));
      } else {
        console.log(msg('Warning'));
      }
    }
  }
  return article;
}

export async function getOrgVarietyFromAgVariety(
  store: firebase.firestore.Firestore,
  orgId: string,
  agVariety: string
): Promise<string | undefined> {
  const vms = (
    await varietyMappingColRef(store, orgId).where('id', '==', agVariety).get()
  ).docs.map((v) => v.data() as VarietyMapping);

  return vms[0]?.companyId ?? agVariety;
}

export async function editVarietyInLotAndOrder(
  store: firebase.firestore.Firestore,
  orgId: string,
  variety: string,
  agVariety: string,
  lotId: string,
  orderId?: string
) {
  // Modify article in lot
  await lotColRef(store, orgId)
    .doc(lotId)
    .update({ 'article.variety': variety, 'article.agVariety': agVariety });

  // Modify article in order, if applies
  if (orderId) {
    const orderRef = orderColRef(store, orgId).doc(orderId);
    const order = (await orderRef.get()).data() as Order;
    const positions = order.positions.map((p) =>
      p.lotId === lotId ? { ...p, article: { ...p.article, variety, agVariety } } : p
    );
    await orderRef.update({ positions });
  }
}

/***********************/
// 4. ORDERS
/***********************/
// 4.1 General
export async function importOrder(
  store: firebase.firestore.Firestore,
  profile: UserProfile,
  payload: OrderCreatePayload,
  checkProduct: boolean = false,
  isAgVariety: boolean = false
): Promise<any> {
  return inTransaction(store, async (transaction: firebase.firestore.Transaction) => {
    return await saveOrderWrapper(
      store,
      transaction,
      profile,
      payload,
      undefined,
      checkProduct,
      isAgVariety
    );
  });
}

export async function saveOrderWrapper(
  store: firebase.firestore.Firestore,
  transaction: firebase.firestore.Transaction,
  profile: UserProfile,
  payload: OrderCreatePayload,
  createNew?: boolean,
  checkProduct: boolean = false,
  isAgVariety: boolean = false,
  replaceFirstTransfer: boolean = false // when creating a new incoming order from PageNewOrder, instead of creating a new transfer for the lots in the order, we just update their first transfer with the right transferType
) {
  const payloadCopy = cloneDeep(payload);
  let oldOrder: Order;
  if (createNew) {
    oldOrder = createNewEmptyOrder(payloadCopy, profile);
  } else {
    oldOrder = await getOrderTransactional(store, transaction, profile, payloadCopy.id);
  }

  // remove duplicate lotIds positions
  let positions = payloadCopy.positions ?? [];
  let positionsIds = positions.map((o) => o.lotId);
  positions = positions.filter(
    ({ lotId }, index) => !positionsIds.includes(lotId, index + 1)
  );

  // if we are calling this function from the import (transaction == true), we have to resolve the article in the db
  let productsToUpdate: { [productId: string]: ProductView } = {};

  if (transaction) {
    const res = await resolveArticlesInPositions(
      positions,
      transaction,
      payloadCopy,
      store,
      profile,
      isAgVariety,
      checkProduct
    );
    positions = res.newPositions;
    productsToUpdate = res.productsToUpdate;
  }

  // Check for sublots in the old older for which the mother lot still exists in the new order, and include them if reimporting
  const validSublotPositions: LotPosition[] = oldOrder.positions.filter((p) =>
    isValidSubLot(p, payloadCopy)
  );

  // compute volume
  positions = uniq([...positions, ...validSublotPositions]).map((p) => {
    const volumeInKg =
      p.volumeInKg ?? new Article(p.article).computeVolumeInKg(p.numBoxes);
    return { ...p, volumeInKg };
  });

  // console.log("POSITIONS", JSON.stringify(positions));

  const newOrder: Order = {
    ...oldOrder,
    id: payloadCopy.id,
    orgId: profile.organisationId,
    shippingReference: payloadCopy.shippingReference,
    externalReference: payloadCopy.externalReference,

    lotInspectionMap: oldOrder.lotInspectionMap ?? {},
    transportInspectionMap: oldOrder.transportInspectionMap ?? {},

    qcStatus: payloadCopy.qcStatus ?? oldOrder.qcStatus ?? 'OPEN',
    type: payloadCopy.type,

    qcRelevant: ['BUY', 'INTERNAL_TRANSFER', 'SELL_RETURN', 'SELL'].includes(
      payloadCopy.type
    ),

    // Update contacts
    contactId: payloadCopy.contactId,
    growerContactId: payloadCopy.growerContactId,
    bookingDate: standardizeDate(payloadCopy.bookingDate),
    fulfilmentDate: standardizeDate(payloadCopy.fulfilmentDate),

    supplierId: payloadCopy.supplierId,
    supplierName: payloadCopy.supplierName,

    origin: payloadCopy.origin,
    positions,
    locationId: payloadCopy.locationId,
    dispatchLocationId: payloadCopy.dispatchLocationId,

    transport: payloadCopy.transport,

    price: payloadCopy.price,
  };

  if (payloadCopy.fulfilmentDate != null && newOrder.lastQCStatusDate == null) {
    newOrder.lastQCStatusDate = new Date(payloadCopy.fulfilmentDate);
  }

  // Update article in assessments
  if (transaction) {
    newOrder.positions.forEach((p) => {
      if (!!newOrder.lotInspectionMap?.[p.lotId]) {
        if (!newOrder.lotInspectionMap[p.lotId].lotProperties) {
          newOrder.lotInspectionMap[p.lotId].lotProperties = {
            article: p.article,
          };
        } else {
          newOrder.lotInspectionMap[p.lotId].lotProperties.article = p.article;
        }
      }
    });
  }

  return setOrderTransactional(
    store,
    transaction,
    profile,
    newOrder,
    oldOrder,
    true,
    createNew,
    replaceFirstTransfer
  ).then(() => {
    // Update the organisation specific product view if new varieties, brands, etc, were found
    setProductViewsTransactional(productsToUpdate, transaction, store, profile);
  });
}

export async function getOrderTransactional(
  store: firebase.firestore.Firestore,
  transaction: firebase.firestore.Transaction,
  profile: UserProfile,
  orderId: string,
  createNew: boolean = true
): Promise<Order> {
  const collRef = orderColRef(store, profile.organisationId).doc(orderId);

  if (transaction) {
    const result = await transaction.get(collRef);
    return result.exists
      ? (result.data() as Order)
      : createNew
      ? createNewEmptyOrder({ id: orderId } as OrderCreatePayload, profile)
      : undefined;
  } else {
    return await collRef.get().then((d) => d.data() as Order);
  }
}

export async function setOrderTransactional(
  store: firebase.firestore.Firestore,
  transaction: firebase.firestore.Transaction,
  profile: UserProfile,
  newOrder: Order,
  oldOrder?: Order,
  fromUpdateAssessment = false,
  createNew = false,
  replaceFirstTransfer: boolean = false
) {
  const collRef = orderColRef(store, profile.organisationId).doc(newOrder.id);

  if (newOrder !== oldOrder) {
    // Update metadata
    updateAggregateModificationData(profile.id, newOrder);

    // Now check if lots need to be updated
    await updateOrderPositions(
      store,
      transaction,
      profile,
      newOrder,
      oldOrder,
      createNew,
      replaceFirstTransfer
    );

    // Update report references
    newOrder = await updateOrderReportReferences(store, newOrder, profile);

    const org: Organisation = (
      await orgDocRef(store, profile.organisationId).get()
    ).data() as Organisation;

    // Update search
    newOrder.search = generateOrderSearch(newOrder, org?.settings);

    if (transaction) {
      // the fromUpdateAssessment flag is necessary so, when we import an assessment, the userInputMap doesn't get merged but gets replaced by the new one
      transaction.set(collRef, newOrder, {
        merge: !fromUpdateAssessment,
      });

      // update orderIdLinked flag in related reports at import time
      for (const ref of newOrder.reportReferences ?? []) {
        const reportDocRef = reportColRef(store, profile.organisationId).doc(
          ref.reportId
        );
        try {
          transaction.update(reportDocRef, {
            'reportReference.orderIdLinked': true,
          });
        } catch (error) {
          console.log(`Could not update report:`, error);
        }
      }
    } else {
      collRef.set(newOrder, { merge: !fromUpdateAssessment });
      return;
    }
  }
}

async function updateOrderPositions(
  store: firebase.firestore.Firestore,
  transaction: firebase.firestore.Transaction,
  profile: UserProfile,
  newOrder: Order,
  oldOrder?: Order,
  createNew = false,
  replaceFirstTransfer: boolean = false
) {
  // let newOrder = copyObject(newOrder1)

  // TODO: Test this method
  let lotIdsToCreate: string[] = [];
  let lotIdsToUpdate: string[] = [];
  let lotIdsToRemove: string[] = [];

  if (!oldOrder) {
    lotIdsToCreate = newOrder.positions.map((p) => p.lotId);
  } else {
    // We need to find deltas
    oldOrder.positions.forEach((oldPosition) => {
      const oldPositionExistsInNewOrder = !!newOrder.positions.find(
        (pp) => pp.lotId === oldPosition.lotId
      );
      const isSplitLot = (oldPosition.motherLotIds ?? []).length > 0;
      const motherLotExistsInNewOrder = isValidSubLot(oldPosition, newOrder);

      if (
        (!isSplitLot && !oldPositionExistsInNewOrder) ||
        (isSplitLot && !motherLotExistsInNewOrder)
      ) {
        lotIdsToRemove.push(oldPosition.lotId);
      }
    });

    newOrder.positions.forEach((position) => {
      const oldPosition = oldOrder.positions.find((pp) => pp.lotId === position.lotId);
      if (oldPosition) {
        if (!_.isEqual(oldPosition, position)) {
          // if (true) {
          lotIdsToUpdate.push(position.lotId);
        } else if (
          oldOrder?.dispatchLocationId !== newOrder.dispatchLocationId ||
          oldOrder?.locationId !== newOrder.locationId
        ) {
          lotIdsToUpdate.push(position.lotId);
        }
      } else {
        lotIdsToCreate.push(position.lotId);
      }
    });
  }

  //const lotIds = lotIdsToCreate.concat(lotIdsToRemove).concat(lotIdsToUpdate);
  // const lotIds = newOrder.positions.map(p => p.lotId);

  // we get lots from new position + those positions in the old one that are not present in the new one
  const oldLots: Lot[] = await getLotsTransactional(
    store,
    transaction,
    profile,
    [
      ...newOrder.positions,
      ...(oldOrder
        ? oldOrder.positions.filter(
            (p) => !newOrder.positions.map((pp) => pp.lotId).includes(p.lotId)
          )
        : []),
    ],
    true,
    oldOrder ?? newOrder
  );

  for (const oldLot of oldLots) {
    const newLot: Lot = { ...oldLot };

    if (newOrder.type === 'BUY') {
      newLot.suppliedByContactId = newOrder.contactId;
    }

    const pos = newOrder.positions.find((p) => p.lotId === newLot.id);

    // add article from position if not present
    if (pos?.article) {
      newLot.article = pos.article;
    }

    // transient properties
    let hasLink: boolean = false;

    const { palletIds, externalInstructionsUrl } = pos ?? {};

    if (!newLot.transient) {
      newLot.transient = {
        hasLink,
        palletIds,
        externalInstructionsUrl: externalInstructionsUrl,
        isInMyPossession: true,
      } as LotTransientProps;
    } else {
      newLot.transient.hasLink = hasLink;
      newLot.transient.palletIds = palletIds;
      newLot.transient.externalInstructionsUrl = externalInstructionsUrl;
    }

    newLot.transient.barcodes = pos?.barcodes;

    // origin properties
    const growerContactId = pos?.growerId;
    if (!newLot.origin) {
      newLot.origin = { growerContactId };
    } else {
      newLot.origin.growerContactId = growerContactId;
    }
    newLot.origin.locationId = pos?.fieldId;
    newLot.origin.growerGGN = pos?.ggns?.length > 0 ? pos?.ggns[0] : pos?.ggn;
    newLot.origin.growerGGNs = !!pos?.ggns
      ? pos!.ggns
      : !!pos?.ggn
      ? [pos?.ggn]
      : undefined;

    const q = pos?.numBoxes;
    const vol = pos?.volumeInKg ?? new Article(pos?.article).computeVolumeInKg(q);

    const lotTransfer: LotTransfer = pos
      ? {
          transferId: newOrder.id, // We allow only one transfer per order
          numBoxes: newOrder.type === 'SELL' ? -q : q,
          volumeInKg: newOrder.type === 'SELL' ? -vol : vol,
          transferType: newOrder.type,
          transferDate: standardizeDate(newOrder.fulfilmentDate),
          orderId: newOrder.id,
          locationId: newOrder.locationId,
          dispatchLocationId: newOrder.dispatchLocationId,
        }
      : undefined;

    // TODO: Improve this code.
    if (replaceFirstTransfer) {
      // when deleting a user created incoming order
      if (lotIdsToRemove.find((lotId) => lotId === newLot.id)) {
        newLot.transfers[0] = {
          ...(newLot.transfers[0].prevTransferBackup ?? {
            ...newLot.transfers[0],
            transferId: newLot.id,
            transferType: 'CREATE_LOT',
          }),
        };
      }
      // when creating one
      else {
        newLot.transfers[0] = {
          ...lotTransfer,
          prevTransferBackup: newLot.transfers[0],
        };
      }
    } else if (lotIdsToUpdate.find((lotId) => lotId === newLot.id)) {
      newLot.transfers = newLot.transfers.map((t) =>
        t.orderId === newOrder.id ? lotTransfer : t
      );
    } else if (lotIdsToRemove.find((lotId) => lotId === newLot.id)) {
      newLot.transfers = newLot.transfers.filter(
        (t) => t.orderId !== newOrder.id && t.splitLotOrderIdLink !== newOrder.id
      );
      delete newOrder.lotInspectionMap?.[newLot.id];
    } else if (
      lotIdsToCreate.find((lotId) => lotId === newLot.id) ||
      !newLot.transfers.map((t) => t.transferId).includes(lotTransfer.transferId)
    ) {
      newLot.transfers.push(lotTransfer);
    }

    // console.log(`TRANSFERS ${newLot.id}`, JSON.stringify(newLot.transfers));

    await setLotTransactional(store, transaction, profile, newLot, undefined, newOrder);
  }
}

export async function getOrdersForContactId(
  firestoreRef: firebase.firestore.Firestore,
  organisationId: string,
  contactId: string,
  orderOrganisationId: string = organisationId,
  locationRestricted: boolean | string = false
): Promise<Order[]> {
  let query = orderColRef(firestoreRef, organisationId, orderOrganisationId).where(
    'search.contactId',
    '==',
    contactId
  );

  if (locationRestricted) {
    query = query.where('locationId', '==', locationRestricted);
  }

  return query
    .orderBy('fulfilmentDate', 'desc')
    .limit(50)
    .get()
    .then((dbOrders) => {
      return dbOrders.docs.map((ord) => ord.data() as Order);
    });
}

export async function getAllOrdersForContactId(
  firestoreRef: firebase.firestore.Firestore,
  organisationId: string,
  contactId: string,
  orderOrganisationId: string = organisationId
): Promise<Order[]> {
  return orderColRef(firestoreRef, organisationId, orderOrganisationId)
    .where('contactId', '==', contactId)
    .get()
    .then((dbOrders) => {
      return dbOrders.docs.map((ord) => ord.data() as Order);
    });
}

export function getOrderSnapshot(
  firestoreRef: firebase.firestore.Firestore,
  organisationId: string,
  orderId: string,
  onResult: (order: Order | null) => any,
  orderOrganisationId: string = organisationId
): () => void {
  return orderColRef(firestoreRef, organisationId, orderOrganisationId)
    .doc(orderId)
    .onSnapshot(...handleOnDocSnapshot(onResult));
}

export async function getOrder(
  firestoreRef: firebase.firestore.Firestore,
  organisationId: string,
  orderId: string,
  orderOrganisationId: string = organisationId
): Promise<Order> {
  return orderColRef(firestoreRef, organisationId, orderOrganisationId)
    .doc(orderId)
    .get()
    .then((db) => db.data() as Order);
}

export async function undoOrder(
  store: firebase.firestore.Firestore,
  order: Order,
  profile: UserProfile
) {
  let { organisationId } = profile;
  await orderColRef(store, organisationId).doc(order.id).delete();

  if (order.latestReportReference?.reportId != null) {
    await reportColRef(store, organisationId)
      .doc(order.latestReportReference?.reportId)
      .delete();
  }

  order.positions.forEach(async (o) => {
    await lotColRef(store, organisationId)
      .doc(o.lotId)
      .update({ transfers: [], outgoingAssessment: null });
  });
}

export async function createNewOrder(
  store: firebase.firestore.Firestore,
  profile: UserProfile,
  payload: OrderCreatePayload
): Promise<any> {
  return await saveOrderWrapper(store, undefined, profile, payload, true);
}

export async function updateOrder(
  store: firebase.firestore.Firestore,
  profile: UserProfile,
  payload: OrderCreatePayload
): Promise<any> {
  return await saveOrderWrapper(store, undefined, profile, payload);
}

// 4.2 Production orders
export async function importProductionOrder(
  store: firebase.firestore.Firestore,
  profile: UserProfile,
  payload: ProductionPayload,
  checkProduct: boolean = false,
  isAgVariety: boolean = false
): Promise<any> {
  return inTransaction(store, async (transaction: firebase.firestore.Transaction) => {
    // resolve articles
    payload.incomingPositions = (
      await resolveArticlesInPositions(
        payload.incomingPositions,
        transaction,
        payload,
        store,
        profile,
        isAgVariety,
        checkProduct
      )
    ).newPositions;

    payload.outgoingPositions = (
      await resolveArticlesInPositions(
        payload.outgoingPositions,
        transaction,
        payload,
        store,
        profile,
        isAgVariety,
        checkProduct
      )
    ).newPositions;

    // convert positions to what's expected internally
    const positions: LotPosition[] = [
      ...payload.incomingPositions.map((p) =>
        mapProductionOrSplitLotPayloadPositions(p, -1)
      ),
      ...payload.outgoingPositions.map((p) =>
        mapProductionOrSplitLotPayloadPositions(p)
      ),
    ];

    let order: Order = {
      ...omit(payload, ['suppliedByContactId']),
      fulfilmentDate: standardizeDate(payload.fulfilmentDate),
      positions,
      type: 'PRODUCTION',
      qcRelevant: false,
      orgId: profile.organisationId,
      reason: payload.reason,
      wasteVolumeInKg:
        (payload.wasteVolumeInKg ?? 0) > 0 ? payload.wasteVolumeInKg : undefined,
    };

    // delete these fields present in the payload
    // @ts-ignore
    delete order.incomingPositions;
    // @ts-ignore
    delete order.outgoingPositions;

    console.log('PRODUCTION ORDER', JSON.stringify(order));
    return setProductionOrderTransactional(
      store,
      transaction,
      profile,
      order,
      checkProduct,
      isAgVariety,
      payload.suppliedByContactId
    );
  });
}

export async function setProductionOrderTransactional(
  store: firebase.firestore.Firestore,
  transaction: firebase.firestore.Transaction,
  profile: UserProfile,
  order: Order,
  checkProduct: boolean = false,
  isAgVariety: boolean = false,
  suppliedByContactId: string | undefined = undefined
) {
  const ref = orderColRef(store, profile.organisationId).doc(order.id);

  // add full article info to positions
  let productsToUpdate: { [productId: string]: ProductView } = {};

  if (transaction) {
    const res = await resolveArticlesInPositions(
      order.positions,
      transaction,
      order,
      store,
      profile,
      isAgVariety,
      checkProduct
    );
    order.positions = res.newPositions;
    productsToUpdate = res.productsToUpdate;
  }

  // In the case of production, we get the lots with createNew === true, so if the lot doesn't exist, we create it according to the info in the position
  const lots: Lot[] = await getLotsTransactional(
    store,
    transaction,
    profile,
    order.positions,
    true,
    order
  );

  // Handle lot transfers
  for (const position of order.positions) {
    const lotId = position.lotId;

    const q = position.numBoxes;
    const vol =
      position.volumeInKg ?? new Article(position?.article).computeVolumeInKg(q);

    const transfer: LotTransfer = {
      fromLots: order.positions.filter(filterFromPositions).map(mapLotTransferSummary),
      toLots: order.positions.filter(filterToPositions).map(mapLotTransferSummary),
      transferType: 'PRODUCTION',
      numBoxes: q,
      volumeInKg: !isNaN(+vol) ? +vol : undefined,
      reason: order.reason,
      transferDate: standardizeDate(order.fulfilmentDate),
      transferId: order.id,
      locationId: order.locationId,
      dispatchLocationId: order.dispatchLocationId,
      wasteVolumeInKg: order.wasteVolumeInKg,
    };

    let lot: Lot = lots.find((l) => l?.id === lotId);

    // INPUT POSITIONS
    if (position.numBoxes < 0) {
      if (!lot) {
        throw new Error(`Original lot ${lotId} doesn't exist`);
      }

      const existingTransferIdx = lot.transfers.findIndex(
        (t) => t.transferId === order.id
      );

      // when transfer with the same id already exists
      if (existingTransferIdx >= 0) {
        lot.transfers[existingTransferIdx] = transfer;
      } else {
        lot.transfers.push(transfer);
      }
    } else {
      // OUTPUT POSITIONS
      if (lot) {
        const existingTransferIdx = lot.transfers.findIndex(
          (t) => t.transferId === order.id
        );

        // when transfer with the same id already exists
        if (existingTransferIdx >= 0) {
          lot.transfers[existingTransferIdx] = transfer;
        } else {
          lot.transfers.push(transfer);
        }
      } else {
        const transient: LotTransientProps = {
          palletIds: position.palletIds,
          locationId: order.locationId,
          freshnessDate: new Date(order.fulfilmentDate),
          hasLink: false,
          isInMyPossession: true,
          numBoxes: q,
          volumeInKg: vol,
        };

        lot = {
          emergenceDate: new Date(order.fulfilmentDate),
          id: lotId,
          article: position.article,
          transfers: [transfer],
          orgId: profile.organisationId,
          transient,
        };
      }
      if (order.isAGMaster) {
        lot.isAGMaster = true;
      }
    }

    // Update pallets
    if (position.palletIds?.length) {
      lot.transient.palletIds = position.palletIds;
    }

    // Update supplier
    if (!!suppliedByContactId) {
      lot.suppliedByContactId = suppliedByContactId;
    }

    // Manage waste
    if (
      (order.wasteVolumeInKg ?? 0) > 0 &&
      transfer.fromLots.map((l) => l.lotId).includes(position.lotId)
    ) {
      const fromVolume = order.positions
        .filter(filterFromPositions)
        .reduce((acc: number, p: LotPosition) => p.volumeInKg + acc, 0);
      const wasteVolumeInKg =
        (position.volumeInKg / fromVolume) * order.wasteVolumeInKg;

      const waste: Waste = {
        reason: order.reason,
        type: order.type,
        lotId: position.lotId,
        lotActionId: order.id,
        date: new Date(),
        wasteVolumeInKg,
      };

      await setWasteTransactional(store, transaction, profile, waste);
    }

    await setLotTransactional(store, transaction, profile, lot, true, order);
  }

  // Update the organisation specific product view if new varieties, brands, etc, were found
  setProductViewsTransactional(productsToUpdate, transaction, store, profile);

  // Update metadata and set production order
  updateAggregateModificationData(profile.id, order);

  console.log('Production order written to firestore:', JSON.stringify(order));
  return transaction.set(ref, order);
}

// Note: this function should only be called in online mode
export async function undoProductionOrder(
  firestore: firebase.firestore.Firestore,
  profile: UserProfile,
  orderId: string
) {
  const wasteDocs = await wasteColRef(firestore, profile.organisationId)
    .where('lotActionId', '==', orderId)
    .get();

  try {
    return firestore.runTransaction(async (transaction) => {
      const orderColReference = orderColRef(firestore, profile.organisationId);

      // order to be undone
      let order: Order = await getOrderTransactional(
        firestore,
        transaction,
        profile,
        orderId
      );

      // fetch fromLots
      const fromLots: Lot[] = await getLotsTransactional(
        firestore,
        transaction,
        profile,
        order.positions.filter(filterFromPositions),
        false
      );

      // Update from lots
      for (const [idx, lot] of fromLots.entries()) {
        if (!lot) {
          console.warn(
            `Lot ${order.positions[idx].lotId} not found, could not be updated`
          );
          continue;
        }

        // remove production transfer
        lot.transfers = lot.transfers.filter((t) => t.transferId !== order.id);

        await setLotTransactional(firestore, transaction, profile, lot);
      }

      // Delete waste docs
      if (!wasteDocs.empty) {
        for (const doc of wasteDocs.docs) {
          transaction.delete(doc.ref);
        }
      }

      // Delete production order
      return transaction.delete(orderColReference.doc(order.id));
    });
  } catch (error) {
    console.error(`Could not undo production order ${orderId}`, error);
  }
}

// 4.3 Internal transfers
export async function importInternalTransfer(
  store: firebase.firestore.Firestore,
  profile: UserProfile,
  payload: InternalTransferPayload,
  checkProduct: boolean = false,
  isAgVariety: boolean = false
): Promise<any> {
  return inTransaction(store, async (transaction: firebase.firestore.Transaction) => {
    // filter out repeated positions
    let positions = payload.positions;
    let positionsIds = positions.map((o) => o.lotId);
    positions = positions.filter(
      ({ lotId }, index) => !positionsIds.includes(lotId, index + 1)
    );

    // We have to resolve the article in the db
    let productsToUpdate: { [productId: string]: ProductView } = {};

    if (transaction) {
      const res = await resolveArticlesInPositions(
        positions,
        transaction,
        payload,
        store,
        profile,
        isAgVariety,
        checkProduct
      );
      positions = res.newPositions;
      productsToUpdate = res.productsToUpdate;
    }

    const oldOrder: Order = await getOrderTransactional(
      store,
      transaction,
      profile,
      payload.id
    );

    let order: Order = {
      ...payload,
      fulfilmentDate: standardizeDate(payload.fulfilmentDate),
      positions,
      type: 'INTERNAL_TRANSFER',
      qcRelevant: true,
      orgId: profile.organisationId,
      locationId: payload.locationId,
      dispatchLocationId: payload.dispatchLocationId,
      qcStatus: oldOrder?.qcStatus ?? 'OPEN',
      lastModifiedUserId: oldOrder?.lastModifiedUserId ?? 'system',
      lastModifiedDate: oldOrder?.lastModifiedDate ?? new Date(),
      lastQCStatusUserId: oldOrder?.lastQCStatusUserId,
      lastQCStatusDate: standardizeDate(oldOrder?.lastQCStatusDate),
      lotInspectionMap: oldOrder?.lotInspectionMap,
    };

    return setOrderTransactional(store, transaction, profile, order, oldOrder).then(
      () => {
        // Update the organisation specific product view if new varieties, brands, etc, were found
        setProductViewsTransactional(productsToUpdate, transaction, store, profile);
      }
    );
  });
}

// 4.4 Attachments management
export async function updateOrderAttachments(
  firestore: firebase.firestore.Firestore,
  profile: UserProfile,
  order: Order,
  attachments: string[]
) {
  orderColRef(firestore, profile.organisationId)
    .doc(order.id)
    .update({ attachments, hasReportDraft: true } as Order);
}

// this uploads to the storage
export async function uploadOrderOrReportAttachments(
  storage: firebase.storage.Storage,
  files: FileList,
  profile: UserProfile,
  order?: Order,
  report?: Report
) {
  const maxAttachmentSize = 1024 * 1024 * 5; // 5 MB
  let errors: { filename: string; error: string }[] = [];
  const newAttachments: string[] = [];

  let collection: string, id: string;

  if (!!order) {
    id = order.id;
    collection = 'order';
  } else if (!!report) {
    id = report.id;
    collection = 'report';
  } else {
    throw new Error('Need at least one entity');
  }

  // Uploading files
  for (const file of files) {
    const filename = file.name;
    const storagePath = `uploads/${profile.organisationId}/${collection}/${id}/${filename}`;

    try {
      if (file.size > maxAttachmentSize) {
        throw new Error(
          `File too big, must be smaller than ${
            maxAttachmentSize / (1024 * 1024)
          } megabytes`
        );
      }
      const content = await readFileAsync(file);
      await storage.ref().child(storagePath).put(content);
      console.log('Uploaded:', storagePath);
      newAttachments.push(storagePath);
    } catch (error) {
      console.log(`Error uploading ${storagePath}`, error);
      errors.push({ filename, error });
    }
  }

  return { errors, newAttachments };
}

// UPLOADS TO WIKI
export async function uploadWikiImages(
  storage: firebase.storage.Storage,
  files: FileList,
  profile: UserProfile
) {
  const maxAttachmentSize = 1024 * 1024 * 5; // 5 MB
  let errors: { filename: string; error: string }[] = [];
  const newAttachments: AnnotatedImage[] = [];

  // Uploading files
  for (const file of files) {
    const filename = uuid4();
    const storagePath = `uploads/${profile.organisationId}/wiki/${filename}`;

    try {
      if (file.size > maxAttachmentSize) {
        throw new Error(
          `File too big, must be smaller than ${
            maxAttachmentSize / (1024 * 1024)
          } megabytes`
        );
      }
      const content = await readFileAsync(file);
      await storage.ref().child(storagePath).put(content);
      console.log('Uploaded:', storagePath);

      newAttachments.push({ storageId: storagePath } as AnnotatedImage);
    } catch (error) {
      console.log(`Error uploading ${storagePath}`, error);
      errors.push({ filename, error });
    }
  }

  return { errors, newAttachments };
}

export async function deleteOrderAttachment(
  firestore: firebase.firestore.Firestore,
  storage: firebase.storage.Storage,
  profile: UserProfile,
  order: Order,
  path: string
) {
  // delete reference to attachment in order
  await orderColRef(firestore, profile.organisationId)
    .doc(order.id)
    .update({
      attachments: order.attachments.filter((a) => a !== path),
      hasReportDraft: true,
    } as Order);

  // delete actual file from storage
  await storage.ref().child(path).delete();
}

export async function deleteOrder(
  firestore: firebase.firestore.Firestore,
  profile: UserProfile,
  order: Order,
  deletedFrom: string
) {
  // Archive the order
  const deletionInfo: DeletionInfo = {
    deletedByUserId: profile.id,
    deletedFrom,
    deletionDate: firebase.firestore.FieldValue.serverTimestamp() as any,
    originalPath: `organisation/${profile.organisationId}/common/${profile.organisationId}/order/${order.id}`,
  };

  await orderColRef(firestore, profile.organisationId, undefined, ARCHIVED_COL_NAME)
    .doc(order.id)
    .set({ ...order, deletionInfo });

  // If the order was created using ERP-lite functionality, we don't delete the lots.
  // We just remove the positions from the order to trigger an update of the first transfer of each lot
  if (order.isAGMaster) {
    await saveOrderWrapper(
      firestore,
      undefined,
      profile,
      { id: order.id, positions: [] } as OrderCreatePayload,
      false,
      undefined,
      undefined,
      order.type === 'BUY'
    );

    // We also update the inspections in the lots, removing all references to the order in their inspections
    await addOrRemoveOrderReferenceInLotInspections(
      firestore,
      profile,
      order,
      'remove'
    );
  }
  // If the order was imported via API, we delete the lots
  else {
    for (const { lotId } of order.positions) {
      await deleteLot(firestore, profile, lotId, deletedFrom, undefined, true);
    }
  }

  // Finally, delete the order
  return orderColRef(firestore, profile.organisationId).doc(order.id).delete();
}

export async function addOrRemoveOrderReferenceInLotInspections(
  store: firebase.firestore.Firestore,
  profile: UserProfile,
  order: Order | OrderCreatePayload,
  action: 'add' | 'remove'
) {
  const orderCopy = cloneDeep(order);
  const lots: Lot[] = await getLotsTransactional(
    store,
    undefined,
    profile,
    orderCopy.positions.filter((p) => !!orderCopy.lotInspectionMap[p.lotId])
  );
  const batch = store.batch();

  for (const lot of lots) {
    const orderInspection = orderCopy.lotInspectionMap[lot.id];
    const updateObj: Lot = {} as Lot;

    const mapReference = (i: LotInspection) => {
      if (
        action === 'add' &&
        hasInspectionReference(i, { ...orderInspection.reference, orderId: undefined })
      ) {
        return { ...i, reference: { ...i.reference, orderId: orderCopy.id } };
      } else if (
        action === 'remove' &&
        hasInspectionReference(i, orderInspection.reference)
      ) {
        return { ...i, reference: { ...i.reference, orderId: undefined } };
      }
      return i;
    };

    updateObj.inspections = lot.inspections.map(mapReference);
    updateObj.latestInspection = mapReference(lot.latestInspection);

    const lotRef = lotColRef(store, profile.organisationId).doc(lot.id);
    batch.update(lotRef, updateObj);
  }

  await batch.commit();
}

/***********************/
// 5. LOTS
/***********************/

// 5.1 General
export function getLotSnapshot(
  firestoreRef: firebase.firestore.Firestore,
  organisationId: string,
  lotId: string,
  onResult: (lot: Lot | null) => any,
  lotOrganisationId: string = organisationId,
  collection: string = COMMON_COL_NAME
): () => void {
  return lotColRef(firestoreRef, organisationId, collection, lotOrganisationId)
    .doc(lotId)
    .onSnapshot(...handleOnDocSnapshot(onResult));
}

export async function getLot(
  firestoreRef: firebase.firestore.Firestore,
  organisationId: string,
  lotId: string,
  lotOrganisationId: string = organisationId,
  collection: string = COMMON_COL_NAME
): Promise<Lot> {
  return lotColRef(firestoreRef, organisationId, collection, lotOrganisationId)
    .doc(lotId)
    .get()
    .then((db) => db.data() as Lot);
}

export async function createSupplyChainLot(
  store: firebase.firestore.Firestore,
  profile: UserProfile,
  payload: LotCreatePayload,
  org: Organisation
): Promise<Lot> {
  const numBoxes = isNaN(payload.numBoxes) ? undefined : payload.numBoxes;
  const volumeInKg = isNaN(payload.volumeInKg) ? undefined : payload.volumeInKg;
  const date = new Date();

  const transferType: OrderType = 'CREATE_LOT';
  let transfers: LotTransfer[] =
    (payload.transfers ?? []).length > 0
      ? payload.transfers
      : [
          {
            transferId: `${transferType}-${payload.id}`,
            transferType,
            numBoxes,
            volumeInKg,
            transferDate: date,
          },
        ];

  const transient: LotTransientProps = {
    freshnessDate: date,
    locationId: payload.locationId,
    palletIds: payload.palletIds,
    isInMyPossession: false,
    numBoxes,
    volumeInKg,
  };

  const origin: LotOriginProps = {
    growerContactId: payload.growerContactId,
    plotId: payload.originPlotId,
    locationId: payload.originLocationId,
    growerGGN: payload.growerGGN,
    growerGGNs: [payload.growerGGN],
    harvestDate: payload.originHarvestDate,
    previousHarvestDate: payload.originPreviousHarvestDate,
    harvestNumber: isNaN(payload.originHarvestNumber)
      ? undefined
      : payload.originHarvestNumber,
    daysFromPrevHarvest: isNaN(payload.originDaysFromPrevHarvest)
      ? undefined
      : payload.originDaysFromPrevHarvest,
  };

  const previousOrg: LotPreviousOrgProps = {
    lotReferenceId: payload.prevOrgLotReferenceId,
  };

  let lot: Lot = {
    id: payload.id,
    emergenceDate: date,
    orgId: profile.organisationId,
    lastModifiedDate: date,
    lastSystemDate: date,
    article: payload.article,
    isAGMaster: true,
    suppliedByContactId: payload.suppliedByContactId,
    transfers,
    transient,
    origin,
    previousOrg,
  };

  await setLotFromUI(store, profile, lot, SUPPLY_CHAIN_COL_NAME, org);
  await createLotAction(store, profile, lot, true);
  return lot;
}

export async function createNewLot(
  store: firebase.firestore.Firestore,
  profile: UserProfile,
  payload: LotCreatePayload,
  org?: Organisation
): Promise<Lot> {
  let lot: Lot;
  const date = new Date();

  const transient: LotTransientProps = {
    freshnessDate: date,
    locationId: payload.locationId,
    palletIds: payload.palletIds,
    isInMyPossession: true,
  } as LotTransientProps;

  const origin: LotOriginProps = {
    growerContactId: payload.growerContactId,
    plotId: payload.originPlotId,
    locationId: payload.originLocationId,
    growerGGN: payload.growerGGN,
    growerGGNs: [payload.growerGGN],
    harvestDate: payload.originHarvestDate,
    previousHarvestDate: payload.originPreviousHarvestDate,
    harvestNumber: isNaN(payload.originHarvestNumber)
      ? undefined
      : payload.originHarvestNumber,
    daysFromPrevHarvest: isNaN(payload.originDaysFromPrevHarvest)
      ? undefined
      : payload.originDaysFromPrevHarvest,
  };

  const previousOrg: LotPreviousOrgProps = {
    lotReferenceId: payload.prevOrgLotReferenceId,
  };

  const transferType = payload.firstTransferType ?? 'CREATE_LOT';
  let createLotTransfer: LotTransfer[] = [
    {
      transferId: createLotActionId(),
      transferType,
      numBoxes: payload.numBoxes,
      volumeInKg: payload.volumeInKg,
      transferDate: new Date(),
    },
  ];

  lot = {
    id: payload.id,
    isAGMaster: true,
    orgId: profile.organisationId,
    lastModifiedDate: date,
    lastSystemDate: date,
    previousOrg,
    suppliedByContactId: payload.suppliedByContactId,
    transfers: [...createLotTransfer, ...(payload.transfers ?? [])],
    transient,
    origin,
  };

  if (payload.article) {
    const { article } = payload;
    lot.article = article;
    console.log('payload article: ', article);

    // update all articles in inspections
    lot.inspections = (lot.inspections ?? []).map((i) => ({
      ...i,
      lotProperties: { ...i.lotProperties, article },
    }));
    lot.latestInspection = !!lot.latestInspection
      ? {
          ...lot.latestInspection,
          lotProperties: { ...lot.latestInspection.lotProperties, article },
        }
      : undefined;
  }

  await setLotFromUI(store, profile, lot, undefined, org);
  await createLotAction(store, profile, lot);
  return lot;
}

export async function createLotAction(
  store: firebase.firestore.Firestore,
  profile: UserProfile,
  lot: Lot,
  isSupplyChain: boolean = false
) {
  const lotAction = mapLotToLotAction(lot, isSupplyChain);
  updateAggregateModificationData(profile.id, lotAction);
  return offlineSet(
    lotActionColRef(store, profile.organisationId).doc(lotAction.id),
    lotAction
  );
}

async function updateLotAction(
  store: firebase.firestore.Firestore,
  profile: UserProfile,
  lot: Lot
) {
  const { transferType: type, transferDate, transferId: id } = lot.transfers[0];
  const lotActionRef = lotActionColRef(store, profile.organisationId).doc(id);
  const position: LotPosition = {
    lotId: lot.id,
    numBoxes: lot.transient?.numBoxes,
    article: lot.article,
  };

  const updateObj: Order = {
    type,
    fulfilmentDate: standardizeDate(transferDate),
    locationId: lot.transient?.locationId,
    positions: [position],
    contactId: lot.suppliedByContactId ?? lot.origin?.growerContactId ?? null,
  } as Order;

  updateAggregateModificationData(profile.id, updateObj);

  return offlineUpdate(lotActionRef, updateObj);
}

export async function deleteLotAction(
  store: firebase.firestore.Firestore,
  profile: UserProfile,
  lot: Lot
) {
  const { transferId } = lot.transfers[0];
  await lotActionColRef(store, profile.organisationId).doc(transferId).delete();
}

export async function saveLotUnderNewId(
  store: firebase.firestore.Firestore,
  profile: UserProfile,
  oldLot: Lot,
  newLotId: string,
  orgSettings: OrganisationSettings,
  collection: string = COMMON_COL_NAME,
  updateObj?: LotUpdateObject
) {
  // Update lot id everywhere in the document
  const lot: Lot = changeLotId(oldLot, newLotId, orgSettings);

  updateAggregateModificationData(profile.id, lot);

  // save it under the new id
  await offlineSet(
    lotColRef(store, profile.organisationId, collection).doc(newLotId),
    lot
  );

  // Update fields if required
  if (updateObj != null) {
    await updateLotAtomic(
      store,
      profile,
      lot,
      updateObj,
      orgSettings,
      collection,
      collection === COMMON_COL_NAME
    );
  }
  // delete previous one
  return deleteLot(
    store,
    profile,
    oldLot.id,
    `Change lot id from ${oldLot.id} to ${newLotId} from PageNewLot`,
    collection
  );
}

export async function updateLotAtomic(
  store: firebase.firestore.Firestore,
  profile: UserProfile,
  lot: Lot,
  updateObj: LotUpdateObject,
  orgSettings: OrganisationSettings,
  collection: string = COMMON_COL_NAME,
  shouldUpdateLotAction: boolean = false
) {
  if (!updateObj) {
    return;
  }

  updateAggregateModificationData(profile.id, updateObj);

  if (
    !!lot?.article &&
    !!updateObj?.article &&
    !isEqual(lot.article, updateObj.article)
  ) {
    const l2 = replaceArticleInLotInspections(lot, updateObj.article);
    updateObj.inspections = l2.inspections;
    updateObj.latestInspection = l2.latestInspection;
  }

  updateObj.search = generateLotSearch(lot, orgSettings, updateObj);

  const lotRef = lotColRef(store, profile.organisationId, collection).doc(lot.id);

  await offlineUpdate(lotRef, updateObj);
  if (!shouldUpdateLotAction) {
    return;
  }
  const updatedLot: Lot = (await lotRef.get()).data() as Lot;
  return updateLotAction(store, profile, updatedLot);
}

export async function getLotsTransactional(
  store: firebase.firestore.Firestore,
  transaction: firebase.firestore.Transaction,
  profile: UserProfile,
  lotPositions: LotPosition[],
  createNew = true,
  order?: Order
): Promise<Lot[]> {
  const oldLots: Lot[] = [];
  for (const position of lotPositions) {
    oldLots.push(
      await getLotTransactional(
        store,
        transaction,
        profile,
        position,
        createNew,
        undefined,
        order
      )
    );
  }
  return oldLots;
}

export async function getLotTransactional(
  store: firebase.firestore.Firestore,
  transaction: firebase.firestore.Transaction,
  profile: UserProfile,
  lotPosition: LotPosition,
  createNew = true,
  collection: string = COMMON_COL_NAME,
  order?: Order
): Promise<Lot> {
  const lotId = lotPosition.lotId;

  const collRef = lotColRef(store, profile.organisationId, collection).doc(lotId);

  let result;

  // TODO: add an unknownSource field to indicate that it's a dummy lot. The problem to handle here is that if the
  // actual lot is imported later, we'd have to remove this flag

  const numBoxes = lotPosition.numBoxes;
  const volumeInKg =
    lotPosition.volumeInKg ??
    new Article(lotPosition?.article).computeVolumeInKg(numBoxes);

  const date = new Date();
  const transient: LotTransientProps = {
    freshnessDate: date,
    numBoxes,
    volumeInKg,
    isInMyPossession: collection !== SUPPLY_CHAIN_COL_NAME,
  };

  const transfers: LotTransfer[] = !!order
    ? []
    : [
        {
          transferId: `CREATE_LOT-${lotPosition.lotId}`,
          transferType: 'CREATE_LOT',
          numBoxes,
          volumeInKg,
          transferDate: date,
        },
      ];

  const emptyLot: Lot = {
    id: lotId,
    article: lotPosition.article,
    orgId: profile.organisationId,
    transfers,
    lastModifiedDate: date,
    lastSystemDate: date,
    transient,
  };

  emptyLot.search = generateLotSearch(emptyLot);

  if (transaction) {
    result = await transaction.get(collRef);
    if (result.exists) {
      return result.data() as Lot;
    }
    if (createNew) {
      return emptyLot;
    } else {
      return undefined;
    }
  } else {
    try {
      result = await collRef.get();
    } catch (e) {
      // console.log(e)
      return emptyLot;
    }
  }

  if (result.exists) {
    return result.data() as Lot;
  }
  if (createNew) {
    return emptyLot;
  } else {
    return undefined;
  }
}

export function offlineSet<T extends Record<any, any>>(
  ref: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>,
  value: T
): Promise<void> {
  return makeOfflineWriteAwaitable(ref, () => ref.set(value));
}

export function offlineUpdate<T extends Record<any, any>>(
  ref: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>,
  value: Partial<T>
): Promise<void> {
  return makeOfflineWriteAwaitable(ref, () => ref.update(value));
}

export function offlineDelete(
  ref: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
): Promise<void> {
  return makeOfflineWriteAwaitable(ref, () => ref.delete());
}

// Idea taken from here: https://github.com/firebase/firebase-js-sdk/issues/6515#issuecomment-1211423817
function makeOfflineWriteAwaitable(
  ref: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>,
  operation: () => Promise<void>
): Promise<void> {
  return new Promise((res, rej) => {
    try {
      operation();

      // Capture the snapshot, which will fire when the document gets written to the cache
      const unsubscribe = ref.onSnapshot(() => {
        // Unsubscribe from the snapshot so it doesn't continue to fire
        unsubscribe();
        // Resolve the promise, so `await`ing the promise won't also `await` the backend write
        return res();
      });
    } catch (e) {
      // Reject if there's an error
      rej(e);
    }
  });
}

export async function setLotFromUI(
  store: firebase.firestore.Firestore,
  profile: UserProfile,
  newLot: Lot,
  collection: string = COMMON_COL_NAME,
  org?: Organisation
): Promise<void> {
  const lotRef = lotColRef(store, profile.organisationId, collection).doc(newLot.id);

  // Populate all relevant lot data
  populateLotFields(newLot, profile, collection, org);

  return offlineSet(lotRef, newLot);
}

export async function setLotTransactional(
  store: firebase.firestore.Firestore,
  transaction: firebase.firestore.Transaction,
  profile: UserProfile,
  newLot: Lot,
  fromUpdateAssessment = false,
  order?: Order,
  collection: string = COMMON_COL_NAME
): Promise<any> {
  const collRef = lotColRef(store, profile.organisationId, collection).doc(newLot.id);

  let org: Organisation;

  try {
    org = (await orgDocRef(store, profile.organisationId).get()).data() as Organisation;
  } catch (error) {
    console.log('Could not fetch organisation', error);
  }

  // Populate all relevant lot data
  populateLotFields(newLot, profile, collection, org);

  if (transaction) {
    // the fromUpdateAssessment flag is necessary so, when we import an assessment, the userInputMap doesn't get merged but gets replaced by the new one
    return transaction.set(collRef, newLot, { merge: !fromUpdateAssessment });
  } else {
    // careful on offline mode. collRef.set promise will only fire when is back online.
    // write to the cache happens immediatly so there is no need for await for the promise to end
    // neither return it as a promise cause then the caller function wont resolve.
    // that's why the return statement goes in the next line.
    collRef
      .set(newLot, { merge: !fromUpdateAssessment })
      .catch((e) => console.error('error on collRef.set', e));
    return;
  }
}

function populateLotFields(
  lot: Lot,
  profile: UserProfile,
  collection: string,
  org?: Organisation
) {
  // Update metadata
  updateAggregateModificationData(profile.id, lot);

  // sort transfers
  const sortedTransfers = (lot.transfers ?? []).sort(sortLotTransfers);

  // update arrivalDate
  // we the use date of the first transfer to set it as emergenceDate
  let orderAllocation = sortedTransfers.reverse()?.[0];
  if (!!orderAllocation) {
    lot.emergenceDate = new Date(standardizeDate(orderAllocation.transferDate));
  }

  // update transient info
  let freshnessDate = standardizeDate(
    lot.lastQCDate ?? lot.emergenceDate ?? lot.lastModifiedDate
  );

  if (isNaN(freshnessDate.getTime())) {
    freshnessDate = undefined;
  }

  let locationId: string;
  const lastTransferWithLocation = sortedTransfers.filter(
    (t) => !!t.locationId || !!t.dispatchLocationId
  )[0];
  if (!!lastTransferWithLocation) {
    locationId =
      lastTransferWithLocation.transferType === 'SELL'
        ? lastTransferWithLocation.dispatchLocationId
        : lastTransferWithLocation.locationId;
  }

  const isInMyPossession = collection !== SUPPLY_CHAIN_COL_NAME;

  const { numBoxes, volumeInKg } = computeTransientQuantities(lot);

  if (!lot.transient) {
    lot.transient = {
      locationId,
      freshnessDate,
      numBoxes,
      isInMyPossession,
      volumeInKg,
    };
  } else {
    // if we cannot determine from transfers where it is but the new lot has info about the location, set it up
    lot.transient.locationId = locationId ?? lot.transient.locationId;
    lot.transient.freshnessDate = freshnessDate;
    lot.transient.numBoxes = numBoxes;
    lot.transient.volumeInKg = volumeInKg;
  }

  // undo reverse
  sortedTransfers.reverse();

  lot.search = generateLotSearch(lot, org?.settings);
}

// 5.2 Split lots
export async function saveSplitLot(
  store: firebase.firestore.Firestore,
  profile: UserProfile,
  payload: SplitLotPayload,
  checkProduct: boolean = false,
  isAgVariety: boolean = false
): Promise<any> {
  return inTransaction(store, async (transaction: firebase.firestore.Transaction) => {
    payload.incomingPositions = (
      await resolveArticlesInPositions(
        payload.incomingPositions,
        transaction,
        payload,
        store,
        profile,
        isAgVariety,
        checkProduct
      )
    ).newPositions;

    payload.outgoingPositions = (
      await resolveArticlesInPositions(
        payload.outgoingPositions,
        transaction,
        payload,
        store,
        profile,
        isAgVariety,
        checkProduct
      )
    ).newPositions;

    // convert positions to what's expected internally
    const positions: LotPosition[] = [
      ...payload.incomingPositions.map((p) =>
        mapProductionOrSplitLotPayloadPositions(p, -1)
      ),
      ...payload.outgoingPositions.map((p) =>
        mapProductionOrSplitLotPayloadPositions(p)
      ),
    ];

    let order: Order = {
      ...payload,
      fulfilmentDate: standardizeDate(payload.fulfilmentDate),
      positions,
      type: 'SPLIT_LOT',
      qcRelevant: false,
      orgId: profile.organisationId,
    };

    // delete these fields present in the payload
    // @ts-ignore
    delete order.incomingPositions;
    // @ts-ignore
    delete order.outgoingPositions;

    return setSplitLotTransactional(
      store,
      transaction,
      profile,
      order,
      checkProduct,
      isAgVariety
    );
  });
}

export async function setSplitLotTransactional(
  store: firebase.firestore.Firestore,
  transaction: firebase.firestore.Transaction,
  profile: UserProfile,
  splitLotOrder: Order,
  checkProduct: boolean = false,
  isAgVariety: boolean = false
) {
  const splitLotOrderRef = orderColRef(store, profile.organisationId).doc(
    splitLotOrder.id
  );

  // if we are calling this function from the import (transaction == true), we have to resolve the article in the db
  let productsToUpdate: { [productId: string]: ProductView } = {};

  if (transaction) {
    const res = await resolveArticlesInPositions(
      splitLotOrder.positions,
      transaction,
      splitLotOrder,
      store,
      profile,
      isAgVariety,
      checkProduct
    );
    splitLotOrder.positions = res.newPositions;
    productsToUpdate = res.productsToUpdate;
  }

  const lots: Lot[] = await getLotsTransactional(
    store,
    transaction,
    profile,
    splitLotOrder.positions,
    false
  );
  const org = await getOrganisation(store, profile.organisationId);

  // separate positions in mothers and children
  const motherPositions: LotPosition[] =
    splitLotOrder.positions.filter(filterFromPositions);
  const subLotPositions: LotPosition[] =
    splitLotOrder.positions.filter(filterToPositions);

  // update the IO with split lot info, if it applies
  let oldIncomingOrder: Order;

  if (splitLotOrder.splitLotOrderIdLink) {
    //     - At split lot time -
    //     - copy the new lots into positions of the IO
    //         - Add a flag to mark them as associated lots (=True)
    //         - Add to the position, a field called motherLot and set it to the original lot for splitted lots
    //         - If the splitted lot exists, and it has an assessment, copy the assessment into the assessment map

    oldIncomingOrder = await getOrderTransactional(
      store,
      transaction,
      profile,
      splitLotOrder.splitLotOrderIdLink,
      false
    );

    if (!oldIncomingOrder) {
      throw new Error(
        `Problem when importing split lot order ${splitLotOrder.id}: incoming order ${splitLotOrder.splitLotOrderIdLink} not found`
      );
    }

    // Copy the sub lot positions to the IO
    for (const sublotPos of subLotPositions) {
      // add info about mother lot(s)
      sublotPos.motherLotIds = motherPositions.map((p) => p.lotId);

      // check if sublot position exists already in the IO and handle it accordingly
      const sublotIndex = oldIncomingOrder.positions.findIndex(
        (p) => p.lotId === sublotPos.lotId
      );

      if (sublotIndex < 0) {
        oldIncomingOrder.positions.push(sublotPos);
      } else {
        oldIncomingOrder.positions[sublotIndex] = sublotPos;
      }
    }

    // update info in the original positions
    for (const pos of motherPositions) {
      // get index of position
      const lotIndex = oldIncomingOrder.positions.findIndex(
        (p) => p.lotId === pos.lotId
      );
      // update relevant info
      oldIncomingOrder.positions[lotIndex] = {
        ...oldIncomingOrder.positions[lotIndex],
        palletIds: pos.palletIds,
      };
    }

    // update other order info
    oldIncomingOrder.hasSplitLot = true;
    updateAggregateModificationData(profile.id, oldIncomingOrder);
    oldIncomingOrder.search = generateOrderSearch(oldIncomingOrder, org.settings);
  }

  // create/update lots
  for (const position of splitLotOrder.positions) {
    const lotId = position.lotId;

    const numBoxes = position.numBoxes;
    const volumeInKg =
      position.volumeInKg ?? new Article(position?.article).computeVolumeInKg(numBoxes);

    const transfer: LotTransfer = {
      fromLots: motherPositions.map(mapLotTransferSummary),
      toLots: subLotPositions.map(mapLotTransferSummary),
      transferType: 'SPLIT_LOT',
      numBoxes,
      volumeInKg,
      transferDate: standardizeDate(splitLotOrder.fulfilmentDate),
      locationId: splitLotOrder.locationId,
      transferId: splitLotOrder.id,
      dispatchLocationId: splitLotOrder.dispatchLocationId,
      splitLotOrderIdLink: splitLotOrder.splitLotOrderIdLink,
    };

    let lot: Lot = lots.find((l) => l?.id === lotId);

    const palletIds = position.palletIds;

    // If it's a mother lot
    if (position.numBoxes < 0) {
      if (!lot) {
        throw new Error(
          `Problem when importing split lot order ${splitLotOrder.id}: original lot ${lotId} doesn't exist`
        );
      }

      const existingTransferIdx = lot.transfers.findIndex(
        (t) => t.transferId === splitLotOrder.id
      );

      // when transfer with the same id already exists
      if (existingTransferIdx >= 0) {
        lot.transfers[existingTransferIdx] = transfer;
      } else {
        lot.transfers.push(transfer);
      }

      if (!lot.transient) {
        lot.transient = {
          palletIds,
          isInMyPossession: true,
        } as LotTransientProps;
      } else {
        lot.transient.palletIds = palletIds;
      }

      // If it's a child lot
    } else {
      const motherLot: Lot = lots.find(
        (l) =>
          (position.motherLotIds ?? []).length > 0 && l.id === position.motherLotIds[0]
      );
      const { suppliedByContactId } = motherLot ?? ({} as Lot);

      if (lot) {
        const existingTransferIdx = lot.transfers.findIndex(
          (t) => t.transferId === splitLotOrder.id
        );

        // when transfer with the same id already exists
        if (existingTransferIdx >= 0) {
          lot.transfers[existingTransferIdx] = transfer;
        } else {
          lot.transfers.push(transfer);
        }

        // if the sublot had already been created, we just merge relevant data that could've changed
        lot = {
          ...lot,
          article: position.article,
        };

        // we propagate the suppliedByContactId from the mother lot to the child
        if (suppliedByContactId != null) {
          lot.suppliedByContactId = suppliedByContactId;
        }

        if (!lot.transient) {
          lot.transient = {
            palletIds,
            isInMyPossession: true,
          } as LotTransientProps;
        } else {
          lot.transient.palletIds = palletIds;
        }

        // copy assessment to order
        if (oldIncomingOrder) {
          const lotInspection = lot.latestInspection;
          if (lotInspection) {
            if (!oldIncomingOrder.lotInspectionMap) {
              oldIncomingOrder.lotInspectionMap = {};
            }
            // assign IO to the reference
            lotInspection.reference.orderId = splitLotOrder.splitLotOrderIdLink;
            oldIncomingOrder.lotInspectionMap[lot.id] = lotInspection;
          }
        }
      } else {
        // if the sublot doesn't exist yet, we create it
        const transient: LotTransientProps = {
          palletIds,
          locationId: splitLotOrder.locationId,
          freshnessDate: new Date(splitLotOrder.fulfilmentDate),
          hasLink: false,
          isInMyPossession: true,
        } as LotTransientProps;

        lot = {
          id: lotId,
          emergenceDate: new Date(splitLotOrder.fulfilmentDate),
          article: position.article,
          suppliedByContactId,
          orgId: profile.organisationId,
          transfers: [transfer],
          transient,
        };
      }
    }

    await setLotTransactional(store, transaction, profile, lot, true, oldIncomingOrder);
  }

  // Update metadata and set split lot order
  updateAggregateModificationData(profile.id, splitLotOrder);
  if (transaction) {
    transaction.set(splitLotOrderRef, splitLotOrder);
  } else {
    splitLotOrderRef.set(splitLotOrder);
  }

  // Update the organisation specific product view if new varieties, brands, etc, were found
  setProductViewsTransactional(productsToUpdate, transaction, store, profile);

  // Update original IO if necessary
  if (oldIncomingOrder) {
    const oldIORef = orderColRef(store, profile.organisationId).doc(
      oldIncomingOrder.id
    );
    if (transaction) {
      transaction.set(oldIORef, oldIncomingOrder);
    } else {
      oldIORef.set(oldIncomingOrder);
    }
  }
}

export async function undoSplitLotOrder(
  firestore: firebase.firestore.Firestore,
  profile: UserProfile,
  slPosition: LotPosition,
  oldOrder: Order,
  orgSettings: OrganisationSettings,
  sublot?: Lot
) {
  // Note: this function should only be called in online mode
  try {
    return firestore.runTransaction(async (transaction) => {
      console.log(slPosition, oldOrder);

      const lotColReference = lotColRef(firestore, profile.organisationId);
      const orderColReference = orderColRef(firestore, profile.organisationId);

      let order: Order = _.cloneDeep(oldOrder);

      // fetch sublot and mother lot(s)
      if (!sublot) {
        sublot = (
          await transaction.get(lotColReference.doc(slPosition.lotId))
        ).data() as Lot;
      }
      const motherLots: Lot[] = await getLotsTransactional(
        firestore,
        transaction,
        profile,
        slPosition.motherLotIds.map((id) => ({ lotId: id } as LotPosition)),
        false,
        oldOrder
      );

      // find sublot order id in the transfers and extract relevant info
      const splitLotTransfer: LotTransfer = sublot.transfers.find(
        (t) =>
          t.toLots?.[0].lotId === slPosition.lotId && t.transferType === 'SPLIT_LOT'
      );
      const splitLotOrderId: string = splitLotTransfer?.transferId;

      // Remove sublot position from order
      order.positions = order.positions.filter((p) => p.lotId !== sublot.id);

      // if no sublot positions left, remove the flag
      if (
        order.positions.filter((p) => !!p.motherLotIds && p.motherLotIds?.length > 0)
          .length === 0
      ) {
        order.hasSplitLot = undefined;
      }

      // Update mother lot
      for (const lot of motherLots) {
        const motherPositionIdx = order.positions.findIndex((p) => p.lotId === lot.id);
        const motherPosition = order.positions[motherPositionIdx];
        const restoredPalletIds = [
          ...new Set([...motherPosition.palletIds, ...slPosition.palletIds]),
        ];

        // restore original pallets and quantity (handled in setLotTransactional)
        order.positions[motherPositionIdx].palletIds = restoredPalletIds;

        if (!lot.transient) {
          lot.transient = {
            palletIds: restoredPalletIds,
            isInMyPossession: true,
          } as LotTransientProps;
        } else {
          lot.transient.palletIds = restoredPalletIds;
        }

        // remove sublot transfer
        lot.transfers = lot.transfers.filter((t) => t.transferId !== splitLotOrderId);
        await setLotTransactional(firestore, transaction, profile, lot);
      }

      // Set order in db
      order.search = generateOrderSearch(order, orgSettings);
      updateAggregateModificationData(profile.id, order);
      transaction.set(orderColReference.doc(order.id), order);

      // Delete split lot order
      if (splitLotOrderId != null) {
        transaction.delete(orderColReference.doc(splitLotOrderId));
      } else {
        console.log(`Warning: split lot order not found!`);
      }

      // Archive and delete sublot
      const deletionInfo = {
        deletedByUserId: profile.id,
        deletedFrom: 'PageOrder',
        deletionDate: firebase.firestore.FieldValue.serverTimestamp() as any,
        originalPath: `${lotColReference.path}/${sublot.id}`,
      };
      await setLotTransactional(
        firestore,
        transaction,
        profile,
        { ...sublot, deletionInfo },
        undefined,
        undefined,
        ARCHIVED_COL_NAME
      );
      return transaction.delete(lotColReference.doc(slPosition.lotId));
    });
  } catch (error) {
    console.error(`Could not undo split lot ${slPosition.lotId}`, error);
  }
}

export async function deleteLot(
  firestore: firebase.firestore.Firestore,
  profile: UserProfile,
  lotId: string,
  deletedFrom: string,
  collection: string = COMMON_COL_NAME,
  fromDeleteOrder: boolean = false
) {
  const lotRef = lotColRef(firestore, profile.organisationId, collection).doc(lotId);
  const lotDoc = await lotRef.get();
  if (!lotDoc.exists) {
    throw Error(`Lot id ${lotId} can not be deleted because it doesn't exist`);
  }

  const lot: Lot = lotDoc.data() as Lot;

  // Archive lot
  lot.deletionInfo = {
    deletedByUserId: profile.id,
    deletedFrom,
    deletionDate: firebase.firestore.FieldValue.serverTimestamp() as any,
    originalPath: lotRef.path,
  };
  await offlineSet(
    lotColRef(firestore, profile.organisationId, ARCHIVED_COL_NAME).doc(lotId),
    lot
  );

  // We skip this step if the deletion is being triggered from deleteOrder, since
  // this is already being taken care of there
  // We also skip it if it's a supply chain lot
  if (collection === COMMON_COL_NAME && !fromDeleteOrder) {
    const firstTransfer: LotTransfer = lot.transfers.sort(sortLotTransfers)[0];

    // If the lot came in an order, remove the lot reference from the positions
    // If not, delete the associated lot action
    if (!!firstTransfer.orderId) {
      const order: Order = (
        await orderColRef(firestore, profile.organisationId)
          .doc(firstTransfer.orderId)
          .get()
      ).data() as Order;

      if (!!order) {
        await setOrderTransactional(firestore, undefined, profile, {
          ...order,
          positions: order.positions.filter((p) => p.lotId !== lotId),
        });
      }
    } else {
      await deleteLotAction(firestore, profile, lot);
    }
  }

  // Undo associated production order, if applies
  const productionTransfer: LotTransfer | undefined =
    getProductionTransferOfLotCreatedFromBatchTransformation(lot);

  if (!!productionTransfer) {
    await undoProductionOrder(firestore, profile, productionTransfer.transferId);
  }

  // Delete lot
  return offlineDelete(lotRef);
}

/***********************/
// 6. SHARED REPORTS
/***********************/

export async function getSharedReport(
  firestoreRef: firebase.firestore.Firestore,
  id: string
): Promise<Report> {
  return sharedReportDocRef(firestoreRef, id)
    .get()
    .then((db) => db.data() as Report);
}

/***********************/
// 7. ORGANISATION'S META
/***********************/

// 7.1 Contacts
export async function getContact(
  firestoreRef: firebase.firestore.Firestore,
  organisationId: string,
  contactId: string
): Promise<Contact> {
  return contactColRef(firestoreRef, organisationId)
    .doc(contactId)
    .get()
    .then((db) => db.data() as Contact);
}

export async function importContact(
  store: firebase.firestore.Firestore,
  profile: UserProfile,
  contactPayload: ContactUpdatePayload
) {
  return inTransaction(store, async (transaction: firebase.firestore.Transaction) => {
    const oldContact: Contact = await getContactTransactional(
      store,
      transaction,
      profile,
      contactPayload.id
    );
    const newContact: Contact = {
      ...oldContact,
      ...contactPayload,
      additionalContactEmails: undefined,
    } as any as Contact;

    // Update the contactEmails as the union of oldContact and contactPayload. Note that
    // we don't retain undefined values, but that should be fine in this context.
    newContact.contactEmails = [
      ...new Set([
        ...(oldContact?.contactEmails ?? []),
        ...(contactPayload.additionalContactEmails ?? []),
      ]),
    ];

    return setContactTransactional(store, transaction, profile, newContact, oldContact);
  });
}

export function getContactsSnapshot(
  firestoreRef: firebase.firestore.Firestore,
  organisationId: string,
  onResult: (contacts: Contact[] | null) => any
): () => void {
  return contactColRef(firestoreRef, organisationId).onSnapshot(
    ...handleOnCollectionSnapshot(onResult)
  );
}

export async function getContactTransactional(
  store: firebase.firestore.Firestore,
  transaction: firebase.firestore.Transaction,
  profile: UserProfile,
  contactId: string
): Promise<Contact | undefined> {
  const collRef = contactColRef(store, profile.organisationId).doc(contactId);

  if (transaction) {
    const result = await transaction.get(collRef);
    return result.exists ? (result.data() as Contact) : undefined;
  } else {
    return collRef.get().then((d) => d.data() as Contact);
  }
}

export async function setContactTransactional(
  store: firebase.firestore.Firestore,
  transaction: firebase.firestore.Transaction,
  profile: UserProfile,
  newContact: Contact,
  oldContact?: Contact
) {
  const collRef = contactColRef(store, profile.organisationId).doc(newContact.id);

  if (newContact !== oldContact) {
    // Update metadata
    await updateAggregateModificationData(profile.id, newContact);
    if (transaction) {
      return transaction.set(collRef, newContact, { merge: true });
    } else {
      collRef.set(newContact, { merge: true });
      return;
    }
  }
}

// 7.2 Product views
export function getProductViewSnapshot(
  firestoreRef: firebase.firestore.Firestore,
  organisationId: string,
  onResult: (product: ProductView[] | null) => any
): () => void {
  return productViewColRef(firestoreRef, organisationId).onSnapshot(
    ...handleOnCollectionSnapshot(onResult)
  );
}

function setProductViewsTransactional(
  productsToUpdate: { [productId: string]: ProductView },
  transaction: firebase.firestore.Transaction,
  store: firebase.firestore.Firestore,
  profile: UserProfile
) {
  if (Object.keys(productsToUpdate).length > 0) {
    for (const productId in productsToUpdate) {
      const ref = productViewColRef(store, profile.organisationId).doc(productId);
      if (transaction) {
        transaction.set(ref, productsToUpdate[productId]);
      } else {
        ref.set(productsToUpdate[productId]);
      }
    }
  }
}

function mergeProductViews(prod1: ProductView, prod2: ProductView) {
  const newProd: ProductView = { ...prod1 };
  for (const param of [
    'varieties',
    'agVarieties',
    'packaging',
    'brands',
    'origins',
    'suppliers',
    'customers',
  ]) {
    newProd[param] = [...new Set([...(prod1[param] ?? []), ...(prod2[param] ?? [])])];
  }
  return newProd;
}

export function updateProductView(
  productView: ProductView,
  article: IArticle,
  productChanged: boolean = false
) {
  const updatedProductView = { ...productView };

  for (const [prodField, artField] of [
    ['origins', 'origin'],
    ['packaging', 'packaging'],
    ['varieties', 'variety'],
    ['agVarieties', 'agVariety'],
    ['brands', 'brand'],
  ]) {
    const articleValue =
      artField === 'packaging'
        ? new Article(article).getPackagingRepr()
        : article[artField];

    if (
      !!productView[prodField] &&
      !!articleValue &&
      !productView[prodField].includes(articleValue)
    ) {
      updatedProductView[prodField].push(articleValue);
      productChanged = true;
    }
  }

  return { productChanged, updatedProductView };
}

// 7.3 Location
export function getLocationsSnapshot(
  firestoreRef: firebase.firestore.Firestore,
  organisationId: string,
  onResult: (locations: Location[] | null) => any
): () => void {
  return locationColRef(firestoreRef, organisationId).onSnapshot(
    ...handleOnCollectionSnapshot(onResult)
  );
}

export async function setLocation(
  store: firebase.firestore.Firestore,
  profile: UserProfile,
  location: Location
): Promise<any> {
  return locationColRef(store, profile.organisationId)
    .doc(location.locationId)
    .set(location);
}

export async function importLocation(
  store: firebase.firestore.Firestore,
  profile: UserProfile,
  payload: Location
): Promise<any> {
  // this is used from importAPI
  return inTransaction(store, async (transaction: firebase.firestore.Transaction) => {
    return setLocationTransactional(store, transaction, profile, payload);
  });
}

export async function setLocationTransactional(
  store: firebase.firestore.Firestore,
  transaction: firebase.firestore.Transaction,
  profile: UserProfile,
  payload: Location
) {
  const ref = locationColRef(store, profile.organisationId).doc(payload.locationId);

  // Update metadata and set locatiohn
  updateAggregateModificationData(profile.id, payload);
  transaction.set(ref, payload);
}

// 7.4 Articles
export async function getArticles(
  store: firebase.firestore.Firestore,
  organisationId: string
): Promise<IArticle[]> {
  return articleColRef(store, organisationId)
    .limit(5)
    .get()
    .then((db) => db.docs.map((doc) => doc.data() as IArticle));
}

export async function importArticle(
  store: firebase.firestore.Firestore,
  profile: UserProfile,
  payload: IArticle,
  checkProduct: boolean = false,
  isAgProduct: boolean = false,
  isAgVariety: boolean = false
): Promise<any> {
  return inTransaction(store, async (transaction: firebase.firestore.Transaction) => {
    return await setArticleTransactional(
      store,
      transaction,
      profile,
      payload,
      checkProduct,
      isAgProduct,
      isAgVariety
    );
  });
}

async function resolveArticlesInPositions(
  positions: LotPosition[],
  transaction: firebase.firestore.Transaction,
  payload: OrderCreatePayload | Order | InternalTransferPayload | ProductionPayload,
  store: firebase.firestore.Firestore,
  profile: UserProfile,
  isAgVariety: boolean,
  checkProduct: boolean
) {
  let agProducts: Product[];
  let productsToUpdate: { [productId: string]: ProductView } = {};

  // we fetch the articles to resolve them later
  const articles = await getArticlesTransactional(
    store,
    transaction,
    profile,
    positions.map((a) => a.articleId)
  );

  // fetch products to do the necessary checks if the flag is there
  if (checkProduct) {
    agProducts = await getAGProductsTransactional(
      store,
      transaction,
      articles.map((a) => a?.agProductId)
    );
  }

  const newPositions = [];

  for (const p of positions) {
    let article: IArticle;

    if (!!p.article) {
      article = {
        ...(articles.find((a) => a.id === p.articleId) ?? []),
        ...p.article,
      };
      const artClass = new Article(article);
      artClass.computePackagingRelatedFieldsFromPackagingString();
      article = artClass.getArticle();
    } else {
      article = articles.find((a) => a.id === p.articleId);
      if (!article) {
        throw new Error(
          `Problem when importing order ${payload.id}: article id ${p.articleId} not found`
        );
      }
    }

    // BW/Frutania case: we extract these fields from the lot position (and then we delete it from the position)
    for (const param of ['size', 'variety', 'origin']) {
      if (!!p[param]) {
        article[param] = p[param];
        delete p[param];
        // if variety came in the position, resolve the agVariety
        if (param === 'variety') {
          article = await resolveAgVariety(store, article, profile, isAgVariety);
        }
      }
    }

    // BW: if the position doesn't include an origin, we use the origin in the order payload instead
    if ((payload as OrderCreatePayload).origin && !p.origin) {
      article.origin = (payload as OrderCreatePayload).origin;
    }

    // Update organisation specific product view with new varieties, if applies
    if (!!article.agProductId) {
      const productViewRef = productViewColRef(store, profile.organisationId).doc(
        article.agProductId
      );
      let productView: ProductView = (await productViewRef.get()).data() as ProductView;
      let changed = false;
      if (!productView) {
        productView = {
          agProductId: article.agProductId,
          productId: article.productId ?? article.agProductId,
          varieties: [],
          agVarieties: [],
          packaging: [],
          brands: [],
          origins: [],
          suppliers: [],
          customers: [],
        };
        changed = true;
      }
      const { productChanged, updatedProductView } = updateProductView(
        productView,
        article,
        changed
      );
      if (productChanged) {
        if (!productsToUpdate[updatedProductView.agProductId]) {
          productsToUpdate[updatedProductView.agProductId] = updatedProductView;
        } else {
          productsToUpdate[updatedProductView.agProductId] = mergeProductViews(
            productsToUpdate[updatedProductView.agProductId],
            updatedProductView
          );
        }
      }
    }

    if (checkProduct) {
      // check if product and variety exist in our system
      const { agProductId, agVariety } = article;

      const product: Product = agProducts.find((p) => p.id === agProductId);

      if (!product) {
        throw new Error(`Product id ${agProductId} does not exist in AG Product list`);
      }

      if (agVariety && !product.varieties?.includes(agVariety)) {
        throw new Error(
          `Variety ${agVariety} does not exist in product ${agProductId}`
        );
      }
    }

    newPositions.push({ ...p, article });
  }
  return { newPositions, productsToUpdate };
}

export async function getArticlesTransactional(
  store: firebase.firestore.Firestore,
  transaction: firebase.firestore.Transaction,
  profile: UserProfile,
  articleIds: string[]
): Promise<IArticle[]> {
  const articles: IArticle[] = [];

  for (const articleId of articleIds) {
    if (articleId == null) {
      continue;
    }
    const article = await getArticleTransactional(
      store,
      transaction,
      profile,
      articleId
    );
    if (!!article && !articles.map((a) => a.id).includes(articleId)) {
      articles.push(article);
    }
  }
  return articles;
}

export async function getArticleTransactional(
  store: firebase.firestore.Firestore,
  transaction: firebase.firestore.Transaction,
  profile: UserProfile,
  articleId: string
): Promise<IArticle> {
  const collRef = articleColRef(store, profile.organisationId).doc(articleId);

  let result;

  // TODO: ugly code, improve
  if (transaction) {
    result = await transaction.get(collRef);

    if (result.exists) {
      const article = result.data() as IArticle;
      // @ts-ignore
      delete article.lastModifiedDate;
      return article;
    }
    return undefined;
  } else {
    result = await collRef.get();

    if (result.exists) {
      const article = result.data() as IArticle;
      // @ts-ignore
      delete article.lastModifiedDate;
      return article;
    }
    return undefined;
  }
}

export async function setArticleTransactional(
  store: firebase.firestore.Firestore,
  transaction: firebase.firestore.Transaction,
  profile: UserProfile,
  article: IArticle,
  checkProduct: boolean = false,
  isAgProduct: boolean = false,
  isAgVariety: boolean = false
) {
  const articleRef = articleColRef(store, profile.organisationId).doc(article.id);
  const exists = (await articleRef.get()).exists;

  // resolve agProductId and AgVariety
  article = await resolveAgProductId(store, article, profile, isAgProduct);
  article = await resolveAgVariety(store, article, profile, isAgVariety);

  if (checkProduct) {
    // check if product and variety exist in our system
    const { agProductId, agVariety } = article;

    const productDoc = await productDocRef(store, agProductId).get();
    if (!productDoc.exists) {
      throw new Error(`Product id ${agProductId} does not exist in AG Product list`);
    }

    const product: Product = productDoc.data() as Product;
    if (agVariety && !product.varieties?.includes(agVariety)) {
      throw new Error(`Variety ${agVariety} does not exist in product ${agProductId}`);
    }
  }

  // add date and delete merge flag from article
  const merge = !!article.merge;
  delete article.merge;

  // Update the organisation specific product view if new varieties, brands, etc, were found
  const productViewRef = productViewColRef(store, profile.organisationId).doc(
    article.agProductId
  );
  let productView: ProductView = (await productViewRef.get()).data() as ProductView;
  let changed = false;
  if (!productView) {
    productView = {
      agProductId: article.agProductId,
      productId: article.productId ?? article.agProductId,
      varieties: [],
      agVarieties: [],
      packaging: [],
      brands: [],
      origins: [],
      suppliers: [],
      customers: [],
    };
    changed = true;
  }
  const { productChanged, updatedProductView } = updateProductView(
    productView,
    article,
    changed
  );

  if (transaction) {
    if (productChanged) {
      transaction.set(productViewRef, updatedProductView);
    }
    if (merge && exists) {
      return transaction.update(articleRef, article);
    } else {
      return transaction.set(articleRef, article);
    }
  } else {
    if (productChanged) {
      await productViewRef.set(updatedProductView);
    }
    return await (merge && exists
      ? articleRef.update(article)
      : articleRef.set(article)
    ).catch((e) => console.error('error on collRef.set', e));
  }
}

// 7.5 Claims
export async function getClaimsByLotId(
  firestoreRef: firebase.firestore.Firestore,
  orgId: string,
  lotId: string
): Promise<Claim[]> {
  try {
    let claimDocs = await claimColRef(firestoreRef, orgId)
      .where('position.lotId', '==', lotId)
      .get();

    if (!claimDocs.empty) {
      return claimDocs.docs.map((d) => d.data() as Claim);
    }
    return [];
  } catch (err) {
    console.error('Error: ', err);
  }
}

export function getClaimsByLotIdSnapshot(
  firestoreRef: firebase.firestore.Firestore,
  orgId: string,
  lotId: string,
  onResult: (claims: Claim[] | null) => void
) {
  return claimColRef(firestoreRef, orgId)
    .where('position.lotId', '==', lotId)
    .onSnapshot(...handleOnCollectionSnapshot(onResult));
}

export async function importClaim(
  store: firebase.firestore.Firestore,
  profile: UserProfile,
  payload: ClaimCreatePayload
): Promise<any> {
  return inTransaction(store, async (transaction: firebase.firestore.Transaction) => {
    const claimId = [payload.orderId, payload.position.lotId].join(';');

    const {
      position,
      amount,
      handledDate,
      receivedDate,
      orderId,
      reason,
      description,
    } = payload;

    const claim: Claim = {
      position,
      amount,
      handledDate: standardizeDate(handledDate),
      receivedDate: standardizeDate(receivedDate),
      reason,
      orderId,
      description,
      claimId,
    };

    // try to fetch the order to extract the type
    try {
      const order: Order = (
        await orderColRef(store, profile.organisationId).doc(orderId).get()
      ).data() as Order;
      if (!!order) {
        claim.orderType = order.type as ClaimableOrderType;
      }
    } catch (error) {
      console.error(`Could not fetch order ${orderId}`, error);
    }

    return setClaimTransactional(store, transaction, profile, claim);
  });
}

export async function setClaimTransactional(
  store: firebase.firestore.Firestore,
  transaction: firebase.firestore.Transaction,
  profile: UserProfile,
  claim: Claim
) {
  const ref = claimColRef(store, profile.organisationId).doc(claim.claimId);

  // Update metadata and set claim
  updateAggregateModificationData(profile.id, claim);
  transaction.set(ref, claim);
}

/***********************/
// 8. INVITES
/***********************/

export async function getInvite(
  firestoreRef: firebase.firestore.Firestore,
  inviteId: string
): Promise<Invite> {
  return inviteDocRef(firestoreRef, inviteId)
    .get()
    .then((d) => d.data() as Invite);
}

export function getPendingInvitesSnapshot(
  firestoreRef: firebase.firestore.Firestore,
  organisationId: string,
  onResult: (invites: Invite[] | null) => void
): () => void {
  return inviteColRef(firestoreRef)
    .where('invitingOrganisationId', '==', organisationId)
    .where('status', '==', 'PENDING')
    .onSnapshot(
      ...handleOnCollectionSnapshot(
        onResult,
        (invite) => !invite.acceptingOrganisationId
      )
    );
}

export async function getPendingInvitations(
  firestoreRef: firebase.firestore.Firestore,
  profile: UserProfile
): Promise<Invite[]> {
  return inviteColRef(firestoreRef)
    .where('acceptingEmail', '==', profile.email)
    .where('status', '==', 'PENDING')
    .get()
    .then((d) => d.docs.map((dd) => dd.data() as Invite));
}

export function getPendingInvitationsSnapshot(
  firestoreRef: firebase.firestore.Firestore,
  email: string,
  onResult: (invites: Invite[] | null) => any
): () => void {
  return inviteColRef(firestoreRef)
    .where('acceptingEmail', '==', email)
    .onSnapshot(
      ...handleOnCollectionSnapshot(
        onResult,
        (invite) => !invite.acceptingOrganisationId
      )
    );
}

export async function sendInviteLink(
  firestoreRef: firebase.firestore.Firestore,
  email: string,
  name: string,
  orgId,
  role: UserRoleType,
  isAdmin: boolean = false
) {
  let hostname = window.location.hostname;
  if (hostname === 'localhost') {
    hostname = 'http://localhost:3000';
  } else {
    hostname = `https://${hostname}`;
  }
  email = email.toLocaleLowerCase();

  // const actionCodeSettings = {
  //   // URL you want to redirect back to. The domain (www.example.com) for this
  //   // URL must be in the authorized domains list in the Firebase Console.
  //   url: hostname + "/?email=" + email,
  //   // This must be true.
  //   handleCodeInApp: true,
  // };

  const sendUserInvitationEmail = firebase
    .app()
    .functions(FIREBASE_FUNCTIONS_REGION)
    .httpsCallable('sendUserInvitationEmail');

  sendUserInvitationEmail({
    email,
    orgName: orgId,
    isLocalhost: window.location.hostname === 'localhost',
  })
    .then(async (result) => {
      const code = _.split(_.split(result.data.link, 'Code=')[1], '&continue')[0];

      const invite: UserInvite = {
        email,
        name,
        orgId,
        role,
        code,
        created: false,
        isAdmin,
        inviteSentDate: new Date(),
      };

      await userInviteColRef(firestoreRef).add(invite);
    })
    .catch((err) => {
      console.log('err', err);
    })
    .finally(() => {});

  return;
}

export async function removePendingInvite(email: string) {
  const action = firebase
    .app()
    .functions(FIREBASE_FUNCTIONS_REGION)
    .httpsCallable('removeUserInvite');

  action({ email })
    .then()
    .catch((err) => {
      console.log('err', err);
    })
    .finally();

  return;
}

export async function acceptUserInvite(email: string) {
  const action = firebase
    .app()
    .functions(FIREBASE_FUNCTIONS_REGION)
    .httpsCallable('acceptUserInvite');

  action({ email });
}

export async function issueInviteForOrganisation(
  store: firebase.firestore.Firestore,
  profile: UserProfile,
  email: string,
  contact: Contact,
  hostname?: string
): Promise<any> {
  const docRef = inviteColRef(store).doc();
  email = email.toLocaleLowerCase();

  const sendInvitationEmailPartner = firebase
    .app()
    .functions(FIREBASE_FUNCTIONS_REGION)
    .httpsCallable('sendInvitationEmailPartner');

  sendInvitationEmailPartner({
    email,
    orgName: profile.organisationId,
    isLocalhost: window.location.hostname === 'localhost',
    hostname: hostname,
  })
    .then((result) => {
      console.log('result', result);
      const code = _.split(_.split(result.data.link, 'Code=')[1], '&continue')[0];
      console.log('code: ', code);
      return docRef.set({
        id: docRef.id,
        invitingOrganisationId: profile.organisationId,
        invitingContactId: contact.id,
        invitingContactName: contact.name,
        invitingUserId: profile.id,
        invitingEmail: profile.email,
        acceptingEmail: email,
        status: 'PENDING',
        code: code,
      });
    })
    .catch((err) => {
      console.log('err', err);
    })
    .finally(() => {});

  // get list of products from inviting company
  const ordersFromInvitingOrg: Order[] = await orderColRef(
    store,
    profile.organisationId
  )
    .orderBy('fulfilmentDate', 'desc')
    .where('contactId', '==', contact.id)
    .limit(200)
    .get()
    .then((rows) => rows.docs.map((doc) => doc.data() as Order));

  const products = [
    ...new Set(
      ordersFromInvitingOrg.map((o: Order) => o.positions[0].article.agProductId)
    ),
  ].filter((o) => !!o);
  let productsJson = {};

  await Promise.all(
    products.map(async (p) => {
      const docRef = await productViewColRef(store, profile.organisationId)
        .doc(p)
        .get();

      productsJson[p] = docRef.data();
    })
  );

  return docRef.set({
    id: docRef.id,
    invitingOrganisationId: profile.organisationId,
    invitingContactId: contact.id,
    invitingContactName: contact.name,
    invitingUserId: profile.id,
    invitingEmail: profile.email,
    acceptingEmail: email,
    status: 'PENDING',
    products: productsJson,
    orgType: contact.type,
  });
}

export async function acceptInviteForOrganisation(
  store: firebase.firestore.Firestore,
  profile: UserProfile,
  inviteId: string,
  status: InviteStatus = 'ACCEPTED',
  contactId?: string
): Promise<any> {
  const invite: Invite = await getInvite(store, inviteId);
  if (status === 'ACCEPTED') {
    return inviteDocRef(store, inviteId).update({
      acceptingOrganisationId: profile.organisationId,
      acceptingUserId: profile.id,
      acceptingContactId: contactId || invite.invitingOrganisationId,
      status: 'ACCEPTED',
    });
  } else if (status === 'REJECTED') {
    return inviteDocRef(store, inviteId).update({
      status: 'REJECTED',
    });
  }
}

/***********************/
// 9. USERS
/***********************/

export function getUsersSnapshot(
  firestoreRef: firebase.firestore.Firestore,
  organisationId: string,
  onResult: (users: User[] | null) => any
): () => void {
  return userColRef(firestoreRef)
    .where('organisationId', '==', organisationId)
    .onSnapshot(...handleOnCollectionSnapshot(onResult));
}

export function getUserProfileSnapshot(
  firestoreRef: firebase.firestore.Firestore,
  userId: string,
  onResult: (profile: UserProfile | null) => any
): () => void {
  return profileDocRef(firestoreRef, userId).onSnapshot(
    ...handleOnDocSnapshot(onResult)
  );
}

export function updateUserProperty(
  firestoreRef: firebase.firestore.Firestore,
  userId: string,
  props: UserProperties
) {
  props.updatedDate = new Date();

  return userDocRef(firestoreRef, userId).collection('property').add(props);
}

export async function getUsersById(
  store: firebase.firestore.Firestore,
  userIds: string[]
): Promise<User[]> {
  if (!userIds || userIds.length === 0) {
    return [];
  }
  const frontUsers = userIds.slice(0, 10);
  const remaining = userIds.slice(10);
  let dbLots: any = [];
  try {
    dbLots = await userColRef(store)
      .where(firebase.firestore.FieldPath.documentId(), 'in', frontUsers)
      .get();
  } catch (e) {
    console.error('Error while fetching users:', frontUsers, e);
  }

  const user: User[] = dbLots.docs.map((db) => db.data());
  if (remaining.length > 0) {
    const remainingLots = await getUsersById(store, remaining);
    return user.concat(remainingLots);
  }
  return user;
}

export async function saveUserSubscriptions(
  store: firebase.firestore.Firestore,
  profile: UserProfile,
  subscriptions: string[],
  subscriptionsStatus: string[]
) {
  await profileDocRef(store, profile.id).update({
    productSubscriptions: subscriptions,
    statusSubscriptions: subscriptionsStatus,
  });
}

export async function saveUserDisplayAGTabs(
  store: firebase.firestore.Firestore,
  profile: UserProfile,
  agTabs: FilterType[]
) {
  await profileDocRef(store, profile.id).update({
    displayAGTabs: agTabs,
  });
}

export async function saveUserTabs(
  store: firebase.firestore.Firestore,
  profile: UserProfile,
  tabs: FilterType[],
  profileProperty: string
) {
  await profileDocRef(store, profile.id).update({
    [`${profileProperty}`]: tabs,
  });
}

/********************************/
// 11. CONVERSATIONS
/********************************/

export async function getAllConversations(
  firestoreRef: firebase.firestore.Firestore,
  userId: string
): Promise<Conversation[]> {
  let conversations: Conversation[] = [];
  (
    await conversationColRef(firestoreRef)
      .where('members', 'array-contains', userId)
      .get()
  ).forEach((conv) => conversations.push(conv.data() as Conversation));
  return conversations;
}

const sortByLastMessage = (a: Conversation, b: Conversation) => {
  let aLast =
    a.lastMessages?.length > 0
      ? a.lastMessages[a.lastMessages.length - 1].creationDate
      : a.creationDate;
  let bLast =
    b.lastMessages?.length > 0
      ? b.lastMessages[b.lastMessages.length - 1].creationDate
      : b.creationDate;
  return bLast - aLast;
};

export function getAllConversationsSnapshot(
  firestoreRef: firebase.firestore.Firestore,
  user: UserProfile,
  onResult: (conversations: Conversation[] | null) => any
) {
  return conversationColRef(firestoreRef)
    .where('members', 'array-contains', user.id)
    .onSnapshot(...handleOnCollectionSnapshot(onResult, undefined, sortByLastMessage));
}

export async function getConversation(
  firestoreRef: firebase.firestore.Firestore,
  id: string
): Promise<Conversation> {
  try {
    let dbConv = await conversationDocRef(firestoreRef, id).get();
    if (dbConv.exists) {
      return dbConv.data() as Conversation;
    }
  } catch (err) {
    console.error('Error: ', err);
  }
}

export function getConversationSnapshot(
  firestoreRef: firebase.firestore.Firestore,
  conversationId: string,
  onResult: (conversation: Conversation | null) => void
) {
  return conversationDocRef(firestoreRef, conversationId).onSnapshot(
    ...handleOnDocSnapshot(onResult)
  );
}

export function getConversationMessagesSnapshot(
  firestoreRef: firebase.firestore.Firestore,
  conversationId: string,
  onResult: (m: ConversationMessage[] | null) => void
) {
  return conversationDocRef(firestoreRef, conversationId)
    .collection('message')
    .orderBy('creationDate', 'asc')
    .onSnapshot(...handleOnCollectionSnapshot(onResult));
}

export function newOrExistingConversation(
  profile: UserProfile,
  conversations: Conversation[],
  userId: string
): Conversation {
  // see if we find an existing conversation
  let conversation: Conversation = conversations.find(
    (conv) => conv.members.indexOf(userId) >= 0 && conv.members.indexOf(profile.id) >= 0
  );
  return (
    conversation ||
    ({
      members: [profile.id, userId],
      lastMessages: [],
      creationDate: new Date(),
      author: userId,
    } as Conversation)
  );
}

export function newConversation(profile: UserProfile, users: User[]): Conversation {
  return {
    members: [profile.id, ...users.map((user) => user.id)],
    lastMessages: [],
    creationDate: new Date(),
    author: profile.id,
  } as Conversation;
}

export async function addMessage(
  firestoreRef: firebase.firestore.Firestore,
  conversation: Conversation,
  message: ConversationMessage
): Promise<any> {
  if (!conversation.id) {
    createConversation(firestoreRef, conversation);
  }

  message.creationDate = firebase.firestore.FieldValue.serverTimestamp();
  // Conversation already exists, only push message
  return conversationDocRef(firestoreRef, conversation.id)
    .collection('message')
    .doc(uuid4())
    .set(message);
}

export async function addTextMessage(
  firestoreRef: firebase.firestore.Firestore,
  text: string,
  userId: string,
  conversation: Conversation
): Promise<any> {
  return addMessage(firestoreRef, conversation, {
    userId: userId,
    message: text,
    creationDate: Date.now(),
    type: 'TEXT',
  });
}

export async function addOrderMessage(
  firestoreRef: firebase.firestore.Firestore,
  order: Order | Report,
  userId: string,
  conversation: Conversation
): Promise<any> {
  const newOrder = copyOrderForSharing(order, undefined);

  return addMessage(firestoreRef, conversation, {
    userId: userId,
    order: newOrder,
    creationDate: Date.now(),
    type: 'ORDER',
  });
}

export async function addReportMessage(
  firestoreRef: firebase.firestore.Firestore,
  report: Report,
  userId: string,
  conversation: Conversation
): Promise<any> {
  // const newOrder = copyOrderForSharing(order, undefined);

  return addMessage(firestoreRef, conversation, {
    userId: userId,
    report: report,
    creationDate: Date.now(),
    type: 'REPORT',
  });
}

export async function addLotMessage(
  firestoreRef: firebase.firestore.Firestore,
  lot: Lot,
  userId: string,
  conversation: Conversation
): Promise<any> {
  return addMessage(firestoreRef, conversation, {
    userId: userId,
    lot: lot,
    creationDate: Date.now(),
    type: 'LOT',
  });
}

export async function createConversation(
  firestoreRef: firebase.firestore.Firestore,
  conversation: Conversation
): Promise<any> {
  // if (conversation.id) {
  //   throw Error("Trying to update an existing covnersation with id: " + conversation.id);
  // }

  if (!conversation.id) {
    conversation.id = uuid4();
  }

  if (navigator.onLine) {
    try {
      return conversationDocRef(firestoreRef, conversation.id).set(conversation);
    } catch (error) {
      console.error('Error online: ', error);
    }
  } else {
    // HACK: in case offline do not wait for promise as this never returns
    try {
      return conversationDocRef(firestoreRef, conversation.id).set(conversation);
    } catch (err) {
      console.error('Error offline: ', err);
    }
    return conversation;
  }
}

export async function archiveConversation(
  firestoreRef: firebase.firestore.Firestore,
  conversation: Conversation,
  userId: string
): Promise<any> {
  if (!conversation.id) {
    return false;
  }

  conversation.archived = _.uniq((conversation.archived ?? []).concat(userId));

  return conversationDocRef(firestoreRef, conversation.id).set(conversation);
}

export async function unarchiveConversation(
  firestoreRef: firebase.firestore.Firestore,
  conversation: Conversation,
  userId: string
): Promise<any> {
  if (!conversation.id) {
    return false;
  }

  conversation.archived = _.without(conversation.archived ?? [], userId);

  return conversationDocRef(firestoreRef, conversation.id).set(conversation);
}

export async function updateLastRead(
  firestoreRef: firebase.firestore.Firestore,
  conversation: Conversation,
  userId: string
) {
  const convDocRef = conversationDocRef(firestoreRef, conversation.id);

  // in order to trigger 'onConversationWrite' and recalculate the unreadConversationsCount in the user's profile
  await convDocRef.update({
    lastModifiedDate: firebase.firestore.FieldValue.serverTimestamp(),
  });

  return convDocRef
    .collection('read')
    .doc(userId)
    .set({
      userId: userId,
      creationDate: firebase.firestore.FieldValue.serverTimestamp(),
    } as LastRead);
}

export function conversationTitle(
  profile: UserProfile,
  conversations: Conversation[],
  users: User[],
  conversationId
): string {
  if (!conversations) return '';

  let conversation = conversations.find((o) => o.id === conversationId);
  if (!!conversation?.title) {
    return conversation.title;
  }

  if (!conversation) {
    return '';
  }
  if (conversation.members.length > 2) {
    return conversation.title ?? conversation.members.length + ' people';
  }

  let userId: string = conversation.members.find((u) => u !== profile.id);
  let email = users.find((u) => u.id === userId)?.email;

  return email ?? 'Chat';
}

export function messagesUnread(conversation: Conversation, userId: string): number {
  let lastReadMap = conversation.lastRead || {};
  let lastSeen = lastReadMap[userId] || 0;
  let unreadCount = 0;
  conversation.lastMessages.forEach((message) => {
    if (message.userId !== userId && message.creationDate > lastSeen) {
      unreadCount++;
    }
  });
  return unreadCount;
}

export function totalMessagesUnread(
  conversations: Conversation[],
  userId: string
): number {
  let count = 0;
  conversations?.forEach((conv) => (count += messagesUnread(conv, userId)));
  return count;
}

/******************** */
// WASTE

export async function setWasteTransactional(
  store: firebase.firestore.Firestore,
  transaction: firebase.firestore.Transaction,
  profile: UserProfile,
  waste: Waste
) {
  const ref = wasteColRef(store, profile.organisationId).doc(
    getWasteId(waste.lotActionId, waste.lotId)
  );

  transaction.set(ref, waste);
}

// export async function getWasteByLotActionIdTransactional(
//   store: firebase.firestore.Firestore,
//   transaction: firebase.firestore.Transaction,
//   profile: UserProfile,
//   lotActionId: string
// ) {
//   const wasteRef = wasteColRef(store, profile.organisationId).where('lotActionId', '==', lotActionId);
//   return transaction.get(wasteRef);
// }

/********************************/
// 99. DEPRECATED / UNUSED / UNCLEAR
/********************************/

export async function getCollectionReferenceByIds(
  store: firebase.firestore.Firestore,
  collRef: any,
  ids: string[]
): Promise<any[]> {
  if (ids.length === 0) {
    return [];
  }
  const frontIds = ids.slice(0, 10);
  const remaining = ids.slice(10);
  let dbLots: any = [];
  try {
    dbLots = await collRef.where('id', 'in', frontIds).get();
  } catch (e) {
    console.error('Error while fetching lots:', frontIds, e);
  }

  const lots: Lot[] = dbLots.docs.map((db) => db.data());
  if (remaining.length > 0) {
    const remainingLots = await getCollectionReferenceByIds(store, collRef, remaining);
    return lots.concat(remainingLots);
  }
  return lots;
}

export async function getUsersMapForConversation(
  conversations: Conversation[]
): Promise<{ [key in string]?: User }> {
  throw Error('Not Implemented');
  /*
    let userIds = _.chain(conversations)
      .map(m => m.members)
      .flatten()
      .uniq()
      .value()

    let users = await ServiceData.getUsers(userIds);

    let userMap = {};
    users.forEach(user => userMap[user.id] = user)
    return userMap;
     */
}

export async function createLotFromContactPosition(
  firestore: firebase.firestore.Firestore,
  lot: LotCreatePayload,
  order: Order | Report,
  currContactPosition: LotPosition,
  profile: UserProfile,
  products: ProductView[],
  orgSettings: OrganisationSettings
) {
  // TODO: remove function or reimplement if needed

  console.log('NOT IMPLEMENTED');
  // const orderColRef = (orderId: string) => firestore.collection('organisation').doc(profile.organisationId).collection('common').doc(profile.organisationId).collection('order').doc(orderId);

  // // add transfer to lot
  // const transfer: LotTransfer = {
  //   transferId: `${lot.id}-${order.id}`,
  //   quantity: -lot.numBoxes,
  //   transferType: 'SELL',
  //   orderId: order.id,
  // };
  // lot.transfers.push(transfer);

  // // create lot with linked == true
  // await createNewLot(firestore, profile, lot, true);
  // await savePackagingToCompanyMetaProductsFromCreatePosition(firestore, products, profile.organisationId, lot.article.packaging, lot.article.agProductId);

  // // add position to order
  // const positions = [...(order.positions ?? [])];
  // const position: LotPosition = {
  //   lotId: lot.id,
  //   quantity: lot.numBoxes,
  //   article: lot.article,
  //   palletIds: lot.palletIds,
  //   growerId: lot.growerContactId
  // };
  // positions.push(position);

  // // add mapping
  // const positionsMap = { ...order.contactOrder?.positionsMap };
  // positionsMap[lot.id] = currContactPosition.lotId;

  // // update order
  // const orderRef = orderColRef(order.id);

  // let newOrder = { ...order };
  // newOrder.positions = positions;
  // newOrder.qcStatus = 'OPEN';
  // newOrder.contactOrder.positionsMap = positionsMap;
  // updateAggregateModificationData(profile.id, newOrder);

  // // newOrder = buildOrderArraySearch(newOrder);
  // newOrder.search = generateOrderSearch(newOrder, orgSettings);

  // await orderRef.set(newOrder);
}

export function getLotsToLink(
  firestore: firebase.firestore.Firestore,
  organisationId: string,
  search: string,
  productId: string,
  agProductId: string,
  onResult: (lots: Lot[] | null) => void,
  max: number = 20
) {
  let query = lotColRef(firestore, organisationId)
    .where('article.agProductId', '==', agProductId)
    .where('hasLink', '==', false);
  // .where("searchProducts", "array-contains-any", [agProductId, productId])
  // .where("hasLink", "==", false)

  if (search != null && search?.length > 0) {
    query = query.where('searchbox', 'array-contains', search);
  }

  const snapshot = query.limit(max).onSnapshot(...handleOnCollectionSnapshot(onResult));

  return snapshot;
}

export async function shareOrder(
  firestoreRef: firebase.firestore.Firestore,
  order: Order,
  users: User[],
  profile: UserProfile,
  message: string,
  listOfEmailsArray: string[],
  contact: Contact
): Promise<any> {
  // -------------------------------
  //  no invitations sent for now
  // -------------------------------

  //   listOfEmailsArray.map(async email => {
  //     // maybe check if the user email is not already in my contact list
  //     // and dont issue the invite
  //     console.log('sending invite to: ', email, contact)
  //     await issueInviteForOrganisation(firestoreRef, profile, email, contact.id);
  //     await sendInviteLink(email)
  //   });

  const newOrder = copyOrderForSharing(order, contact);

  let conversation: Conversation = {
    members: [...users.map((c) => c.id), profile.id],
    lastMessages: [],
    creationDate: new Date(),
    author: profile.id,
    title: `${order.id} Report`,
    lastRead: undefined,
    order: newOrder,
    listOfEmails: listOfEmailsArray,
  };

  await createConversation(firestoreRef, conversation);

  await addMessage(firestoreRef, conversation, {
    userId: profile.id,
    creationDate: Date.now(),
    type: 'STATUS',
    message: profile.name + ' started a conversation',
  });

  await addMessage(firestoreRef, conversation, {
    userId: profile.id,
    creationDate: Date.now(),
    type: 'ORDER',
    order: newOrder,
  });

  if (message) {
    await addMessage(firestoreRef, conversation, {
      userId: profile.id,
      creationDate: Date.now(),
      type: 'TEXT',
      message: message,
    });
  }

  return conversation.id;
}

export async function updateOrderMetadata(
  store: firebase.firestore.Firestore,
  profile: UserProfile,
  orderId: string,
  metadata: any
): Promise<any> {
  return inTransaction(store, async (transaction: firebase.firestore.Transaction) => {
    const oldOrder: Order = await getOrderTransactional(
      store,
      transaction,
      profile,
      orderId
    );
    const newOrder: Order = { ...oldOrder, metadata: metadata };

    return setOrderTransactional(store, transaction, profile, newOrder, oldOrder);
  });
}
